import React from "react";
import NavBar from "../Components/navBar";
import XtrCalculator from "../Components/xtrCalculator";

const XtrCalculatorPage = () => {
    return (
        <div>
            <NavBar />
            <XtrCalculator />
        </div>
    );
};

export default XtrCalculatorPage;
