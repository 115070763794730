import React, { useState, useEffect, useCallback } from "react";
import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    FormControl,
    InputLabel,
    Select,
    InputAdornment,
    Box,
    Paper,
    Typography,
    Button,
    Grid,
    IconButton,
    TextField,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemSecondaryAction,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Breadcrumbs,
    Link as MuiLink,
    CircularProgress,
    Tooltip,
    Snackbar,
    Alert,
    Menu,
    MenuItem,
} from "@mui/material";
import {
    DeleteSweep as DeleteSweepIcon,
    Search as SearchIcon,
    Clear as ClearIcon,
    FilterAlt as FilterIcon,
    Visibility as VisibilityIcon,
    GetApp as GetAppIcon,
    Folder as FolderIcon,
    FolderOpen as FolderOpenIcon,
    CreateNewFolder as CreateNewFolderIcon,
    UploadFile as UploadFileIcon,
    Delete as DeleteIcon,
    PictureAsPdf as PdfIcon,
    Add as AddIcon,
    Edit as EditIcon,
    Description as DescriptionIcon,
    Link as LinkIcon,
    LinkOff as LinkOffIcon,
    MoreVert as MoreVertIcon,
    ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../../Helpers/apiClient";

function DocumentManager() {
    // Add these near your other state variables
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [bulkDeleteDialog, setBulkDeleteDialog] = useState(false);
    const [bulkDeleteLoading, setBulkDeleteLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("uploadDate"); // Default sort by upload date
    const [filterText, setFilterText] = useState("");
    const [filterField, setFilterField] = useState("all");
    // State for all data
    const [folders, setFolders] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [folderPath, setFolderPath] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "info",
    });

    // Dialog states
    const [newFolderDialog, setNewFolderDialog] = useState(false);
    const [uploadDialog, setUploadDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);
    const [associateDialog, setAssociateDialog] = useState(false);
    const [entityTypeOptions, setEntityTypeOptions] = useState([
        "ControllerPrice",
    ]);

    // Form states
    const [newFolderName, setNewFolderName] = useState("");
    const [newFolderDescription, setNewFolderDescription] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileDescription, setFileDescription] = useState("");
    const [entityType, setEntityType] = useState("ControllerPrice");
    const [entityId, setEntityId] = useState("");
    const [entityOptions, setEntityOptions] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState(null);

    // Context menu state
    const [contextMenu, setContextMenu] = useState(null);
    const [menuTarget, setMenuTarget] = useState(null);

    const navigate = useNavigate();

    // Fetch folders on component mount
    useEffect(() => {
        fetchFolders();
    }, []);

    // Fetch documents when current folder changes
    useEffect(() => {
        fetchDocuments();
    }, [currentFolder]);

    // Fetch entity options when entityType changes
    useEffect(() => {
        if (associateDialog && entityType) {
            fetchEntityOptions(entityType);
        }
    }, [associateDialog, entityType]);

    // API calls
    const fetchFolders = async (parentId = null) => {
        setLoading(true);
        try {
            const params = parentId ? `?parentFolderId=${parentId}` : "";
            const response = await apiClient.get(
                `/api/Documents/Folders${params}`
            );
            if (Array.isArray(response.data)) {
                setFolders(response.data);
            } else {
                setFolders([]);
            }
            setError(null);
        } catch (err) {
            console.error("Error fetching folders:", err);
            setError("Failed to load folders");
            setFolders([]);
        } finally {
            setLoading(false);
        }
    };

    // Update your fetchDocuments function to include this:
    const fetchDocuments = async () => {
        setLoading(true);
        try {
            // Don't include the folderId parameter at all when requesting root documents
            const params = currentFolder ? `?folderId=${currentFolder.id}` : ""; // No parameter instead of ?folderId=null

            const response = await apiClient.get(`/api/Documents${params}`);

            if (Array.isArray(response.data)) {
                setDocuments(response.data);
                // Clear selections when documents change
                setSelectedDocuments([]);
            } else {
                setDocuments([]);
            }
            setError(null);
        } catch (err) {
            console.error("Error fetching documents:", err);
            setError("Failed to load documents");
            setDocuments([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchEntityOptions = async (type) => {
        try {
            let endpoint;
            switch (type) {
                case "ControllerPrice":
                    endpoint = "/api/PumpStationPriceTables/ControllerPrices";
                    break;
                // Add other entity types here
                default:
                    endpoint = "/api/PumpStationPriceTables/ControllerPrices";
            }

            const response = await apiClient.get(endpoint);
            if (Array.isArray(response.data)) {
                setEntityOptions(response.data);
            } else {
                setEntityOptions([]);
            }
        } catch (err) {
            console.error(`Error fetching ${type} options:`, err);
            setEntityOptions([]);
        }
    };

    // Action handlers
    const handleFolderClick = (folder) => {
        setCurrentFolder(folder);
        // Update folder path for breadcrumbs
        setFolderPath([...folderPath, folder]);
        fetchFolders(folder.id);
    };

    const handleFolderPathClick = (index) => {
        if (index === 0) {
            // Go to root
            setCurrentFolder(null);
            setFolderPath([]);
            fetchFolders(null);
        } else {
            // Go to specific folder in the path
            const targetFolder = folderPath[index];
            setCurrentFolder(targetFolder);
            setFolderPath(folderPath.slice(0, index + 1));
            fetchFolders(targetFolder.id);
        }
    };

    const handleBackClick = () => {
        if (folderPath.length > 0) {
            const newPath = [...folderPath];
            newPath.pop();

            if (newPath.length === 0) {
                // Going back to root
                setCurrentFolder(null);
                setFolderPath([]);
                fetchFolders(null);
            } else {
                // Going back to parent folder
                const parentFolder = newPath[newPath.length - 1];
                setCurrentFolder(parentFolder);
                setFolderPath(newPath);
                fetchFolders(parentFolder.id);
            }
        }
    };

    const handleCreateFolder = async () => {
        if (!newFolderName.trim()) {
            setSnackbar({
                open: true,
                message: "Folder name is required",
                severity: "error",
            });
            return;
        }

        try {
            const response = await apiClient.post("/api/Documents/Folders", {
                name: newFolderName,
                description: newFolderDescription,
                parentFolderId: currentFolder ? currentFolder.id : null,
            });

            setSnackbar({
                open: true,
                message: "Folder created successfully",
                severity: "success",
            });

            // Reset form and refresh folders
            setNewFolderName("");
            setNewFolderDescription("");
            setNewFolderDialog(false);
            fetchFolders(currentFolder ? currentFolder.id : null);
        } catch (err) {
            console.error("Error creating folder:", err);
            setSnackbar({
                open: true,
                message: "Error creating folder",
                severity: "error",
            });
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/pdf") {
            setSelectedFile(file);
        } else {
            setSnackbar({
                open: true,
                message: "Only PDF files are allowed",
                severity: "error",
            });
            setSelectedFile(null);
            // Reset the file input
            event.target.value = "";
        }
    };

    const handleUploadDocument = async () => {
        if (!selectedFile) {
            setSnackbar({
                open: true,
                message: "Please select a file",
                severity: "error",
            });
            return;
        }

        try {
            const formData = new FormData();
            formData.append("file", selectedFile);

            if (currentFolder) {
                formData.append("folderId", currentFolder.id);
            }

            if (fileDescription) {
                formData.append("description", fileDescription);
            }

            const response = await apiClient.post("/api/Documents", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            setSnackbar({
                open: true,
                message: "Document uploaded successfully",
                severity: "success",
            });

            // Reset form and refresh documents
            setSelectedFile(null);
            setFileDescription("");
            setUploadDialog(false);
            fetchDocuments();
        } catch (err) {
            console.error("Error uploading document:", err);
            setSnackbar({
                open: true,
                message: "Error uploading document",
                severity: "error",
            });
        }
    };

    const handleDeleteItem = async () => {
        if (!deleteItem) return;

        try {
            const isFolder = "name" in deleteItem; // Folders have a name property

            if (isFolder) {
                await apiClient.delete(
                    `/api/Documents/Folders/${deleteItem.id}`
                );
                fetchFolders(currentFolder ? currentFolder.id : null);
            } else {
                await apiClient.delete(`/api/Documents/${deleteItem.id}`);
                fetchDocuments();
            }

            setSnackbar({
                open: true,
                message: `${
                    isFolder ? "Folder" : "Document"
                } deleted successfully`,
                severity: "success",
            });

            setDeleteDialog(false);
            setDeleteItem(null);
        } catch (err) {
            console.error("Error deleting item:", err);
            setSnackbar({
                open: true,
                message: err.response?.data || "Error deleting item",
                severity: "error",
            });
        }
    };

    const handleViewDocument = async (document) => {
        try {
            const response = await apiClient.get(
                `/api/Documents/View/${document.id}`
            );
            if (response.data && response.data.url) {
                window.open(response.data.url, "_blank");
            }
        } catch (err) {
            console.error("Error viewing document:", err);
            setSnackbar({
                open: true,
                message: "Error opening document",
                severity: "error",
            });
        }
    };

    const handleDownloadDocument = async (documentObj) => {
        try {
            // Changed parameter name from 'document' to 'documentObj'
            const response = await apiClient.get(
                `/api/Documents/Download/${documentObj.id}`,
                {
                    responseType: "blob",
                }
            );

            const contentType =
                response.headers["content-type"] || "application/pdf";
            const blob = new Blob([response.data], { type: contentType });

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a"); // Now uses the global document object
            link.href = url;
            link.setAttribute("download", documentObj.originalFileName);

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (err) {
            console.error("Error downloading document:", err);
            setSnackbar({
                open: true,
                message:
                    "Error downloading document: " +
                    (err.message || "Unknown error"),
                severity: "error",
            });
        }
    };

    const handleAssociateDocument = async () => {
        if (!selectedDocument || !entityType || !entityId) {
            setSnackbar({
                open: true,
                message: "Please select all required fields",
                severity: "error",
            });
            return;
        }

        try {
            await apiClient.post("/api/Documents/Associate", {
                documentId: selectedDocument.id,
                entityType: entityType,
                entityId: parseInt(entityId),
            });

            setSnackbar({
                open: true,
                message: "Document associated successfully",
                severity: "success",
            });

            setAssociateDialog(false);
            setSelectedDocument(null);
            setEntityId("");
        } catch (err) {
            console.error("Error associating document:", err);
            setSnackbar({
                open: true,
                message: err.response?.data || "Error associating document",
                severity: "error",
            });
        }
    };

    const handleContextMenu = (event, item) => {
        event.preventDefault();
        setContextMenu({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
        setMenuTarget(item);
    };

    const handleCloseContextMenu = () => {
        setContextMenu(null);
        setMenuTarget(null);
    };

    const handleContextMenuAction = (action) => {
        handleCloseContextMenu();

        switch (action) {
            case "view":
                handleViewDocument(menuTarget);
                break;
            case "download":
                handleDownloadDocument(menuTarget);
                break;
            case "associate":
                setSelectedDocument(menuTarget);
                setAssociateDialog(true);
                break;
            case "delete":
                setDeleteItem(menuTarget);
                setDeleteDialog(true);
                break;
            default:
                break;
        }
    };

    // Format file size for display
    const formatFileSize = (bytes) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    };

    // Format date for display
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
        });
    };

    // Add these functions to your component
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const clearFilter = () => {
        setFilterText("");
        setFilterField("all");
    };

    const applyFilters = () => {
        if (!filterText.trim()) {
            setFilteredDocuments(documents);
            return;
        }

        const searchTerm = filterText.toLowerCase().trim();

        const filtered = documents.filter((doc) => {
            const fileName = doc.originalFileName || "";
            const description = doc.description || "";

            if (filterField === "all") {
                return (
                    fileName.toLowerCase().includes(searchTerm) ||
                    description.toLowerCase().includes(searchTerm) ||
                    formatDate(doc.uploadDate)
                        .toLowerCase()
                        .includes(searchTerm)
                );
            }

            if (filterField === "fileName") {
                return fileName.toLowerCase().includes(searchTerm);
            }
            if (filterField === "description") {
                return description.toLowerCase().includes(searchTerm);
            }
            if (filterField === "date") {
                return formatDate(doc.uploadDate)
                    .toLowerCase()
                    .includes(searchTerm);
            }

            return false;
        });

        setFilteredDocuments(filtered);
    };

    const getSortedData = (data) => {
        if (!data.length) return [];

        return [...data].sort((a, b) => {
            if (orderBy === "fileName") {
                const valueA = a.originalFileName || "";
                const valueB = b.originalFileName || "";
                return order === "asc"
                    ? valueA.localeCompare(valueB)
                    : valueB.localeCompare(valueA);
            }

            if (orderBy === "fileSize") {
                const valueA = a.fileSize || 0;
                const valueB = b.fileSize || 0;
                return order === "asc" ? valueA - valueB : valueB - valueA;
            }

            if (orderBy === "uploadDate") {
                const valueA = new Date(a.uploadDate).getTime();
                const valueB = new Date(b.uploadDate).getTime();
                return order === "asc" ? valueA - valueB : valueB - valueA;
            }

            if (orderBy === "description") {
                const valueA = a.description || "";
                const valueB = b.description || "";
                return order === "asc"
                    ? valueA.localeCompare(valueB)
                    : valueB.localeCompare(valueA);
            }

            return 0;
        });
    };

    // Add these functions to your component
    const handleSelectDocument = (documentId) => {
        setSelectedDocuments((prev) => {
            if (prev.includes(documentId)) {
                return prev.filter((id) => id !== documentId);
            } else {
                return [...prev, documentId];
            }
        });
    };

    const handleSelectAllDocuments = (event) => {
        if (event.target.checked) {
            setSelectedDocuments(documents.map((doc) => doc.id));
        } else {
            setSelectedDocuments([]);
        }
    };

    const handleBulkDelete = async () => {
        if (selectedDocuments.length === 0) return;

        setBulkDeleteLoading(true);
        let successCount = 0;
        let errorCount = 0;
        const errors = [];

        try {
            // Process each document deletion sequentially
            for (const docId of selectedDocuments) {
                try {
                    await apiClient.delete(`/api/Documents/${docId}`);
                    successCount++;
                } catch (err) {
                    errorCount++;
                    console.error(`Error deleting document ID ${docId}:`, err);
                    errors.push(
                        `ID ${docId}: ${err.response?.data || "Unknown error"}`
                    );
                }
            }

            // Show appropriate message based on results
            if (successCount > 0 && errorCount === 0) {
                setSnackbar({
                    open: true,
                    message: `Successfully deleted ${successCount} document${
                        successCount > 1 ? "s" : ""
                    }`,
                    severity: "success",
                });
            } else if (successCount > 0 && errorCount > 0) {
                setSnackbar({
                    open: true,
                    message: `Deleted ${successCount} document${
                        successCount > 1 ? "s" : ""
                    }, but failed to delete ${errorCount}`,
                    severity: "warning",
                });
            } else {
                setSnackbar({
                    open: true,
                    message: "Failed to delete any documents",
                    severity: "error",
                });
            }

            // Refresh documents
            fetchDocuments();
        } catch (err) {
            console.error("Error in bulk delete:", err);
            setSnackbar({
                open: true,
                message: "Error during bulk delete operation",
                severity: "error",
            });
        } finally {
            setBulkDeleteLoading(false);
            setBulkDeleteDialog(false);
        }
    };

    // Additional state variables for filtered data
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const [filteredFolders, setFilteredFolders] = useState([]);

    // Add this effect to update filtered documents and folders
    useEffect(() => {
        applyFilters();
    }, [documents, filterText, filterField]);

    useEffect(() => {
        if (!filterText.trim()) {
            setFilteredFolders(folders);
        } else {
            const searchTerm = filterText.toLowerCase().trim();
            setFilteredFolders(
                folders.filter(
                    (folder) =>
                        folder.name.toLowerCase().includes(searchTerm) ||
                        (folder.description || "")
                            .toLowerCase()
                            .includes(searchTerm)
                )
            );
        }
    }, [folders, filterText]);

    // For handling multiple file selection
    const handleFilesChange = (event) => {
        const files = Array.from(event.target.files);
        const validFiles = files.filter(
            (file) => file.type === "application/pdf"
        );

        if (validFiles.length < files.length) {
            setSnackbar({
                open: true,
                message:
                    "Only PDF files are allowed. Non-PDF files were ignored.",
                severity: "warning",
            });
        }

        setSelectedFiles((prev) => [...prev, ...validFiles]);
    };

    // For removing a file from selection
    const removeFile = (index) => {
        setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
    };

    // Update handleUploadDocument to handle multiple files
    const handleUploadDocuments = async () => {
        if (selectedFiles.length === 0) {
            setSnackbar({
                open: true,
                message: "Please select at least one file",
                severity: "error",
            });
            return;
        }

        setUploadLoading(true);
        let successCount = 0;
        let errorCount = 0;

        try {
            // Process each file sequentially to avoid overwhelming the server
            for (const file of selectedFiles) {
                const formData = new FormData();
                formData.append("file", file);

                if (currentFolder) {
                    formData.append("folderId", currentFolder.id);
                }

                if (fileDescription) {
                    formData.append("description", fileDescription);
                }

                try {
                    await apiClient.post("/api/Documents", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                    successCount++;
                } catch (err) {
                    console.error(`Error uploading ${file.name}:`, err);
                    errorCount++;
                }
            }

            // Show appropriate message based on results
            if (successCount > 0 && errorCount === 0) {
                setSnackbar({
                    open: true,
                    message: `${successCount} document${
                        successCount > 1 ? "s" : ""
                    } uploaded successfully`,
                    severity: "success",
                });
            } else if (successCount > 0 && errorCount > 0) {
                setSnackbar({
                    open: true,
                    message: `${successCount} document${
                        successCount > 1 ? "s" : ""
                    } uploaded, ${errorCount} failed`,
                    severity: "warning",
                });
            } else {
                setSnackbar({
                    open: true,
                    message: "Failed to upload documents",
                    severity: "error",
                });
            }

            // Reset form and refresh documents
            setSelectedFiles([]);
            setFileDescription("");
            setUploadDialog(false);
            fetchDocuments();
        } catch (err) {
            console.error("Error in bulk upload:", err);
            setSnackbar({
                open: true,
                message: "Error uploading documents",
                severity: "error",
            });
        } finally {
            setUploadLoading(false);
        }
    };

    return (
        <Box
            sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                padding: 4,
                paddingTop: 2,
                boxSizing: "border-box",
                overflow: "hidden",
            }}
        >
            <Typography variant="h4" gutterBottom align="center">
                PDF Management
            </Typography>

            <Paper
                sx={{
                    width: "100%",
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    mb: 2,
                }}
            >
                {/* Actions Toolbar */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: 2,
                        alignItems: "center",
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        {folderPath.length > 0 && (
                            <IconButton
                                onClick={handleBackClick}
                                sx={{ mr: 1 }}
                                aria-label="go back"
                            >
                                <ArrowBackIcon />
                            </IconButton>
                        )}

                        <Breadcrumbs
                            aria-label="folder path"
                            sx={{ flexGrow: 1 }}
                        >
                            <MuiLink
                                component="button"
                                variant="body1"
                                onClick={() => handleFolderPathClick(0)}
                                sx={{
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <FolderIcon sx={{ mr: 0.5 }} fontSize="small" />
                                Root
                            </MuiLink>

                            {folderPath.map((folder, index) => (
                                <MuiLink
                                    key={folder.id}
                                    component="button"
                                    variant="body1"
                                    onClick={() =>
                                        handleFolderPathClick(index + 1)
                                    }
                                    sx={{
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <FolderIcon
                                        sx={{ mr: 0.5 }}
                                        fontSize="small"
                                    />
                                    {folder.name}
                                </MuiLink>
                            ))}
                        </Breadcrumbs>
                    </Box>

                    <Box>
                        <Button
                            variant="outlined"
                            startIcon={<CreateNewFolderIcon />}
                            onClick={() => setNewFolderDialog(true)}
                            sx={{ mr: 1 }}
                        >
                            New Folder
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<UploadFileIcon />}
                            onClick={() => setUploadDialog(true)}
                        >
                            Upload
                        </Button>
                    </Box>
                </Box>
                <Divider />
                {/* Content Area */}
                <Box sx={{ flexGrow: 1, overflowY: "auto", p: 2 }}>
                    {/* Search and Filter Bar */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            p: 2,
                            alignItems: "center",
                        }}
                    >
                        {/* Filter */}
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                width: "60%",
                            }}
                        >
                            <TextField
                                variant="outlined"
                                placeholder="Search..."
                                size="small"
                                fullWidth
                                value={filterText}
                                onChange={(e) => setFilterText(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: filterText ? (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                onClick={clearFilter}
                                                edge="end"
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : null,
                                }}
                            />
                            <Tooltip title="Filter by field">
                                <Box>
                                    <IconButton
                                        color={
                                            filterField !== "all"
                                                ? "primary"
                                                : "default"
                                        }
                                        aria-label="Filter options"
                                        onClick={(e) => {
                                            e.currentTarget.nextElementSibling.style.display =
                                                e.currentTarget
                                                    .nextElementSibling.style
                                                    .display === "none"
                                                    ? "flex"
                                                    : "none";
                                        }}
                                    >
                                        <FilterIcon />
                                    </IconButton>
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            zIndex: 10,
                                            bgcolor: "background.paper",
                                            boxShadow: 3,
                                            borderRadius: 1,
                                            p: 1,
                                            display: "none",
                                            flexDirection: "column",
                                            gap: 0.5,
                                        }}
                                    >
                                        {[
                                            { id: "all", label: "All Fields" },
                                            {
                                                id: "fileName",
                                                label: "File Name",
                                            },
                                            {
                                                id: "description",
                                                label: "Description",
                                            },
                                            {
                                                id: "date",
                                                label: "Upload Date",
                                            },
                                        ].map((option) => (
                                            <Button
                                                key={option.id}
                                                size="small"
                                                variant={
                                                    filterField === option.id
                                                        ? "contained"
                                                        : "text"
                                                }
                                                onClick={() => {
                                                    setFilterField(option.id);
                                                    // Hide the filter dropdown
                                                    document.querySelector(
                                                        '[aria-label="Filter options"]'
                                                    ).nextElementSibling.style.display =
                                                        "none";
                                                }}
                                            >
                                                {option.label}
                                            </Button>
                                        ))}
                                    </Box>
                                </Box>
                            </Tooltip>
                            {filterField !== "all" && (
                                <Typography variant="caption" sx={{ ml: 1 }}>
                                    Filtering by:{" "}
                                    {filterField === "fileName"
                                        ? "File Name"
                                        : filterField === "description"
                                        ? "Description"
                                        : filterField === "date"
                                        ? "Upload Date"
                                        : ""}
                                </Typography>
                            )}
                        </Box>
                    </Box>

                    {loading ? (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                p: 3,
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : error ? (
                        <Typography color="error" align="center">
                            {error}
                        </Typography>
                    ) : (
                        <>
                            {/* Folders Section */}
                            {folders.length > 0 && (
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Folders
                                    </Typography>
                                    <TableContainer>
                                        <Table
                                            stickyHeader
                                            aria-label="folders table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>
                                                        Description
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredFolders.length ===
                                                0 ? (
                                                    <TableRow>
                                                        <TableCell
                                                            colSpan={3}
                                                            align="center"
                                                        >
                                                            {filterText
                                                                ? "No folders match your search"
                                                                : "No folders available"}
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    filteredFolders.map(
                                                        (folder) => (
                                                            <TableRow
                                                                key={folder.id}
                                                                hover
                                                                onClick={() =>
                                                                    handleFolderClick(
                                                                        folder
                                                                    )
                                                                }
                                                                sx={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <TableCell>
                                                                    <Box
                                                                        sx={{
                                                                            display:
                                                                                "flex",
                                                                            alignItems:
                                                                                "center",
                                                                        }}
                                                                    >
                                                                        <FolderIcon
                                                                            color="primary"
                                                                            sx={{
                                                                                mr: 1,
                                                                            }}
                                                                        />
                                                                        {
                                                                            folder.name
                                                                        }
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {folder.description ||
                                                                        "-"}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <IconButton
                                                                        size="small"
                                                                        color="error"
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            setDeleteItem(
                                                                                folder
                                                                            );
                                                                            setDeleteDialog(
                                                                                true
                                                                            );
                                                                        }}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            )}

                            {/* Documents Section */}
                            <Box>
                                <Typography variant="h6" gutterBottom>
                                    Documents
                                </Typography>
                                {documents.length > 0 && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            mb: 1,
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{ mr: 2 }}
                                        >
                                            {selectedDocuments.length} selected
                                        </Typography>
                                        {selectedDocuments.length > 0 && (
                                            <Button
                                                variant="contained"
                                                color="error"
                                                size="small"
                                                startIcon={<DeleteSweepIcon />}
                                                onClick={() =>
                                                    setBulkDeleteDialog(true)
                                                }
                                            >
                                                Delete Selected
                                            </Button>
                                        )}
                                    </Box>
                                )}
                                <TableContainer>
                                    <Table
                                        stickyHeader
                                        aria-label="documents table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {documents.length > 0 && (
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            indeterminate={
                                                                selectedDocuments.length >
                                                                    0 &&
                                                                selectedDocuments.length <
                                                                    documents.length
                                                            }
                                                            checked={
                                                                documents.length >
                                                                    0 &&
                                                                selectedDocuments.length ===
                                                                    documents.length
                                                            }
                                                            onChange={
                                                                handleSelectAllDocuments
                                                            }
                                                        />
                                                    </TableCell>
                                                )}
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={
                                                            orderBy ===
                                                            "fileName"
                                                        }
                                                        direction={
                                                            orderBy ===
                                                            "fileName"
                                                                ? order
                                                                : "asc"
                                                        }
                                                        onClick={() =>
                                                            handleRequestSort(
                                                                "fileName"
                                                            )
                                                        }
                                                    >
                                                        <strong>
                                                            Document Name
                                                        </strong>
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={
                                                            orderBy ===
                                                            "description"
                                                        }
                                                        direction={
                                                            orderBy ===
                                                            "description"
                                                                ? order
                                                                : "asc"
                                                        }
                                                        onClick={() =>
                                                            handleRequestSort(
                                                                "description"
                                                            )
                                                        }
                                                    >
                                                        <strong>
                                                            Description
                                                        </strong>
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={
                                                            orderBy ===
                                                            "fileSize"
                                                        }
                                                        direction={
                                                            orderBy ===
                                                            "fileSize"
                                                                ? order
                                                                : "asc"
                                                        }
                                                        onClick={() =>
                                                            handleRequestSort(
                                                                "fileSize"
                                                            )
                                                        }
                                                    >
                                                        <strong>Size</strong>
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={
                                                            orderBy ===
                                                            "uploadDate"
                                                        }
                                                        direction={
                                                            orderBy ===
                                                            "uploadDate"
                                                                ? order
                                                                : "asc"
                                                        }
                                                        onClick={() =>
                                                            handleRequestSort(
                                                                "uploadDate"
                                                            )
                                                        }
                                                    >
                                                        <strong>
                                                            Upload Date
                                                        </strong>
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <strong>Actions</strong>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredDocuments.length === 0 ? (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={5}
                                                        align="center"
                                                    >
                                                        {filterText
                                                            ? "No documents match your search"
                                                            : folders.length > 0
                                                            ? "No documents in this folder"
                                                            : "No documents available"}
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                getSortedData(
                                                    filteredDocuments
                                                ).map((document) => (
                                                    <TableRow
                                                        key={document.id}
                                                        hover
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                checked={selectedDocuments.includes(
                                                                    document.id
                                                                )}
                                                                onChange={() =>
                                                                    handleSelectDocument(
                                                                        document.id
                                                                    )
                                                                }
                                                                onClick={(e) =>
                                                                    e.stopPropagation()
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                <PdfIcon
                                                                    color="error"
                                                                    sx={{
                                                                        mr: 1,
                                                                    }}
                                                                />
                                                                {
                                                                    document.originalFileName
                                                                }
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            {document.description ||
                                                                "-"}
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatFileSize(
                                                                document.fileSize
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatDate(
                                                                document.uploadDate
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Tooltip title="View">
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        handleViewDocument(
                                                                            document
                                                                        )
                                                                    }
                                                                    size="small"
                                                                >
                                                                    <VisibilityIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Download">
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        handleDownloadDocument(
                                                                            document
                                                                        )
                                                                    }
                                                                    size="small"
                                                                >
                                                                    <GetAppIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Associate">
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        setSelectedDocument(
                                                                            document
                                                                        );
                                                                        setAssociateDialog(
                                                                            true
                                                                        );
                                                                    }}
                                                                    size="small"
                                                                >
                                                                    <LinkIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete">
                                                                <IconButton
                                                                    color="error"
                                                                    onClick={() => {
                                                                        setDeleteItem(
                                                                            document
                                                                        );
                                                                        setDeleteDialog(
                                                                            true
                                                                        );
                                                                    }}
                                                                    size="small"
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box
                                    sx={{
                                        p: 2,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        {filterText
                                            ? `Showing ${filteredDocuments.length} of ${documents.length} documents`
                                            : `Total: ${documents.length} documents`}
                                    </Typography>
                                    {filterText && (
                                        <Button
                                            size="small"
                                            startIcon={<ClearIcon />}
                                            onClick={clearFilter}
                                        >
                                            Clear Filter
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </Paper>

            {/* Back Button */}
            <Box sx={{ mt: 2, textAlign: "center" }}>
                <Button
                    variant="contained"
                    component={Link}
                    to="/pumpstation-price-tables"
                >
                    Back to Pump Station Tables
                </Button>
            </Box>

            {/* Context Menu */}
            <Menu
                open={contextMenu !== null}
                onClose={handleCloseContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                {menuTarget && "name" in menuTarget ? (
                    // Folder context menu
                    <MenuItem onClick={() => handleContextMenuAction("delete")}>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Delete Folder</ListItemText>
                    </MenuItem>
                ) : (
                    // Document context menu
                    <>
                        <MenuItem
                            onClick={() => handleContextMenuAction("view")}
                        >
                            <ListItemIcon>
                                <PdfIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>View Document</ListItemText>
                        </MenuItem>
                        <MenuItem
                            onClick={() => handleContextMenuAction("download")}
                        >
                            <ListItemIcon>
                                <UploadFileIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Download</ListItemText>
                        </MenuItem>
                        <MenuItem
                            onClick={() => handleContextMenuAction("associate")}
                        >
                            <ListItemIcon>
                                <LinkIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Associate with Record</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => handleContextMenuAction("delete")}
                        >
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Delete Document</ListItemText>
                        </MenuItem>
                    </>
                )}
            </Menu>

            {/* New Folder Dialog */}
            <Dialog
                open={newFolderDialog}
                onClose={() => setNewFolderDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Create New Folder</DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ mt: 1 }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Folder Name"
                            fullWidth
                            variant="outlined"
                            value={newFolderName}
                            onChange={(e) => setNewFolderName(e.target.value)}
                            required
                        />
                        <TextField
                            margin="dense"
                            label="Description (Optional)"
                            fullWidth
                            variant="outlined"
                            value={newFolderDescription}
                            onChange={(e) =>
                                setNewFolderDescription(e.target.value)
                            }
                            multiline
                            rows={3}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNewFolderDialog(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleCreateFolder} variant="contained">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Upload Dialog */}
            <Dialog
                open={uploadDialog}
                onClose={() => setUploadDialog(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Upload Documents</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select PDF files to upload.
                        {currentFolder && (
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                Current folder:{" "}
                                <strong>{currentFolder.name}</strong>
                            </Typography>
                        )}
                    </DialogContentText>
                    <Box component="form" sx={{ mt: 2 }}>
                        <Button
                            variant="outlined"
                            component="label"
                            fullWidth
                            startIcon={<UploadFileIcon />}
                            sx={{
                                mb: 2,
                                height: 56,
                                justifyContent: "flex-start",
                            }}
                        >
                            {selectedFiles.length === 0
                                ? "Choose Files"
                                : `${selectedFiles.length} file(s) selected`}
                            <input
                                type="file"
                                hidden
                                accept="application/pdf"
                                onChange={handleFilesChange}
                                multiple
                            />
                        </Button>

                        {selectedFiles.length > 0 && (
                            <TableContainer sx={{ mb: 2 }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>File Name</TableCell>
                                            <TableCell>Size</TableCell>
                                            <TableCell align="right">
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedFiles.map((file, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <PdfIcon
                                                            color="error"
                                                            sx={{ mr: 1 }}
                                                        />
                                                        {file.name}
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    {formatFileSize(file.size)}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <IconButton
                                                        size="small"
                                                        color="error"
                                                        onClick={() =>
                                                            removeFile(index)
                                                        }
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}

                        <TextField
                            margin="dense"
                            label="Description (Optional - will be applied to all files)"
                            fullWidth
                            variant="outlined"
                            value={fileDescription}
                            onChange={(e) => setFileDescription(e.target.value)}
                            multiline
                            rows={3}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUploadDialog(false)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleUploadDocuments}
                        variant="contained"
                        disabled={selectedFiles.length === 0}
                        startIcon={
                            uploadLoading && (
                                <CircularProgress size={16} color="inherit" />
                            )
                        }
                    >
                        Upload{" "}
                        {selectedFiles.length > 0
                            ? `(${selectedFiles.length})`
                            : ""}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {deleteItem && "name" in deleteItem
                            ? `Are you sure you want to delete the folder "${deleteItem.name}"? This action cannot be undone.`
                            : `Are you sure you want to delete the document "${deleteItem?.originalFileName}"? This action cannot be undone.`}
                    </DialogContentText>
                    {deleteItem && "name" in deleteItem && (
                        <DialogContentText sx={{ mt: 2, color: "error.main" }}>
                            Note: You cannot delete folders that contain
                            documents or subfolders.
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialog(false)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteItem}
                        variant="contained"
                        color="error"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Associate Document Dialog */}
            <Dialog
                open={associateDialog}
                onClose={() => setAssociateDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Associate Document with Record</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selectedDocument && (
                            <Typography variant="body2" sx={{ mb: 2 }}>
                                Document:{" "}
                                <strong>
                                    {selectedDocument.originalFileName}
                                </strong>
                            </Typography>
                        )}
                    </DialogContentText>
                    <Box component="form" sx={{ mt: 1 }}>
                        <TextField
                            select
                            margin="dense"
                            label="Entity Type"
                            fullWidth
                            variant="outlined"
                            value={entityType}
                            onChange={(e) => setEntityType(e.target.value)}
                            sx={{ mb: 2 }}
                        >
                            {entityTypeOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            select
                            margin="dense"
                            label="Select Record"
                            fullWidth
                            variant="outlined"
                            value={entityId}
                            onChange={(e) => setEntityId(e.target.value)}
                        >
                            {entityOptions.map((option) => (
                                <MenuItem
                                    key={option.id}
                                    value={option.id || option.Id}
                                >
                                    {entityType === "ControllerPrice"
                                        ? `CT-${(option.id || option.Id)
                                              .toString()
                                              .padStart(3, "0")} - ${
                                              option.control_Type ||
                                              option.Control_Type ||
                                              "Unnamed"
                                          }`
                                        : `ID: ${option.id || option.Id}`}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAssociateDialog(false)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleAssociateDocument}
                        variant="contained"
                        disabled={!selectedDocument || !entityType || !entityId}
                    >
                        Associate
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
            {/* Add this as a new Dialog component at the end of your component */}
            {/* Bulk Delete Confirmation Dialog */}
            <Dialog
                open={bulkDeleteDialog}
                onClose={() => setBulkDeleteDialog(false)}
            >
                <DialogTitle>Confirm Bulk Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete{" "}
                        {selectedDocuments.length} document
                        {selectedDocuments.length > 1 ? "s" : ""}? This action
                        cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setBulkDeleteDialog(false)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleBulkDelete}
                        variant="contained"
                        color="error"
                        disabled={bulkDeleteLoading}
                        startIcon={
                            bulkDeleteLoading ? (
                                <CircularProgress size={16} color="inherit" />
                            ) : (
                                <DeleteSweepIcon />
                            )
                        }
                    >
                        Delete Selected
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default DocumentManager;
