import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Box,
    Grid,
    Snackbar,
    Alert,
    CircularProgress,
    InputAdornment,
    Tooltip,
    Divider,
    MenuItem,
} from "@mui/material";
import {
    Description as DescriptionIcon,
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Search as SearchIcon,
    FilterAlt as FilterIcon,
    Clear as ClearIcon,
} from "@mui/icons-material";
import apiClient from "../../Helpers/apiClient";
import DocumentAssociation from "../DocumentManagerPage/DocumentAssociation";

function ControllerPriceTable() {
    const [controllerPrices, setControllerPrices] = useState([]);
    const [filteredControllerPrices, setFilteredControllerPrices] = useState(
        []
    );
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedController, setSelectedController] = useState(null);
    const [levelControlOptions, setLevelControlOptions] = useState([]);
    const [mixerTypeOptions, setMixerTypeOptions] = useState([]);
    const [formData, setFormData] = useState({
        id: 0,
        controlType: "",
        noOfPumps: null,
        voltage: null,
        kw: null,
        minAmps: null,
        maxAmps: null,
        mixer: null,
        model: "",
        description: "",
        costPrice: null,
        sellPrice: null,
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });

    // Sorting states
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("id");

    // Filtering states
    const [filterText, setFilterText] = useState("");
    const [filterField, setFilterField] = useState("all");

    const [documentDialogOpen, setDocumentDialogOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        fetchControllerPrices();
    }, []);

    // Apply filters whenever controllerPrices or filter settings change
    useEffect(() => {
        applyFilters();
    }, [controllerPrices, filterText, filterField]);

    const fetchControllerPrices = async () => {
        setLoading(true);
        try {
            const response = await apiClient.get(
                "/api/PumpStationPriceTables/ControllerPrices"
            );

            if (Array.isArray(response.data)) {
                setControllerPrices(response.data);
            } else {
                console.warn("Response is not an array:", response.data);
                setControllerPrices([]);
            }

            setError(null);
        } catch (err) {
            console.error("Error fetching controller prices:", err);
            setError(`Failed to load data: ${err.message}`);
            setControllerPrices([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchControllerPrices();
        fetchLevelControlOptions();
        fetchMixerTypeOptions();
    }, []);

    const handleOpenDocumentDialog = (controller) => {
        setSelectedController(controller);
        setDocumentDialogOpen(true);
    };

    const handleOpenDialog = (controller = null) => {
        if (controller) {
            // Debug output
            console.log("Controller object:", controller);

            setFormData({
                id: controller.id || controller.Id || 0,
                controlType:
                    controller.control_Type || controller.Control_Type || "",
                noOfPumps:
                    controller.no_of_Pumps || controller.No_of_Pumps || null,
                voltage: controller.voltage || controller.Voltage || null,
                kw: controller.kw || controller.Kw || null,
                minAmps: controller.minAmps || controller.MinAmps || null,
                maxAmps: controller.maxAmps || controller.MaxAmps || null,
                mixer: controller.mixer || controller.Mixer || null,
                model: controller.model || controller.Model || "",
                description:
                    controller.description || controller.Description || "",
                costPrice:
                    controller.cost_Price || controller.Cost_Price || null,
                sellPrice:
                    controller.sell_Price || controller.Sell_Price || null,
                // Match exactly to your API response properties
                levelControlDescription:
                    controller.levelControlDescription ||
                    controller.LevelControlDescription ||
                    "",
                mixerType: controller.mixerType || controller.MixerType || "",
            });
            setSelectedController(controller);
        } else {
            // Reset form for new entry
            setFormData({
                id: 0,
                controlType: "",
                noOfPumps: null,
                voltage: null,
                kw: null,
                minAmps: null,
                maxAmps: null,
                mixer: null,
                model: "",
                description: "",
                costPrice: null,
                sellPrice: null,
                levelControlDescription: "",
                mixerType: "",
            });
            setSelectedController(null);
        }
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDeleteDialog = (controller) => {
        setSelectedController(controller);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedController(null);
    };

    const handleFormChange = (e) => {
        const { name, value, type } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]:
                type === "number"
                    ? value === ""
                        ? null
                        : parseFloat(value)
                    : value,
        }));
    };

    const handleSubmit = async () => {
        try {
            // Convert to PascalCase to match C# model
            const payload = {
                Id: formData.id,
                Control_Type: formData.controlType,
                No_of_Pumps: formData.noOfPumps,
                Voltage: formData.voltage,
                Kw: formData.kw,
                MinAmps: formData.minAmps,
                MaxAmps: formData.maxAmps,
                Mixer: formData.mixer,
                Model: formData.model,
                Description: formData.description,
                Cost_Price: formData.costPrice,
                Sell_Price: formData.sellPrice,
                // Use the exact property names from your model
                LevelControlDescription: formData.levelControlDescription,
                MixerType: formData.mixerType,
                Quotations: [], // Always include this for navigation property
            };

            let response;
            if (selectedController) {
                // Update existing record
                response = await apiClient.put(
                    `/api/PumpStationPriceTables/ControllerPrices/${payload.Id}`,
                    payload
                );
                setSnackbar({
                    open: true,
                    message: "Controller price updated successfully!",
                    severity: "success",
                });
            } else {
                // Create new record
                response = await apiClient.post(
                    "/api/PumpStationPriceTables/ControllerPrices",
                    payload
                );
                setSnackbar({
                    open: true,
                    message: "Controller price created successfully!",
                    severity: "success",
                });
            }

            handleCloseDialog();
            fetchControllerPrices();
        } catch (err) {
            console.error("Error saving controller price:", err);
            setSnackbar({
                open: true,
                message: `Error: ${
                    err.response?.data?.message ||
                    "Failed to save. Please try again."
                }`,
                severity: "error",
            });
        }
    };

    const handleDelete = async () => {
        try {
            await apiClient.delete(
                `/api/PumpStationPriceTables/ControllerPrices/${
                    selectedController.id || selectedController.Id
                }`
            );
            setSnackbar({
                open: true,
                message: "Controller price deleted successfully!",
                severity: "success",
            });
            handleCloseDeleteDialog();
            fetchControllerPrices();
        } catch (err) {
            console.error("Error deleting controller price:", err);
            setSnackbar({
                open: true,
                message: `Error: ${
                    err.response?.data?.message ||
                    "Failed to delete. Please try again."
                }`,
                severity: "error",
            });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    // Sorting functionality
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const getSortedData = (data) => {
        if (!data.length) return [];

        return [...data].sort((a, b) => {
            // Mapping between component state and backend properties
            const propertyMap = {
                id: ["id", "Id"],
                controlType: ["control_Type", "Control_Type"],
                noOfPumps: ["no_of_Pumps", "No_of_Pumps"],
                voltage: ["voltage", "Voltage"],
                kw: ["kw", "Kw"],
                minAmps: ["minAmps", "MinAmps"],
                maxAmps: ["maxAmps", "MaxAmps"],
                mixer: ["mixer", "Mixer"],
                model: ["model", "Model"],
                description: ["description", "Description"],
                costPrice: ["cost_Price", "Cost_Price"],
                sellPrice: ["sell_Price", "Sell_Price"],
            };

            // Find the current property's possible keys
            const currentProp = propertyMap[orderBy] || [orderBy, orderBy];

            // Get values, prioritizing camelCase then PascalCase
            const getPropertyValue = (obj) => {
                for (let key of currentProp) {
                    if (obj[key] !== undefined) return obj[key];
                }
                return null;
            };

            const valueA = getPropertyValue(a);
            const valueB = getPropertyValue(b);

            // Check if either value is 'N/A' or null
            const isNAValue = (val) => val === null || val === "N/A";

            // If one of the values is 'N/A', put it at the bottom
            if (isNAValue(valueA) && !isNAValue(valueB)) return 1;
            if (!isNAValue(valueA) && isNAValue(valueB)) return -1;
            if (isNAValue(valueA) && isNAValue(valueB)) return 0;

            // Handle numeric sorting
            if (typeof valueA === "number" && typeof valueB === "number") {
                return order === "asc"
                    ? (valueA || 0) - (valueB || 0)
                    : (valueB || 0) - (valueA || 0);
            }

            // Handle string sorting
            if (typeof valueA === "string" && typeof valueB === "string") {
                return order === "asc"
                    ? valueA.localeCompare(valueB)
                    : valueB.localeCompare(valueA);
            }

            // Fallback for mixed types
            return 0;
        });
    };

    // Filtering functionality
    const handleFilterChange = (event) => {
        setFilterText(event.target.value);
    };

    const handleFilterFieldChange = (field) => {
        setFilterField(field);
    };

    const clearFilter = () => {
        setFilterText("");
        setFilterField("all");
    };

    const applyFilters = () => {
        if (!filterText.trim()) {
            setFilteredControllerPrices(controllerPrices);
            return;
        }

        const searchTerm = filterText.toLowerCase().trim();

        const filtered = controllerPrices.filter((controller) => {
            // Comprehensive filter across all fields
            const fieldsToSearch = [
                controller.id || controller.Id,
                controller.control_Type || controller.Control_Type,
                controller.no_of_Pumps || controller.No_of_Pumps,
                controller.voltage || controller.Voltage,
                controller.kw || controller.Kw,
                controller.minAmps || controller.MinAmps,
                controller.maxAmps || controller.MaxAmps,
                controller.mixer || controller.Mixer,
                controller.model || controller.Model,
                controller.description || controller.Description,
                controller.cost_Price || controller.Cost_Price,
                controller.sell_Price || controller.Sell_Price,
            ];

            // Convert fields to strings for searching
            return fieldsToSearch.some(
                (field) =>
                    field !== null &&
                    field !== undefined &&
                    String(field).toLowerCase().includes(searchTerm)
            );
        });

        setFilteredControllerPrices(filtered);
    };

    // Format amp range for display
    const formatAmpRange = (controller) => {
        const minAmps = controller.minAmps || controller.MinAmps;
        const maxAmps = controller.maxAmps || controller.MaxAmps;

        if (minAmps !== null && maxAmps !== null) {
            return `${minAmps}-${maxAmps}`;
        } else if (minAmps !== null) {
            return `${minAmps}+`;
        } else if (maxAmps !== null) {
            return `0-${maxAmps}`;
        } else {
            return "N/A";
        }
    };
    // Add these fetch functions after your other fetch functions
    const fetchLevelControlOptions = async () => {
        try {
            const response = await apiClient.get(
                "/api/PumpStationPriceTables/LevelControlPrices"
            );
            if (Array.isArray(response.data)) {
                setLevelControlOptions(response.data);
            } else {
                console.warn(
                    "Level control response is not an array:",
                    response.data
                );
                setLevelControlOptions([]);
            }
        } catch (err) {
            console.error("Error fetching level control options:", err);
            setLevelControlOptions([]);
        }
    };

    const fetchMixerTypeOptions = async () => {
        try {
            const response = await apiClient.get(
                "/api/PumpStationPriceTables/MixerPrices"
            );
            if (Array.isArray(response.data)) {
                setMixerTypeOptions(response.data);
            } else {
                console.warn(
                    "Mixer type response is not an array:",
                    response.data
                );
                setMixerTypeOptions([]);
            }
        } catch (err) {
            console.error("Error fetching mixer type options:", err);
            setMixerTypeOptions([]);
        }
    };

    // Prepare data for rendering
    const displayData = getSortedData(filteredControllerPrices);

    return (
        <Box
            sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                padding: 4,
                paddingTop: 2,
                boxSizing: "border-box",
                overflow: "hidden",
            }}
        >
            <Typography variant="h4" gutterBottom align="center">
                Controller Price Table
            </Typography>

            <Paper
                sx={{
                    width: "100%",
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    mb: 2,
                }}
            >
                {/* Filter and Add Button Section */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: 2,
                        alignItems: "center",
                    }}
                >
                    <TextField
                        variant="outlined"
                        placeholder="Search across all fields"
                        size="small"
                        fullWidth
                        value={filterText}
                        onChange={handleFilterChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: filterText ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        size="small"
                                        onClick={clearFilter}
                                        edge="end"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ) : null,
                        }}
                        sx={{ mr: 2 }}
                    />
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => handleOpenDialog()}
                    >
                        Add New
                    </Button>
                </Box>

                <Divider />

                {/* Table Container with Vertical and Horizontal Scroll */}
                <TableContainer
                    sx={{
                        flexGrow: 1,
                        overflowX: "auto",
                        overflowY: "scroll", // Enable vertical scrolling
                        maxHeight: "calc(100vh - 300px)", // Adjust height as needed
                    }}
                >
                    <Table
                        stickyHeader
                        sx={{
                            minWidth: 1200, // Ensure minimum width for horizontal scrolling
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                {[
                                    { id: "id", label: "ID" },
                                    {
                                        id: "controlType",
                                        label: "Control Type",
                                    },
                                    { id: "noOfPumps", label: "No. of Pumps" },
                                    { id: "voltage", label: "Voltage" },
                                    { id: "kw", label: "KW" },
                                    { id: "minAmps", label: "Min Amps" },
                                    { id: "maxAmps", label: "Max Amps" },
                                    // { id: "mixer", label: "Mixer" },
                                    { id: "mixerType", label: "Mixer Type" }, // New column
                                    {
                                        id: "levelControlDescription",
                                        label: "Level Control",
                                    }, // New column
                                    { id: "model", label: "Model" },
                                    { id: "description", label: "Description" },
                                    { id: "costPrice", label: "Cost Price" },
                                    { id: "sellPrice", label: "Sell Price" },
                                    { id: "actions", label: "Actions" },
                                ].map((column) =>
                                    column.id !== "actions" ? (
                                        <TableCell key={column.id}>
                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={
                                                    orderBy === column.id
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort(column.id)
                                                }
                                            >
                                                <strong>{column.label}</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                    ) : (
                                        <TableCell
                                            key={column.id}
                                            align="center"
                                        >
                                            <strong>{column.label}</strong>
                                        </TableCell>
                                    )
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {displayData.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={15} align="center">
                                        {filterText
                                            ? "No matches found. Try a different search."
                                            : "No data available. Add your first controller price."}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                displayData.map((controller) => (
                                    <TableRow
                                        key={controller.id || controller.Id}
                                        hover
                                    >
                                        <TableCell>
                                            CT-
                                            {(controller.id || controller.Id)
                                                .toString()
                                                .padStart(3, "0")}
                                        </TableCell>
                                        <TableCell>
                                            {controller.control_Type ||
                                                controller.Control_Type ||
                                                "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            {controller.no_of_Pumps ||
                                                controller.No_of_Pumps ||
                                                "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            {controller.voltage ||
                                                controller.Voltage ||
                                                "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            {controller.kw ||
                                                controller.Kw ||
                                                "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            {controller.minAmps ||
                                                controller.MinAmps ||
                                                "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            {controller.maxAmps ||
                                                controller.MaxAmps ||
                                                "N/A"}
                                        </TableCell>
                                        {/* <TableCell>
                                            {controller.mixer ||
                                                controller.Mixer ||
                                                "N/A"}
                                        </TableCell> */}
                                        {/* New mixer type column */}
                                        <TableCell>
                                            {controller.mixerType ||
                                                controller.MixerType ||
                                                "N/A"}
                                        </TableCell>
                                        {/* New level control column */}
                                        <TableCell>
                                            {controller.levelControlDescription ||
                                                controller.LevelControlDescription ||
                                                "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            {controller.model ||
                                                controller.Model ||
                                                "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            {(
                                                controller.description ||
                                                controller.Description ||
                                                ""
                                            ).length > 30
                                                ? `${(
                                                      controller.description ||
                                                      controller.Description
                                                  ).substring(0, 30)}...`
                                                : controller.description ||
                                                  controller.Description ||
                                                  "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            $
                                            {Number(
                                                controller.cost_Price ||
                                                    controller.Cost_Price ||
                                                    0
                                            ).toFixed(2)}
                                        </TableCell>
                                        <TableCell>
                                            $
                                            {Number(
                                                controller.sell_Price ||
                                                    controller.Sell_Price ||
                                                    0
                                            ).toFixed(2)}
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton
                                                color="primary"
                                                onClick={() =>
                                                    handleOpenDialog(controller)
                                                }
                                                aria-label="edit"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                color="secondary"
                                                onClick={() =>
                                                    handleOpenDocumentDialog(
                                                        controller
                                                    )
                                                }
                                                aria-label="documents"
                                                title="Manage Documents"
                                            >
                                                <DescriptionIcon />
                                            </IconButton>
                                            <IconButton
                                                color="error"
                                                onClick={() =>
                                                    handleOpenDeleteDialog(
                                                        controller
                                                    )
                                                }
                                                aria-label="delete"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Summary and Pagination */}
                <Box
                    sx={{
                        p: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="body2" color="text.secondary">
                        {filterText
                            ? `Showing ${displayData.length} of ${controllerPrices.length} items`
                            : `Total: ${controllerPrices.length} items`}
                    </Typography>
                    {filterText && (
                        <Button
                            size="small"
                            startIcon={<ClearIcon />}
                            onClick={clearFilter}
                        >
                            Clear Filter
                        </Button>
                    )}
                </Box>
            </Paper>

            {/* Back to Main Tables Button */}
            <Box sx={{ mt: 2, textAlign: "center", flexShrink: 0 }}>
                <Button
                    variant="contained"
                    component={Link}
                    to="/pumpstation-price-tables"
                    sx={{ mt: 2 }}
                >
                    Back to Pump Station Tables
                </Button>
            </Box>

            {/* Create/Edit Dialog */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    {selectedController
                        ? "Edit Controller Price"
                        : "Add New Controller Price"}
                </DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Control Type"
                                    name="controlType"
                                    value={formData.controlType}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    helperText="e.g., Dual Pump Control"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Number of Pumps"
                                    name="noOfPumps"
                                    type="number"
                                    value={formData.noOfPumps || ""}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    inputProps={{ min: 0 }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Voltage"
                                    name="voltage"
                                    type="number"
                                    value={formData.voltage || ""}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    inputProps={{ step: "0.1" }}
                                    helperText="e.g., 240, 415"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="KW"
                                    name="kw"
                                    type="number"
                                    value={formData.kw || ""}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    inputProps={{ step: "0.1" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField
                                    fullWidth
                                    label="Min Amps"
                                    name="minAmps"
                                    type="number"
                                    value={formData.minAmps || ""}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    inputProps={{ step: "0.1", min: "0" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField
                                    fullWidth
                                    label="Max Amps"
                                    name="maxAmps"
                                    type="number"
                                    value={formData.maxAmps || ""}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    inputProps={{ step: "0.1", min: "0" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Model"
                                    name="model"
                                    value={formData.model}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                />
                            </Grid>

                            {/* Add these two new Grid items inside the Grid container in your Dialog content */}
                            <Grid item xs={12} md={6}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Level Control"
                                    name="levelControlDescription"
                                    value={
                                        formData.levelControlDescription || ""
                                    }
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    helperText="Select level control description"
                                >
                                    <MenuItem value="">None</MenuItem>
                                    {levelControlOptions.map((option) => (
                                        <MenuItem
                                            key={option.id || option.Id}
                                            value={
                                                option.description ||
                                                option.Description
                                            }
                                        >
                                            {option.description ||
                                                option.Description}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Mixer Type"
                                    name="mixerType"
                                    value={formData.mixerType || ""}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    helperText="Select mixer type"
                                >
                                    <MenuItem value="Not Required">
                                        Not Required
                                    </MenuItem>
                                    {mixerTypeOptions.map((option) => (
                                        <MenuItem
                                            key={option.id || option.Id}
                                            value={
                                                option.mixerType ||
                                                option.MixerType
                                            }
                                        >
                                            {option.mixerType ||
                                                option.MixerType}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Cost Price"
                                    name="costPrice"
                                    type="number"
                                    inputProps={{ step: "0.01", min: "0" }}
                                    value={formData.costPrice || ""}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Sell Price"
                                    name="sellPrice"
                                    type="number"
                                    inputProps={{ step: "0.01", min: "0" }}
                                    value={formData.sellPrice || ""}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                    >
                        {selectedController ? "Update" : "Save"}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this controller price?
                        This action cannot be undone.
                    </DialogContentText>
                    <DialogContentText sx={{ mt: 2, fontWeight: "bold" }}>
                        Details:
                        <br />
                        Control Type:{" "}
                        {selectedController?.control_Type ||
                            selectedController?.Control_Type ||
                            "N/A"}
                        <br />
                        Model:{" "}
                        {selectedController?.model ||
                            selectedController?.Model ||
                            "N/A"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
                    <Button
                        onClick={handleDelete}
                        color="error"
                        variant="contained"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Dialog
                open={documentDialogOpen}
                onClose={() => setDocumentDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    Document Management
                    {selectedController
                        ? ` - CT-${
                              selectedController.id
                                  ?.toString()
                                  .padStart(3, "0") ||
                              selectedController.Id?.toString().padStart(3, "0")
                          }`
                        : ""}
                </DialogTitle>
                <DialogContent>
                    {selectedController && (
                        <DocumentAssociation
                            entityType="ControllerPrice"
                            entityId={
                                selectedController.id || selectedController.Id
                            }
                            entityName={`Controller ${
                                selectedController.control_Type ||
                                selectedController.Control_Type ||
                                ""
                            }`}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDocumentDialogOpen(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default ControllerPriceTable;
