import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Box,
    Grid,
    Snackbar,
    Alert,
    CircularProgress,
    InputAdornment,
    Tooltip,
    Divider,
    MenuItem,
} from "@mui/material";
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Search as SearchIcon,
    FilterAlt as FilterIcon,
    Clear as ClearIcon,
    ShowChart as ShowChartIcon,
    Description as DescriptionIcon,
} from "@mui/icons-material";
import apiClient from "../../Helpers/apiClient";
import PumpPerformanceEditor from "./PumpPerformanceEditor"; // Import the new component
import DocumentAssociation from "../DocumentManagerPage/DocumentAssociation";

function PumpPriceTable() {
    const [pumps, setPumps] = useState([]);
    const [filteredPumps, setFilteredPumps] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedPump, setSelectedPump] = useState(null);
    const [documentDialogOpen, setDocumentDialogOpen] = useState(false);
    // Add this with your other state variables
    const [pedestalOptions, setPedestalOptions] = useState([]);
    const [loadingPedestals, setLoadingPedestals] = useState(false);

    const [formData, setFormData] = useState({
        id: 0,
        model: "",
        supplierModel: "",
        useType: "",
        impellerType: "",
        description: "",
        pedestal: "",
        discharge: 0,
        voltage: 0,
        kw: 0,
        amps: 0,
        costPrice: 0,
        sellPrice: 0,
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });

    // New state for Performance Dialog
    const [openPerformanceDialog, setOpenPerformanceDialog] = useState(false);
    const [performancePumpId, setPerformancePumpId] = useState(null);

    // Sorting states
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("id");
    // Filtering states
    const [filterText, setFilterText] = useState("");
    const [filterField, setFilterField] = useState("all");

    const navigate = useNavigate();

    useEffect(() => {
        fetchPumps();
    }, []);

    // Apply filters whenever pumps or filter settings change
    useEffect(() => {
        applyFilters();
    }, [pumps, filterText, filterField]);
    const handleOpenDocumentDialog = (pump) => {
        setSelectedPump(pump);
        setDocumentDialogOpen(true);
    };

    const fetchPumps = async () => {
        setLoading(true);
        try {
            console.log("Fetching pumps...");

            const response = await apiClient.get(
                "/api/PumpStationPriceTables/PumpPrices"
            );

            console.log("Response received successfully!");
            console.log("Data type:", typeof response.data);
            console.log("Is array:", Array.isArray(response.data));

            if (Array.isArray(response.data)) {
                setPumps(response.data);
                console.log(
                    `Successfully loaded ${response.data.length} pumps`
                );
            } else {
                console.warn("Response is not an array:", response.data);
                setPumps([]);
            }

            setError(null);
        } catch (err) {
            console.error("Error fetching pumps:", err);
            setError(`Failed to load data: ${err.message}`);
            setPumps([]);
        } finally {
            setLoading(false);
        }
    };
    // Update the fetchPedestalOptions function to better handle the response
    const fetchPedestalOptions = async () => {
        setLoadingPedestals(true);
        try {
            const response = await apiClient.get(
                "/api/PumpStationPriceTables/PedestalPrices"
            );

            console.log("Pedestal API response:", response);

            if (Array.isArray(response.data)) {
                // Check if any items have a 'pedestal' property
                const hasPedestalProperty = response.data.some(
                    (item) => "pedestal" in item
                );
                console.log(
                    "Do items have pedestal property?",
                    hasPedestalProperty
                );

                if (hasPedestalProperty) {
                    setPedestalOptions(response.data);
                    console.log("Set pedestal options:", response.data);
                } else {
                    console.warn(
                        "Response items don't have pedestal property:",
                        response.data
                    );
                    setPedestalOptions([]);
                }
            } else {
                console.warn(
                    "Pedestal response is not an array:",
                    response.data
                );
                setPedestalOptions([]);
            }
        } catch (err) {
            console.error("Error fetching pedestals:", err);
            setPedestalOptions([]);
        } finally {
            setLoadingPedestals(false);
        }
    };

    const handleOpenDialog = (pump = null) => {
        // Fetch pedestal options whenever the dialog opens
        fetchPedestalOptions();

        if (pump) {
            setFormData({
                id: pump.id || pump.Id || 0,
                model: pump.model || pump.Model || "",
                supplierModel: pump.supplierModel || pump.SupplierModel || "",
                useType: pump.useType || pump.UseType || "",
                impellerType: pump.impellerType || pump.ImpellerType || "",
                description: pump.description || pump.Description || "",
                pedestal: pump.pedestal || pump.Pedestal || "",
                discharge: pump.discharge || pump.Discharge || 0,
                voltage: pump.voltage || pump.Voltage || 0,
                kw: pump.kw || pump.Kw || 0,
                amps: pump.amps || pump.Amps || 0,
                costPrice: pump.costPrice || pump.CostPrice || 0,
                sellPrice: pump.sellPrice || pump.SellPrice || 0,
            });
            setSelectedPump(pump);
        } else {
            setFormData({
                id: 0,
                model: "",
                supplierModel: "",
                useType: "",
                impellerType: "",
                description: "",
                pedestal: "",
                discharge: 0,
                voltage: 0,
                kw: 0,
                amps: 0,
                costPrice: 0,
                sellPrice: 0,
            });
            setSelectedPump(null);
        }
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDeleteDialog = (pump) => {
        setSelectedPump(pump);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedPump(null);
    };

    // Handler for opening performance dialog
    const handleOpenPerformanceDialog = (pumpId) => {
        setPerformancePumpId(pumpId);
        setOpenPerformanceDialog(true);
    };

    // Handler for closing performance dialog
    const handleClosePerformanceDialog = () => {
        setOpenPerformanceDialog(false);
        setPerformancePumpId(null);
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        // Check if the field is a number field
        const numericFields = [
            "discharge",
            "voltage",
            "kw",
            "amps",
            "costPrice",
            "sellPrice",
        ];

        setFormData({
            ...formData,
            [name]: numericFields.includes(name)
                ? parseFloat(value) || 0
                : value,
        });
    };

    const handleSubmit = async () => {
        try {
            // Convert camelCase to PascalCase to match C# model property names
            const payload = {
                Id: formData.id,
                Model: formData.model,
                SupplierModel: formData.supplierModel,
                UseType: formData.useType,
                ImpellerType: formData.impellerType,
                Description: formData.description,
                Pedestal: formData.pedestal,
                Discharge: formData.discharge,
                Voltage: formData.voltage,
                Kw: formData.kw,
                Amps: formData.amps,
                CostPrice: formData.costPrice,
                SellPrice: formData.sellPrice,
            };

            let response;
            if (selectedPump) {
                // Update existing record
                response = await apiClient.put(
                    `/api/PumpStationPriceTables/PumpPrices/${payload.Id}`,
                    payload
                );
                setSnackbar({
                    open: true,
                    message: "Pump updated successfully!",
                    severity: "success",
                });
            } else {
                // Create new record
                response = await apiClient.post(
                    "/api/PumpStationPriceTables/PumpPrices",
                    payload
                );
                setSnackbar({
                    open: true,
                    message: "Pump created successfully!",
                    severity: "success",
                });

                // If it's a new pump and we want to immediately add performance data
                if (response.data && response.data.id) {
                    handleOpenPerformanceDialog(response.data.id);
                }
            }

            handleCloseDialog();
            fetchPumps();
        } catch (err) {
            console.error("Error saving pump:", err);
            setSnackbar({
                open: true,
                message: `Error: ${
                    err.response?.data?.message ||
                    "Failed to save. Please try again."
                }`,
                severity: "error",
            });
        }
    };

    const handleDelete = async () => {
        try {
            await apiClient.delete(
                `/api/PumpStationPriceTables/PumpPrices/${
                    selectedPump.id || selectedPump.Id
                }`
            );
            setSnackbar({
                open: true,
                message: "Pump deleted successfully!",
                severity: "success",
            });
            handleCloseDeleteDialog();
            fetchPumps();
        } catch (err) {
            console.error("Error deleting pump:", err);
            setSnackbar({
                open: true,
                message: `Error: ${
                    err.response?.data?.message ||
                    "Failed to delete. Please try again."
                }`,
                severity: "error",
            });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    // Sorting functionality
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const getSortedData = (data) => {
        if (!data.length) return [];

        return [...data].sort((a, b) => {
            // Handle the ID/modelNo column specially
            if (orderBy === "modelNo") {
                const idA = parseInt(a.id || a.Id || 0);
                const idB = parseInt(b.id || b.Id || 0);
                return order === "asc" ? idA - idB : idB - idA;
            }

            // For any other column, get the property with both camelCase and PascalCase checks
            let valueA, valueB;

            // Define all sortable properties using lowercase property names
            const propertyMap = {
                model: ["model", "Model"],
                supplierModel: ["supplierModel", "SupplierModel"],
                useType: ["useType", "UseType"],
                impellerType: ["impellerType", "ImpellerType"],
                pedestal: ["pedestal", "Pedestal"],
                discharge: ["discharge", "Discharge"],
                voltage: ["voltage", "Voltage"],
                kw: ["kw", "Kw"],
                amps: ["amps", "Amps"],
                costPrice: ["costPrice", "CostPrice"],
                sellPrice: ["sellPrice", "SellPrice"],
            };

            // Get property mapping if available
            const propertyPaths = propertyMap[orderBy];
            if (propertyPaths) {
                // Try camelCase first, then PascalCase if not found
                valueA =
                    a[propertyPaths[0]] !== undefined
                        ? a[propertyPaths[0]]
                        : a[propertyPaths[1]];
                valueB =
                    b[propertyPaths[0]] !== undefined
                        ? b[propertyPaths[0]]
                        : b[propertyPaths[1]];

                // Handle numeric fields
                const numericProperties = [
                    "discharge",
                    "voltage",
                    "kw",
                    "amps",
                    "costPrice",
                    "sellPrice",
                ];
                if (numericProperties.includes(orderBy)) {
                    valueA = parseFloat(valueA) || 0;
                    valueB = parseFloat(valueB) || 0;
                    return order === "asc" ? valueA - valueB : valueB - valueA;
                }
            }

            // For non-numeric values, convert to strings and compare
            if (typeof valueA !== "number") {
                valueA = String(valueA || "").toLowerCase();
                valueB = String(valueB || "").toLowerCase();
                return order === "asc"
                    ? valueA.localeCompare(valueB)
                    : valueB.localeCompare(valueA);
            }

            // For numeric values
            return order === "asc" ? valueA - valueB : valueB - valueA;
        });
    };

    // Filtering functionality
    const handleFilterChange = (event) => {
        setFilterText(event.target.value);
    };

    const handleFilterFieldChange = (field) => {
        setFilterField(field);
    };

    const clearFilter = () => {
        setFilterText("");
        setFilterField("all");
    };

    const applyFilters = () => {
        if (!filterText.trim()) {
            setFilteredPumps(pumps);
            return;
        }

        const searchTerm = filterText.toLowerCase().trim();

        const filtered = pumps.filter((pump) => {
            // Format model number for filtering (PM-001, PM-002, etc.)
            const modelNo = `PM-${(pump.id || pump.Id || 0)
                .toString()
                .padStart(3, "0")}`;

            // Handle both camelCase and PascalCase property names from API
            const model = pump.model || pump.Model || "";
            const supplierModel =
                pump.supplierModel || pump.SupplierModel || "";
            const useType = pump.useType || pump.UseType || "";
            const impellerType = pump.impellerType || pump.ImpellerType || "";
            const pedestal = pump.pedestal || pump.Pedestal || "";
            const discharge = pump.discharge || pump.Discharge || 0;
            const voltage = pump.voltage || pump.Voltage || 0;
            const kw = pump.kw || pump.Kw || 0;
            const amps = pump.amps || pump.Amps || 0;
            const costPrice = pump.costPrice || pump.CostPrice || 0;
            const sellPrice = pump.sellPrice || pump.SellPrice || 0;

            if (filterField === "all") {
                return (
                    modelNo.toLowerCase().includes(searchTerm) ||
                    String(pump.id || pump.Id || "").includes(searchTerm) ||
                    model.toLowerCase().includes(searchTerm) ||
                    supplierModel.toLowerCase().includes(searchTerm) ||
                    useType.toLowerCase().includes(searchTerm) ||
                    impellerType.toLowerCase().includes(searchTerm) ||
                    pedestal.toLowerCase().includes(searchTerm) ||
                    String(discharge).includes(searchTerm) ||
                    String(voltage).includes(searchTerm) ||
                    String(kw).includes(searchTerm) ||
                    String(amps).includes(searchTerm) ||
                    String(costPrice).includes(searchTerm) ||
                    String(sellPrice).includes(searchTerm)
                );
            }

            // Define a map of filter fields to their property values
            const filterMap = {
                modelNo: () => modelNo.toLowerCase().includes(searchTerm),
                id: () => String(pump.id || pump.Id || "").includes(searchTerm),
                model: () => model.toLowerCase().includes(searchTerm),
                supplierModel: () =>
                    supplierModel.toLowerCase().includes(searchTerm),
                useType: () => useType.toLowerCase().includes(searchTerm),
                impellerType: () =>
                    impellerType.toLowerCase().includes(searchTerm),
                pedestal: () => pedestal.toLowerCase().includes(searchTerm),
                discharge: () => String(discharge).includes(searchTerm),
                voltage: () => String(voltage).includes(searchTerm),
                kw: () => String(kw).includes(searchTerm),
                amps: () => String(amps).includes(searchTerm),
                costPrice: () => String(costPrice).includes(searchTerm),
                sellPrice: () => String(sellPrice).includes(searchTerm),
            };

            // Return result from the appropriate filter function if it exists
            return filterMap[filterField] ? filterMap[filterField]() : false;
        });

        setFilteredPumps(filtered);
    };

    // Prepare data for rendering
    const displayData = getSortedData(filteredPumps);

    return (
        <Box
            sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                padding: 4,
                paddingTop: 2,
                boxSizing: "border-box",
                overflow: "hidden",
            }}
        >
            <Typography variant="h4" gutterBottom align="center">
                Pump Price Table
            </Typography>

            <Paper
                sx={{
                    width: "100%",
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    mb: 2,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: 2,
                        alignItems: "center",
                    }}
                >
                    {/* Filter */}
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            width: "60%",
                        }}
                    >
                        <TextField
                            variant="outlined"
                            placeholder="Search..."
                            size="small"
                            fullWidth
                            value={filterText}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: filterText ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={clearFilter}
                                            edge="end"
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ) : null,
                            }}
                        />
                        <Tooltip title="Filter by field">
                            <Box>
                                <IconButton
                                    color={
                                        filterField !== "all"
                                            ? "primary"
                                            : "default"
                                    }
                                    aria-label="Filter options"
                                    onClick={(e) => {
                                        e.currentTarget.nextElementSibling.style.display =
                                            e.currentTarget.nextElementSibling
                                                .style.display === "none"
                                                ? "flex"
                                                : "none";
                                    }}
                                >
                                    <FilterIcon />
                                </IconButton>
                                <Box
                                    sx={{
                                        position: "absolute",
                                        zIndex: 10,
                                        bgcolor: "background.paper",
                                        boxShadow: 3,
                                        borderRadius: 1,
                                        p: 1,
                                        display: "none",
                                        flexDirection: "column",
                                        gap: 0.5,
                                    }}
                                >
                                    {[
                                        { id: "all", label: "All Fields" },
                                        { id: "modelNo", label: "ID" },
                                        { id: "model", label: "Model" },
                                        {
                                            id: "supplierModel",
                                            label: "Supplier Model",
                                        },
                                        { id: "useType", label: "Use Type" },
                                        {
                                            id: "impellerType",
                                            label: "Impeller Type",
                                        },
                                        { id: "pedestal", label: "Pedestal" },
                                        { id: "discharge", label: "Discharge" },
                                        { id: "voltage", label: "Voltage" },
                                        { id: "kw", label: "KW" },
                                        { id: "amps", label: "Amps" },
                                        {
                                            id: "costPrice",
                                            label: "Cost Price",
                                        },
                                        {
                                            id: "sellPrice",
                                            label: "Sell Price",
                                        },
                                    ].map((option) => (
                                        <Button
                                            key={option.id}
                                            size="small"
                                            variant={
                                                filterField === option.id
                                                    ? "contained"
                                                    : "text"
                                            }
                                            onClick={() => {
                                                handleFilterFieldChange(
                                                    option.id
                                                );
                                                // Hide the filter dropdown
                                                document.querySelector(
                                                    '[aria-label="Filter options"]'
                                                ).nextElementSibling.style.display =
                                                    "none";
                                            }}
                                        >
                                            {option.label}
                                        </Button>
                                    ))}
                                </Box>
                            </Box>
                        </Tooltip>
                        {filterField !== "all" && (
                            <Typography variant="caption" sx={{ ml: 1 }}>
                                Filtering by:{" "}
                                {filterField === "modelNo"
                                    ? "ID"
                                    : filterField === "id"
                                    ? "ID"
                                    : filterField === "model"
                                    ? "Model"
                                    : filterField === "supplierModel"
                                    ? "Supplier Model"
                                    : filterField === "useType"
                                    ? "Use Type"
                                    : filterField === "impellerType"
                                    ? "Impeller Type"
                                    : filterField === "pedestal"
                                    ? "Pedestal"
                                    : filterField === "discharge"
                                    ? "Discharge"
                                    : filterField === "voltage"
                                    ? "Voltage"
                                    : filterField === "kw"
                                    ? "KW"
                                    : filterField === "amps"
                                    ? "Amps"
                                    : filterField === "costPrice"
                                    ? "Cost Price"
                                    : filterField === "sellPrice"
                                    ? "Sell Price"
                                    : ""}
                            </Typography>
                        )}
                    </Box>

                    {/* Add button */}
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => handleOpenDialog()}
                    >
                        Add New Pump
                    </Button>
                </Box>

                <Divider />

                {loading ? (
                    <Box
                        sx={{ display: "flex", justifyContent: "center", p: 3 }}
                    >
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Box
                        sx={{ display: "flex", justifyContent: "center", p: 3 }}
                    >
                        <Typography color="error">{error}</Typography>
                    </Box>
                ) : (
                    <>
                        <TableContainer
                            sx={{
                                flex: 1,
                                overflow: "auto",
                            }}
                        >
                            <Table stickyHeader aria-label="pump price table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === "modelNo"}
                                                direction={
                                                    orderBy === "modelNo"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort("modelNo")
                                                }
                                            >
                                                <strong>ID</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === "model"}
                                                direction={
                                                    orderBy === "model"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort("model")
                                                }
                                            >
                                                <strong>Model</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={
                                                    orderBy === "supplierModel"
                                                }
                                                direction={
                                                    orderBy === "supplierModel"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort(
                                                        "supplierModel"
                                                    )
                                                }
                                            >
                                                <strong>Supplier Model</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === "useType"}
                                                direction={
                                                    orderBy === "useType"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort("useType")
                                                }
                                            >
                                                <strong>Use Type</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={
                                                    orderBy === "impellerType"
                                                }
                                                direction={
                                                    orderBy === "impellerType"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort(
                                                        "impellerType"
                                                    )
                                                }
                                            >
                                                <strong>Impeller Type</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={
                                                    orderBy === "description"
                                                }
                                                direction={
                                                    orderBy === "description"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort(
                                                        "description"
                                                    )
                                                }
                                            >
                                                <strong>Description</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === "pedestal"}
                                                direction={
                                                    orderBy === "pedestal"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort(
                                                        "pedestal"
                                                    )
                                                }
                                            >
                                                <strong>Pedestal</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === "discharge"}
                                                direction={
                                                    orderBy === "discharge"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort(
                                                        "discharge"
                                                    )
                                                }
                                            >
                                                <strong>Discharge</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === "voltage"}
                                                direction={
                                                    orderBy === "voltage"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort("voltage")
                                                }
                                            >
                                                <strong>Voltage</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === "kw"}
                                                direction={
                                                    orderBy === "kw"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort("kw")
                                                }
                                            >
                                                <strong>KW</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === "amps"}
                                                direction={
                                                    orderBy === "amps"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort("amps")
                                                }
                                            >
                                                <strong>Amps</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === "costPrice"}
                                                direction={
                                                    orderBy === "costPrice"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort(
                                                        "costPrice"
                                                    )
                                                }
                                            >
                                                <strong>Cost Price</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === "sellPrice"}
                                                direction={
                                                    orderBy === "sellPrice"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort(
                                                        "sellPrice"
                                                    )
                                                }
                                            >
                                                <strong>Sell Price</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="center">
                                            <strong>Actions</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {displayData.length === 0 ? (
                                        <TableRow>
                                            <TableCell
                                                colSpan={15}
                                                align="center"
                                            >
                                                {filterText
                                                    ? "No matches found. Try a different search."
                                                    : "No data available. Add your first pump."}
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        displayData.map((pump) => (
                                            <TableRow
                                                key={pump.id || pump.Id}
                                                hover
                                            >
                                                <TableCell>
                                                    PM-
                                                    {(pump.id || pump.Id)
                                                        .toString()
                                                        .padStart(3, "0")}
                                                </TableCell>
                                                <TableCell>
                                                    {pump.model ||
                                                        pump.Model ||
                                                        ""}
                                                </TableCell>
                                                <TableCell>
                                                    {pump.supplierModel ||
                                                        pump.SupplierModel ||
                                                        ""}
                                                </TableCell>
                                                <TableCell>
                                                    {pump.useType ||
                                                        pump.UseType ||
                                                        ""}
                                                </TableCell>
                                                <TableCell>
                                                    {pump.impellerType ||
                                                        pump.ImpellerType ||
                                                        ""}
                                                </TableCell>
                                                <TableCell>
                                                    {pump.description ||
                                                        pump.Description ||
                                                        ""}
                                                </TableCell>
                                                <TableCell>
                                                    {pump.pedestal ||
                                                        pump.Pedestal ||
                                                        ""}
                                                </TableCell>
                                                <TableCell>
                                                    {pump.discharge ||
                                                        pump.Discharge ||
                                                        0}
                                                </TableCell>
                                                <TableCell>
                                                    {pump.voltage ||
                                                        pump.Voltage ||
                                                        0}
                                                </TableCell>
                                                <TableCell>
                                                    {pump.kw || pump.Kw || 0}
                                                </TableCell>
                                                <TableCell>
                                                    {pump.amps ||
                                                        pump.Amps ||
                                                        0}
                                                </TableCell>
                                                <TableCell>
                                                    $
                                                    {typeof (
                                                        pump.costPrice ||
                                                        pump.CostPrice
                                                    ) === "number"
                                                        ? (
                                                              pump.costPrice ||
                                                              pump.CostPrice
                                                          ).toFixed(2)
                                                        : "0.00"}
                                                </TableCell>
                                                <TableCell>
                                                    $
                                                    {typeof (
                                                        pump.sellPrice ||
                                                        pump.SellPrice
                                                    ) === "number"
                                                        ? (
                                                              pump.sellPrice ||
                                                              pump.SellPrice
                                                          ).toFixed(2)
                                                        : "0.00"}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() =>
                                                            handleOpenDialog(
                                                                pump
                                                            )
                                                        }
                                                        aria-label="edit"
                                                        size="small"
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="secondary"
                                                        onClick={() =>
                                                            handleOpenPerformanceDialog(
                                                                pump.id ||
                                                                    pump.Id
                                                            )
                                                        }
                                                        aria-label="edit performance"
                                                        size="small"
                                                        title="Edit Performance Data"
                                                    >
                                                        <ShowChartIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="secondary"
                                                        onClick={() =>
                                                            handleOpenDocumentDialog(
                                                                pump
                                                            )
                                                        }
                                                        aria-label="documents"
                                                        title="Manage Documents"
                                                        size="small"
                                                    >
                                                        <DescriptionIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="error"
                                                        onClick={() =>
                                                            handleOpenDeleteDialog(
                                                                pump
                                                            )
                                                        }
                                                        aria-label="delete"
                                                        size="small"
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box
                            sx={{
                                p: 2,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Typography variant="body2" color="text.secondary">
                                {filterText
                                    ? `Showing ${displayData.length} of ${pumps.length} items`
                                    : `Total: ${pumps.length} items`}
                            </Typography>
                            {filterText && (
                                <Button
                                    size="small"
                                    startIcon={<ClearIcon />}
                                    onClick={clearFilter}
                                >
                                    Clear Filter
                                </Button>
                            )}
                        </Box>
                    </>
                )}
            </Paper>

            <Box sx={{ mt: 2, textAlign: "center", flexShrink: 0 }}>
                <Button
                    variant="contained"
                    component={Link}
                    to="/pumpstation-price-tables"
                    sx={{ mt: 2 }}
                >
                    Back to Pump Station Tables
                </Button>
            </Box>

            {/* Create/Edit Dialog */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    {selectedPump ? "Edit Pump" : "Add New Pump"}
                </DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Model"
                                    name="model"
                                    value={formData.model}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Supplier Model"
                                    name="supplierModel"
                                    value={formData.supplierModel}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    helperText="e.g., UMDZ1500/80/1"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Use Type"
                                    name="useType"
                                    value={formData.useType}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    helperText="e.g., Stormwater, Sewer"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Impeller Type"
                                    name="impellerType"
                                    value={formData.impellerType}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    helperText="e.g., Enclosed Channel"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Pedestal"
                                    name="pedestal"
                                    value={formData.pedestal}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    disabled={loadingPedestals}
                                    helperText={
                                        loadingPedestals
                                            ? "Loading pedestals..."
                                            : "Select a pedestal"
                                    }
                                >
                                    {/* Add an empty option */}
                                    <MenuItem value="">None</MenuItem>

                                    {/* Map through pedestal options using the pedestal attribute */}
                                    {pedestalOptions.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.pedestal}
                                        >
                                            {option.pedestal}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Discharge"
                                    name="discharge"
                                    type="number"
                                    inputProps={{ step: "0.1", min: "0" }}
                                    value={formData.discharge}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    label="Voltage"
                                    name="voltage"
                                    type="number"
                                    inputProps={{ step: "0.1", min: "0" }}
                                    value={formData.voltage}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    label="KW"
                                    name="kw"
                                    type="number"
                                    inputProps={{ step: "0.1", min: "0" }}
                                    value={formData.kw}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    label="Amps"
                                    name="amps"
                                    type="number"
                                    inputProps={{ step: "0.1", min: "0" }}
                                    value={formData.amps}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Cost Price"
                                    name="costPrice"
                                    type="number"
                                    inputProps={{ step: "0.01", min: "0" }}
                                    value={formData.costPrice}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Sell Price"
                                    name="sellPrice"
                                    type="number"
                                    inputProps={{ step: "0.01", min: "0" }}
                                    value={formData.sellPrice}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                    >
                        {selectedPump ? "Update" : "Save"}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this pump? This action
                        cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
                    <Button
                        onClick={handleDelete}
                        color="error"
                        variant="contained"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>

            {/* Performance Data Dialog */}
            <Dialog
                open={openPerformanceDialog}
                onClose={handleClosePerformanceDialog}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>
                    Pump Performance Data
                    {performancePumpId
                        ? ` - PM-${performancePumpId
                              .toString()
                              .padStart(3, "0")}`
                        : ""}
                </DialogTitle>
                <DialogContent>
                    {performancePumpId && (
                        <PumpPerformanceEditor
                            pumpId={performancePumpId}
                            onClose={handleClosePerformanceDialog}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePerformanceDialog}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Document Dialog */}
            <Dialog
                open={documentDialogOpen}
                onClose={() => setDocumentDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    Document Management
                    {selectedPump
                        ? ` - PM-${
                              selectedPump.id?.toString().padStart(3, "0") ||
                              selectedPump.Id?.toString().padStart(3, "0")
                          }`
                        : ""}
                </DialogTitle>
                <DialogContent>
                    {selectedPump && (
                        <DocumentAssociation
                            entityType="PumpPrice"
                            entityId={selectedPump.id || selectedPump.Id}
                            entityName={`Pump ${
                                selectedPump.model || selectedPump.Model || ""
                            }`}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDocumentDialogOpen(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default PumpPriceTable;
