import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material"; // Changed to Material-UI Button
import { Box } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

const BackToDashboardButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/dashboard");
    };

    return (
        <Box sx={{ mb: 3, mt: 2, display: "flex", justifyContent: "center" }}>
            <Button
                variant="contained"
                color="primary"
                startIcon={<ArrowBack />}
                onClick={() => navigate("/dashboard")}
            >
                Back to Dashboard
            </Button>
        </Box>
    );
};

export default BackToDashboardButton;
