import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Box,
    Grid,
    Snackbar,
    Alert,
    CircularProgress,
    InputAdornment,
    Tooltip,
    Divider,
} from "@mui/material";
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Search as SearchIcon,
    FilterAlt as FilterIcon,
    Clear as ClearIcon,
} from "@mui/icons-material";
import apiClient from "../../Helpers/apiClient";

function GuideRailPriceTable() {
    const [guideRails, setGuideRails] = useState([]);
    const [filteredGuideRails, setFilteredGuideRails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedGuideRail, setSelectedGuideRail] = useState(null);
    const [formData, setFormData] = useState({
        id: 0,
        guideRailNB: "",
        guideRailType: "",
        price: 0,
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });

    // Sorting states
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("id");
    // Filtering states
    const [filterText, setFilterText] = useState("");
    const [filterField, setFilterField] = useState("all");

    useEffect(() => {
        fetchGuideRails();
    }, []);

    // Apply filters whenever guide rails or filter settings change
    useEffect(() => {
        applyFilters();
    }, [guideRails, filterText, filterField]);

    const fetchGuideRails = async () => {
        setLoading(true);
        try {
            console.log("Fetching guide rails...");

            const response = await apiClient.get(
                "/api/PumpStationPriceTables/GuideRailPrices"
            );

            console.log("Response received successfully!");
            console.log("Data type:", typeof response.data);
            console.log("Is array:", Array.isArray(response.data));

            if (Array.isArray(response.data)) {
                setGuideRails(response.data);
                console.log(
                    `Successfully loaded ${response.data.length} guide rails`
                );
            } else {
                console.warn("Response is not an array:", response.data);
                setGuideRails([]);
            }

            setError(null);
        } catch (err) {
            console.error("Error fetching guide rails:", err);
            setError(`Failed to load data: ${err.message}`);
            setGuideRails([]);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenDialog = (guideRail = null) => {
        if (guideRail) {
            setFormData({
                id: guideRail.id || guideRail.Id || 0,
                guideRailNB:
                    guideRail.guideRailNB || guideRail.GuideRailNB || "",
                guideRailType:
                    guideRail.guideRailType || guideRail.GuideRailType || "",
                price: guideRail.price || guideRail.Price || 0,
            });
            setSelectedGuideRail(guideRail);
        } else {
            setFormData({
                id: 0,
                guideRailNB: "",
                guideRailType: "",
                price: 0,
            });
            setSelectedGuideRail(null);
        }
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDeleteDialog = (guideRail) => {
        setSelectedGuideRail(guideRail);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedGuideRail(null);
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        // Check if the field is a number field
        const numericFields = ["price"];

        setFormData({
            ...formData,
            [name]: numericFields.includes(name)
                ? parseFloat(value) || 0
                : value,
        });
    };

    const handleSubmit = async () => {
        try {
            // Convert camelCase to PascalCase to match C# model property names
            const payload = {
                Id: formData.id,
                GuideRailNB: formData.guideRailNB,
                GuideRailType: formData.guideRailType,
                Price: formData.price,
            };

            let response;
            if (selectedGuideRail) {
                // Update existing record
                response = await apiClient.put(
                    `/api/PumpStationPriceTables/GuideRailPrices/${payload.Id}`,
                    payload
                );
                setSnackbar({
                    open: true,
                    message: "Guide Rail price updated successfully!",
                    severity: "success",
                });
            } else {
                // Create new record
                response = await apiClient.post(
                    "/api/PumpStationPriceTables/GuideRailPrices",
                    payload
                );
                setSnackbar({
                    open: true,
                    message: "Guide Rail price created successfully!",
                    severity: "success",
                });
            }

            handleCloseDialog();
            fetchGuideRails();
        } catch (err) {
            console.error("Error saving guide rail price:", err);
            setSnackbar({
                open: true,
                message: `Error: ${
                    err.response?.data?.message ||
                    "Failed to save. Please try again."
                }`,
                severity: "error",
            });
        }
    };

    const handleDelete = async () => {
        try {
            await apiClient.delete(
                `/api/PumpStationPriceTables/GuideRailPrices/${
                    selectedGuideRail.id || selectedGuideRail.Id
                }`
            );
            setSnackbar({
                open: true,
                message: "Guide Rail price deleted successfully!",
                severity: "success",
            });
            handleCloseDeleteDialog();
            fetchGuideRails();
        } catch (err) {
            console.error("Error deleting guide rail price:", err);
            setSnackbar({
                open: true,
                message: `Error: ${
                    err.response?.data?.message ||
                    "Failed to delete. Please try again."
                }`,
                severity: "error",
            });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    // Sorting functionality
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const getSortedData = (data) => {
        if (!data.length) return [];

        return [...data].sort((a, b) => {
            // Handle the ID column specially
            if (orderBy === "id") {
                const idA = parseInt(a.id || a.Id || 0);
                const idB = parseInt(b.id || b.Id || 0);
                return order === "asc" ? idA - idB : idB - idA;
            }

            // For any other column, get the property with both camelCase and PascalCase checks
            let valueA, valueB;

            // Define all sortable properties using lowercase property names
            const propertyMap = {
                guideRailNB: ["guideRailNB", "GuideRailNB"],
                guideRailType: ["guideRailType", "GuideRailType"],
                price: ["price", "Price"],
            };

            // Get property mapping if available
            const propertyPaths = propertyMap[orderBy];
            if (propertyPaths) {
                // Try camelCase first, then PascalCase if not found
                valueA =
                    a[propertyPaths[0]] !== undefined
                        ? a[propertyPaths[0]]
                        : a[propertyPaths[1]];
                valueB =
                    b[propertyPaths[0]] !== undefined
                        ? b[propertyPaths[0]]
                        : b[propertyPaths[1]];

                // Handle numeric fields
                const numericProperties = ["price"];
                if (numericProperties.includes(orderBy)) {
                    valueA = parseFloat(valueA) || 0;
                    valueB = parseFloat(valueB) || 0;
                    return order === "asc" ? valueA - valueB : valueB - valueA;
                }
            }

            // For non-numeric values, convert to strings and compare
            if (typeof valueA !== "number") {
                valueA = String(valueA || "").toLowerCase();
                valueB = String(valueB || "").toLowerCase();
                return order === "asc"
                    ? valueA.localeCompare(valueB)
                    : valueB.localeCompare(valueA);
            }

            // For numeric values
            return order === "asc" ? valueA - valueB : valueB - valueA;
        });
    };

    // Filtering functionality
    const handleFilterChange = (event) => {
        setFilterText(event.target.value);
    };

    const handleFilterFieldChange = (field) => {
        setFilterField(field);
    };

    const clearFilter = () => {
        setFilterText("");
        setFilterField("all");
    };

    const applyFilters = () => {
        if (!filterText.trim()) {
            setFilteredGuideRails(guideRails);
            return;
        }

        const searchTerm = filterText.toLowerCase().trim();

        const filtered = guideRails.filter((guideRail) => {
            // Format ID for filtering (GR-001, GR-002, etc.)
            const formattedId = `GR-${(guideRail.id || guideRail.Id || 0)
                .toString()
                .padStart(3, "0")}`;

            // Handle both camelCase and PascalCase property names from API
            const guideRailNB =
                guideRail.guideRailNB || guideRail.GuideRailNB || "";
            const guideRailType =
                guideRail.guideRailType || guideRail.GuideRailType || "";
            const price = guideRail.price || guideRail.Price || 0;

            if (filterField === "all") {
                return (
                    formattedId.toLowerCase().includes(searchTerm) ||
                    String(guideRail.id || guideRail.Id || "").includes(
                        searchTerm
                    ) ||
                    guideRailNB.toLowerCase().includes(searchTerm) ||
                    guideRailType.toLowerCase().includes(searchTerm) ||
                    String(price).includes(searchTerm)
                );
            }

            // Define a map of filter fields to their property values
            const filterMap = {
                id: () =>
                    formattedId.toLowerCase().includes(searchTerm) ||
                    String(guideRail.id || guideRail.Id || "").includes(
                        searchTerm
                    ),
                guideRailNB: () =>
                    guideRailNB.toLowerCase().includes(searchTerm),
                guideRailType: () =>
                    guideRailType.toLowerCase().includes(searchTerm),
                price: () => String(price).includes(searchTerm),
            };

            // Return result from the appropriate filter function if it exists
            return filterMap[filterField] ? filterMap[filterField]() : false;
        });

        setFilteredGuideRails(filtered);
    };

    // Prepare data for rendering
    const displayData = getSortedData(filteredGuideRails);

    return (
        <Box
            sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                padding: 4,
                paddingTop: 2,
                boxSizing: "border-box",
                overflow: "hidden",
            }}
        >
            <Typography variant="h4" gutterBottom align="center">
                Guide Rail Price Table
            </Typography>

            <Paper
                sx={{
                    width: "100%",
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    mb: 2,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: 2,
                        alignItems: "center",
                    }}
                >
                    {/* Filter */}
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            width: "60%",
                        }}
                    >
                        <TextField
                            variant="outlined"
                            placeholder="Search..."
                            size="small"
                            fullWidth
                            value={filterText}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: filterText ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={clearFilter}
                                            edge="end"
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ) : null,
                            }}
                        />
                        <Tooltip title="Filter by field">
                            <Box>
                                <IconButton
                                    color={
                                        filterField !== "all"
                                            ? "primary"
                                            : "default"
                                    }
                                    aria-label="Filter options"
                                    onClick={(e) => {
                                        e.currentTarget.nextElementSibling.style.display =
                                            e.currentTarget.nextElementSibling
                                                .style.display === "none"
                                                ? "flex"
                                                : "none";
                                    }}
                                >
                                    <FilterIcon />
                                </IconButton>
                                <Box
                                    sx={{
                                        position: "absolute",
                                        zIndex: 10,
                                        bgcolor: "background.paper",
                                        boxShadow: 3,
                                        borderRadius: 1,
                                        p: 1,
                                        display: "none",
                                        flexDirection: "column",
                                        gap: 0.5,
                                    }}
                                >
                                    {[
                                        { id: "all", label: "All Fields" },
                                        { id: "id", label: "ID" },
                                        {
                                            id: "guideRailNB",
                                            label: "Guide Rail NB",
                                        },
                                        {
                                            id: "guideRailType",
                                            label: "Guide Rail Type",
                                        },
                                        { id: "price", label: "Price" },
                                    ].map((option) => (
                                        <Button
                                            key={option.id}
                                            size="small"
                                            variant={
                                                filterField === option.id
                                                    ? "contained"
                                                    : "text"
                                            }
                                            onClick={() => {
                                                handleFilterFieldChange(
                                                    option.id
                                                );
                                                // Hide the filter dropdown
                                                document.querySelector(
                                                    '[aria-label="Filter options"]'
                                                ).nextElementSibling.style.display =
                                                    "none";
                                            }}
                                        >
                                            {option.label}
                                        </Button>
                                    ))}
                                </Box>
                            </Box>
                        </Tooltip>
                        {filterField !== "all" && (
                            <Typography variant="caption" sx={{ ml: 1 }}>
                                Filtering by:{" "}
                                {filterField === "id"
                                    ? "ID"
                                    : filterField === "guideRailNB"
                                    ? "Guide Rail NB"
                                    : filterField === "guideRailType"
                                    ? "Guide Rail Type"
                                    : filterField === "price"
                                    ? "Price"
                                    : ""}
                            </Typography>
                        )}
                    </Box>

                    {/* Add button */}
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => handleOpenDialog()}
                    >
                        Add New Guide Rail
                    </Button>
                </Box>

                <Divider />

                {loading ? (
                    <Box
                        sx={{ display: "flex", justifyContent: "center", p: 3 }}
                    >
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Box
                        sx={{ display: "flex", justifyContent: "center", p: 3 }}
                    >
                        <Typography color="error">{error}</Typography>
                    </Box>
                ) : (
                    <>
                        <TableContainer
                            sx={{
                                flex: 1,
                                overflow: "auto",
                            }}
                        >
                            <Table
                                stickyHeader
                                aria-label="guide rail price table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === "id"}
                                                direction={
                                                    orderBy === "id"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort("id")
                                                }
                                            >
                                                <strong>ID</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={
                                                    orderBy === "guideRailNB"
                                                }
                                                direction={
                                                    orderBy === "guideRailNB"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort(
                                                        "guideRailNB"
                                                    )
                                                }
                                            >
                                                <strong>Guide Rail NB</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={
                                                    orderBy === "guideRailType"
                                                }
                                                direction={
                                                    orderBy === "guideRailType"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort(
                                                        "guideRailType"
                                                    )
                                                }
                                            >
                                                <strong>Guide Rail Type</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === "price"}
                                                direction={
                                                    orderBy === "price"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort("price")
                                                }
                                            >
                                                <strong>Price</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="center">
                                            <strong>Actions</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {displayData.length === 0 ? (
                                        <TableRow>
                                            <TableCell
                                                colSpan={5}
                                                align="center"
                                            >
                                                {filterText
                                                    ? "No matches found. Try a different search."
                                                    : "No data available. Add your first guide rail."}
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        displayData.map((guideRail) => (
                                            <TableRow
                                                key={
                                                    guideRail.id || guideRail.Id
                                                }
                                                hover
                                            >
                                                <TableCell>
                                                    GR-
                                                    {(
                                                        guideRail.id ||
                                                        guideRail.Id
                                                    )
                                                        .toString()
                                                        .padStart(3, "0")}
                                                </TableCell>
                                                <TableCell>
                                                    {guideRail.guideRailNB ||
                                                        guideRail.GuideRailNB ||
                                                        ""}
                                                </TableCell>
                                                <TableCell>
                                                    {guideRail.guideRailType ||
                                                        guideRail.GuideRailType ||
                                                        ""}
                                                </TableCell>
                                                <TableCell>
                                                    $
                                                    {typeof (
                                                        guideRail.price ||
                                                        guideRail.Price
                                                    ) === "number"
                                                        ? (
                                                              guideRail.price ||
                                                              guideRail.Price
                                                          ).toFixed(2)
                                                        : "0.00"}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() =>
                                                            handleOpenDialog(
                                                                guideRail
                                                            )
                                                        }
                                                        aria-label="edit"
                                                        size="small"
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="error"
                                                        onClick={() =>
                                                            handleOpenDeleteDialog(
                                                                guideRail
                                                            )
                                                        }
                                                        aria-label="delete"
                                                        size="small"
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box
                            sx={{
                                p: 2,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Typography variant="body2" color="text.secondary">
                                {filterText
                                    ? `Showing ${displayData.length} of ${guideRails.length} items`
                                    : `Total: ${guideRails.length} items`}
                            </Typography>
                            {filterText && (
                                <Button
                                    size="small"
                                    startIcon={<ClearIcon />}
                                    onClick={clearFilter}
                                >
                                    Clear Filter
                                </Button>
                            )}
                        </Box>
                    </>
                )}
            </Paper>

            <Box sx={{ mt: 2, textAlign: "center", flexShrink: 0 }}>
                <Button
                    variant="contained"
                    component={Link}
                    to="/pumpstation-price-tables"
                    sx={{ mt: 2 }}
                >
                    Back to Pump Station Tables
                </Button>
            </Box>

            {/* Create/Edit Dialog */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {selectedGuideRail
                        ? "Edit Guide Rail"
                        : "Add New Guide Rail"}
                </DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Guide Rail NB"
                                    name="guideRailNB"
                                    value={formData.guideRailNB}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    required
                                    inputProps={{ maxLength: 10 }}
                                    helperText="Max 10 characters (e.g., 50mm, 80mm)"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Guide Rail Type"
                                    name="guideRailType"
                                    value={formData.guideRailType}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    required
                                    inputProps={{ maxLength: 50 }}
                                    helperText="e.g., SS304, Galvanized"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Price"
                                    name="price"
                                    type="number"
                                    inputProps={{
                                        step: "0.01",
                                        min: "0",
                                        max: "999999.99",
                                    }}
                                    value={formData.price}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        disabled={
                            !formData.guideRailNB || !formData.guideRailType
                        }
                    >
                        {selectedGuideRail ? "Update" : "Save"}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this guide rail price?
                        This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
                    <Button
                        onClick={handleDelete}
                        color="error"
                        variant="contained"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default GuideRailPriceTable;
