import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemSecondaryAction,
    IconButton,
    Divider,
    CircularProgress,
    Tooltip,
    Paper,
} from "@mui/material";
import {
    PictureAsPdf as PdfIcon,
    OpenInNew as OpenIcon,
    Delete as DeleteIcon,
    Add as AddIcon,
    FolderOpen as FolderOpenIcon,
} from "@mui/icons-material";
import apiClient from "../../Helpers/apiClient";
import { useNavigate } from "react-router-dom";
// Add these imports
import { Breadcrumbs, Link as MuiLink } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// This component can be embedded in other components to show associated documents
// and allow users to manage those associations
function DocumentAssociation({ entityType, entityId, entityName }) {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectDocumentDialog, setSelectDocumentDialog] = useState(false);
    const [availableDocuments, setAvailableDocuments] = useState([]);
    const [loadingAvailable, setLoadingAvailable] = useState(false);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [folderPath, setFolderPath] = useState([]);
    const [folders, setFolders] = useState([]);

    const navigate = useNavigate();
    useEffect(() => {
        if (selectDocumentDialog) {
            fetchAvailableDocuments();
        }
    }, [currentFolder, selectDocumentDialog]);
    useEffect(() => {
        if (entityType && entityId) {
            fetchAssociatedDocuments();
        }
    }, [entityType, entityId]);
    const fetchFolders = async (parentId = null) => {
        try {
            const params = parentId ? `?parentFolderId=${parentId}` : "";
            const response = await apiClient.get(
                `/api/Documents/Folders${params}`
            );
            if (Array.isArray(response.data)) {
                setFolders(response.data);
            } else {
                setFolders([]);
            }
        } catch (err) {
            console.error("Error fetching folders:", err);
            setFolders([]);
        }
    };

    const fetchAssociatedDocuments = async () => {
        setLoading(true);
        try {
            const response = await apiClient.get(
                `/api/Documents/Associations?entityType=${entityType}&entityId=${entityId}`
            );
            if (Array.isArray(response.data)) {
                setDocuments(response.data);
            } else {
                setDocuments([]);
            }
            setError(null);
        } catch (err) {
            console.error("Error fetching associated documents:", err);
            setError("Failed to load associated documents");
            setDocuments([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchAvailableDocuments = async () => {
        setLoadingAvailable(true);
        try {
            // Use folder ID if we're in a specific folder, otherwise fetch from root
            const params = currentFolder ? `?folderId=${currentFolder.id}` : "";

            console.log(`Fetching documents with params: ${params}`); // Add logging for debugging

            const response = await apiClient.get(`/api/Documents${params}`);

            if (Array.isArray(response.data)) {
                // Filter out documents that are already associated
                const associatedIds = documents.map((doc) => doc.id);
                const availableDocs = response.data.filter(
                    (doc) => !associatedIds.includes(doc.id)
                );
                setAvailableDocuments(availableDocs);
                console.log(
                    `Found ${availableDocs.length} available documents`
                ); // Add logging for debugging
            } else {
                setAvailableDocuments([]);
            }
        } catch (err) {
            console.error("Error fetching available documents:", err);
            setAvailableDocuments([]);
        } finally {
            setLoadingAvailable(false);
        }
    };
    const handleOpenSelectDialog = () => {
        // Reset folder selection
        setCurrentFolder(null);
        setFolderPath([]);
        // Fetch folders and documents
        fetchFolders();
        fetchAvailableDocuments();
        setSelectDocumentDialog(true);
    };

    // New functions for folder navigation
    const handleFolderClick = (folder) => {
        setCurrentFolder(folder);
        setFolderPath([...folderPath, folder]);
        fetchFolders(folder.id);
    };

    const handleFolderPathClick = (index) => {
        if (index === 0) {
            // Go to root
            setCurrentFolder(null);
            setFolderPath([]);
            fetchFolders(null);
            // Remove fetchAvailableDocuments() from here
        } else {
            // Go to specific folder in the path
            const targetFolder = folderPath[index];
            setCurrentFolder(targetFolder);
            setFolderPath(folderPath.slice(0, index + 1));
            fetchFolders(targetFolder.id);
            // Remove fetchAvailableDocuments() from here
        }
    };

    const handleBackClick = () => {
        if (folderPath.length > 0) {
            const newPath = [...folderPath];
            newPath.pop();

            if (newPath.length === 0) {
                // Going back to root
                setCurrentFolder(null);
                setFolderPath([]);
                fetchFolders(null);
                // Remove fetchAvailableDocuments() from here
            } else {
                // Going back to parent folder
                const parentFolder = newPath[newPath.length - 1];
                setCurrentFolder(parentFolder);
                setFolderPath(newPath);
                fetchFolders(parentFolder.id);
                // Remove fetchAvailableDocuments() from here
            }
        }
    };

    const handleCloseSelectDialog = () => {
        setSelectDocumentDialog(false);
    };

    const handleAssociateDocument = async (documentId) => {
        try {
            await apiClient.post("/api/Documents/Associate", {
                documentId: documentId,
                entityType: entityType,
                entityId: entityId,
            });

            // Close dialog and refresh documents
            handleCloseSelectDialog();
            fetchAssociatedDocuments();
        } catch (err) {
            console.error("Error associating document:", err);
        }
    };

    const handleRemoveAssociation = async (documentId) => {
        try {
            await apiClient.delete("/api/Documents/Disassociate", {
                data: {
                    documentId: documentId,
                    entityType: entityType,
                    entityId: entityId,
                },
            });

            // Refresh documents
            fetchAssociatedDocuments();
        } catch (err) {
            console.error("Error removing document association:", err);
        }
    };

    const handleViewDocument = async (documentId) => {
        try {
            const response = await apiClient.get(
                `/api/Documents/View/${documentId}`
            );
            if (response.data && response.data.url) {
                window.open(response.data.url, "_blank");
            }
        } catch (err) {
            console.error("Error viewing document:", err);
        }
    };

    const handleGotoDocumentManager = () => {
        navigate("/pdf-manager");
    };

    // Format file size for display
    const formatFileSize = (bytes) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    };

    // Format date for display
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
        });
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                }}
            >
                <Typography variant="h6">
                    {entityName
                        ? `Documents for ${entityName}`
                        : "Associated Documents"}
                </Typography>
                <Box>
                    <Button
                        variant="text" // Changed from "outlined" to "text"
                        size="small"
                        startIcon={<FolderOpenIcon />}
                        onClick={handleGotoDocumentManager}
                        sx={{
                            mr: 1,
                            color: "text.secondary", // Using a more subtle text color
                            "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.04)", // Very light hover effect
                            },
                        }}
                    >
                        Document Manager
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={handleOpenSelectDialog}
                    >
                        Add Document
                    </Button>
                </Box>
            </Box>

            <Paper elevation={2} sx={{ p: 2 }}>
                {loading ? (
                    <Box
                        sx={{ display: "flex", justifyContent: "center", p: 3 }}
                    >
                        <CircularProgress size={24} />
                    </Box>
                ) : error ? (
                    <Typography color="error" align="center">
                        {error}
                    </Typography>
                ) : documents.length === 0 ? (
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                    >
                        No documents associated with this record.
                    </Typography>
                ) : (
                    <List>
                        {documents.map((doc) => (
                            <React.Fragment key={doc.id}>
                                <ListItem>
                                    <ListItemIcon>
                                        <PdfIcon color="error" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={doc.originalFileName}
                                        secondary={
                                            <>
                                                {doc.description && (
                                                    <Typography
                                                        variant="body2"
                                                        component="span"
                                                        display="block"
                                                    >
                                                        {doc.description}
                                                    </Typography>
                                                )}
                                                <Typography
                                                    variant="caption"
                                                    component="span"
                                                    color="text.secondary"
                                                >
                                                    {formatFileSize(
                                                        doc.fileSize
                                                    )}{" "}
                                                    • Uploaded{" "}
                                                    {formatDate(doc.uploadDate)}
                                                </Typography>
                                            </>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <Tooltip title="View Document">
                                            <IconButton
                                                edge="end"
                                                aria-label="view"
                                                onClick={() =>
                                                    handleViewDocument(doc.id)
                                                }
                                                sx={{ mr: 1 }}
                                            >
                                                <OpenIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Remove Association">
                                            <IconButton
                                                edge="end"
                                                aria-label="remove"
                                                onClick={() =>
                                                    handleRemoveAssociation(
                                                        doc.id
                                                    )
                                                }
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </React.Fragment>
                        ))}
                    </List>
                )}
            </Paper>

            {/* Select Document Dialog */}
            <Dialog
                open={selectDocumentDialog}
                onClose={handleCloseSelectDialog}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Select Document to Associate</DialogTitle>
                <DialogContent>
                    {/* Add Folder Navigation UI */}
                    <Box sx={{ mb: 2 }}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                mb: 1,
                            }}
                        >
                            {folderPath.length > 0 && (
                                <IconButton
                                    onClick={handleBackClick}
                                    sx={{ mr: 1 }}
                                    size="small"
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                            )}

                            <Breadcrumbs aria-label="folder path">
                                <MuiLink
                                    component="button"
                                    variant="body1"
                                    onClick={() => handleFolderPathClick(0)}
                                    sx={{
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <FolderIcon
                                        sx={{ mr: 0.5 }}
                                        fontSize="small"
                                    />
                                    Root
                                </MuiLink>

                                {folderPath.map((folder, index) => (
                                    <MuiLink
                                        key={folder.id}
                                        component="button"
                                        variant="body1"
                                        onClick={() =>
                                            handleFolderPathClick(index + 1)
                                        }
                                        sx={{
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <FolderIcon
                                            sx={{ mr: 0.5 }}
                                            fontSize="small"
                                        />
                                        {folder.name}
                                    </MuiLink>
                                ))}
                            </Breadcrumbs>
                        </Box>

                        <Divider sx={{ mb: 2 }} />
                    </Box>

                    {loadingAvailable ? (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                p: 3,
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {/* Display Folders First */}
                            {folders.length > 0 && (
                                <Box sx={{ mb: 2 }}>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        Folders
                                    </Typography>
                                    <List sx={{ pt: 0 }}>
                                        {folders.map((folder) => (
                                            <ListItem
                                                button
                                                key={folder.id}
                                                onClick={() =>
                                                    handleFolderClick(folder)
                                                }
                                            >
                                                <ListItemIcon>
                                                    <FolderIcon color="primary" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={folder.name}
                                                    secondary={
                                                        folder.description || ""
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                    <Divider sx={{ my: 2 }} />
                                </Box>
                            )}

                            {/* Display Documents */}
                            <Typography variant="subtitle1" gutterBottom>
                                Documents
                            </Typography>
                            {availableDocuments.length === 0 ? (
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    align="center"
                                >
                                    No documents available in this folder.
                                </Typography>
                            ) : (
                                <List sx={{ pt: 0 }}>
                                    {availableDocuments.map((doc) => (
                                        <React.Fragment key={doc.id}>
                                            <ListItem
                                                button
                                                onClick={() =>
                                                    handleAssociateDocument(
                                                        doc.id
                                                    )
                                                }
                                            >
                                                <ListItemIcon>
                                                    <PdfIcon color="error" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        doc.originalFileName
                                                    }
                                                    secondary={
                                                        <>
                                                            {doc.description && (
                                                                <Typography
                                                                    variant="body2"
                                                                    component="span"
                                                                    display="block"
                                                                >
                                                                    {
                                                                        doc.description
                                                                    }
                                                                </Typography>
                                                            )}
                                                            <Typography
                                                                variant="caption"
                                                                component="span"
                                                                color="text.secondary"
                                                            >
                                                                {formatFileSize(
                                                                    doc.fileSize
                                                                )}{" "}
                                                                • Uploaded{" "}
                                                                {formatDate(
                                                                    doc.uploadDate
                                                                )}
                                                            </Typography>
                                                        </>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider
                                                variant="inset"
                                                component="li"
                                            />
                                        </React.Fragment>
                                    ))}
                                </List>
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSelectDialog}>Cancel</Button>
                    <Button
                        variant="contained"
                        onClick={handleGotoDocumentManager}
                        startIcon={<FolderOpenIcon />}
                    >
                        Document Manager
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default DocumentAssociation;
