import apiClient from "../Helpers/apiClient";

/**
 * Fetches WordPress pages with tag ID 50
 * @returns {Promise} - Promise resolving to the WordPress pages data
 */
const fetchProductPages = async () => {
    try {
        const response = await apiClient.get(
            `https://protector.com.au/wp-json/wp/v2/pages`,
            {
                params: {
                    tags: 50,
                    per_page: 100,
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error fetching product pages:", error);
        throw error;
    }
};

/**
 * Groups pages by their category IDs
 * @returns {Object} - Object with category IDs as keys and arrays of pages as values
 */
const groupPagesByCategories = async () => {
    try {
        const pages = await fetchProductPages();
        const groupedPages = {};

        // Process each page
        pages.forEach((page) => {
            // Check if the page has categories
            if (page.categories && page.categories.length > 0) {
                // For each category of the page
                page.categories.forEach((categoryId) => {
                    // Convert category ID to string for object key
                    const categoryKey = categoryId.toString();

                    // Initialize the category array if it doesn't exist
                    if (!groupedPages[categoryKey]) {
                        groupedPages[categoryKey] = [];
                    }

                    // Add the page to the category
                    groupedPages[categoryKey].push(page);
                });
            } else {
                // For pages without categories, group them under "uncategorized"
                if (!groupedPages["uncategorized"]) {
                    groupedPages["uncategorized"] = [];
                }
                groupedPages["uncategorized"].push(page);
            }
        });

        return groupedPages;
    } catch (error) {
        console.error("Failed to group pages by categories:", error);
        throw error;
    }
};

/**
 * Gets the case studies for a specific product
 * @param {number} categoryId - The category ID of the product
 * @returns {Promise} - Promise resolving to an array of case studies
 */
const getProductCaseStudies = async (categoryId) => {
    try {
        if (!categoryId) {
            return [];
        }

        const groupedPages = await groupPagesByCategories();
        const categoryKey = categoryId.toString();

        return groupedPages[categoryKey] || [];
    } catch (error) {
        console.error("Failed to get product case studies:", error);
        return [];
    }
};

export { fetchProductPages, groupPagesByCategories, getProductCaseStudies };
