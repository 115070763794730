import React from "react";
import { Link } from "react-router-dom";
import {
    Button,
    Container,
    Typography,
    Box,
    AppBar,
    Toolbar,
    Paper,
} from "@mui/material";
import NavigationBar from "./NavigationBar";
import BackToDashboardButton from "../../Components/backToDashBoardButton";

const PumpStationPriceTablesDashBoardPage = () => {
    const forms = [
        {
            path: "/access-cover-price-table",
            label: "Access Cover Price Table",
        },
        { path: "/controller-price-table", label: "Controller Price Table" },
        { path: "/freight-price-table", label: "Freight Price Table" },
        {
            path: "/level-control-price-table",
            label: "Level Control Price Table",
        },
        { path: "/mixer-price-table", label: "Mixer Price Table" },
        { path: "/pedestal-price-table", label: "Pedestal Price Table" },
        { path: "/pipe-work-price-table", label: "PipeWork Price Table" },
        { path: "/pump-price-table", label: "Pump Price Table" },
        { path: "/tank-price-table", label: "Tank Price Table" },
        { path: "/valve-pit-price-table", label: "ValvePit Price Table" },
        { path: "/guiderail-price-table", label: "Guide Rail Price Table" },
        { path: "/pdf-manager", label: "PDF Management" },
    ];

    return (
        <Box sx={{ p: 3 }}>
            <NavigationBar />
            <Typography variant="h4" align="center" gutterBottom sx={{ mt: 2 }}>
                Insert Records into Pump Station Tables
            </Typography>
            <Paper elevation={3} sx={{ p: 2, width: "60%", margin: "auto" }}>
                <Typography variant="h5" align="center">
                    Select a table to insert records into:
                </Typography>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={2}
                    padding={3}
                    paddingX={5}
                >
                    {forms.map((form) => (
                        <Button
                            key={form.path}
                            variant="contained"
                            color="primary"
                            component={Link}
                            to={form.path}
                            fullWidth
                        >
                            {form.label}
                        </Button>
                    ))}
                </Box>
            </Paper>

            <BackToDashboardButton />
        </Box>
    );
};

export default PumpStationPriceTablesDashBoardPage;
