import React, { useState, useEffect, useRef } from "react";
import DynamicPdfGenerator from "./DynamicPdfGenerator";

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Paper,
    CircularProgress,
    Snackbar,
    Alert,
    Grid,
    Button,
    Box,
    FormControlLabel, // Added
    Checkbox, // Added
} from "@mui/material";
import apiClient from "../../Helpers/apiClient";
import NavigationBar from "./NavigationBar";
import BackToDashboardButton from "../../Components/backToDashBoardButton";
import { TextField } from "@mui/material";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Scatter,
} from "recharts";
const ProductQuoteCalculator = () => {
    const renderPdfGenerator = () => {
        if (quoteBreakdown.totalPrice > 0) {
            return (
                <>
                    {renderHiddenPdfChart()}
                    <DynamicPdfGenerator
                        formData={formData}
                        quoteBreakdown={quoteBreakdown}
                        chartRef={pdfChartContainerRef}
                    />
                </>
            );
        }
        return null;
    };
    const [formData, setFormData] = useState({
        tankDiameter: "",
        tankDepth: "",
        accessSize: "",
        accessCoverType: "",
        valvePitDescription: "",
        valvePitAccessCoverSize: "", // New field
        valvePitAccessCoverType: "", // New field
        customerName: "Default Customer",
        customerEmail: "default@email.com",
        customerPhone: "0000000000",
        projectName: "Default Project",
        projectAddress: "Default Address",
        pipeWorkSize: "",
        pipeWorkMaterial: "",
        valveMaterial: "",
        notes: "Default Notes",
        pumpModel: "",
        numberOfPumps: 1,
        pumpOperation: 1, // Default Duty
        pumpSetup: "",
        mixerType: "",
        city: "",
        levelControlDescription: "",
        guideRailType: "", // New field for Guide Rail Type
        controlType: "", // New field for Control Type
        totalHead: "",
        flow: "",
        staticHead: "",
        risingMainLength: "",
        risingMainSize: "",
        risingMainType: "",
    });

    const mapValvePitDescriptionToAccessSize = (description) => {
        const mapping = {
            "600 x 600 x 600 with 600 x 600 Access Opening": "600 x 600",
            "600 x 900 x 900 with 600 x 600 Access Opening": "600 x 600",
            "600 x 900 x 900 with 600 x 900 Access Opening": "600 x 900",
            "900 x 900 x 900 with 600 x 900 Access Opening": "600 x 900",
            "900 x 900 x 900 with 900 x 900 Access Opening": "900 x 900",
            "1200 x 1200 x 1200 with 900 x 1200 Access Opening": "900 x 1200",
        };
        return mapping[description] || "";
    };
    // First, add a new function to fetch pump recommendations based on flow and head
    const fetchPumpRecommendations = async (flow, head) => {
        try {
            const response = await apiClient.get(
                `/api/pumpPerformance/pump-recommendations`,
                {
                    params: {
                        flow: flow,
                        head: head,
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error("Error fetching pump recommendations:", error);
            return [];
        }
    };

    const chartContainerRef = useRef(null);

    // Helper function to interpolate a head value from a pump curve
    const interpolateHeadFromCurve = (flow, curve) => {
        // Sort curve points by flow to ensure proper interpolation
        const sortedCurve = [...curve].sort((a, b) => a.flow - b.flow);

        // Check if flow is before the first point
        if (flow <= sortedCurve[0].flow) {
            return sortedCurve[0].head;
        }

        // Check if flow is after the last point
        if (flow >= sortedCurve[sortedCurve.length - 1].flow) {
            return sortedCurve[sortedCurve.length - 1].head;
        }

        // Find the two points to interpolate between
        for (let i = 0; i < sortedCurve.length - 1; i++) {
            if (
                flow >= sortedCurve[i].flow &&
                flow <= sortedCurve[i + 1].flow
            ) {
                const point1 = sortedCurve[i];
                const point2 = sortedCurve[i + 1];

                // Linear interpolation formula
                return (
                    point1.head +
                    ((flow - point1.flow) / (point2.flow - point1.flow)) *
                        (point2.head - point1.head)
                );
            }
        }

        // Fallback in case something goes wrong
        return 16 - flow * 2;
    };

    // Add state for recommended pumps
    const [recommendedPumps, setRecommendedPumps] = useState([]);
    const [showRecommendations, setShowRecommendations] = useState(false);

    // Add a useEffect to fetch recommendations when flow or head changes
    // Modified useEffect that fetches based on adjusted flow but also calculates combined flow
    const [recommendedPumpCurves, setRecommendedPumpCurves] = useState({});

    // Modify your useEffect that fetches pump recommendations
    useEffect(() => {
        const getRecommendations = async () => {
            // Only fetch recommendations if both flow and total head have valid values
            if (
                formData.flow &&
                formData.totalHead &&
                !isNaN(parseFloat(formData.flow)) &&
                !isNaN(parseFloat(formData.totalHead))
            ) {
                const totalFlow = parseFloat(formData.flow);
                const totalHead = parseFloat(formData.totalHead);

                // Calculate number of duty pumps and adjusted flow
                let numberOfDutyPumps = 1; // Default
                let adjustedFlow = totalFlow;

                // Case 1: Dual pumps with Duty/Duty operation - divide flow by 2
                if (
                    formData.numberOfPumps === 2 &&
                    formData.pumpOperation === 2
                ) {
                    numberOfDutyPumps = 2;
                    adjustedFlow = totalFlow / 2;
                }
                // Case 2: Triplex pumps with Duty/Duty/Standby operation - divide flow by 2
                else if (
                    formData.numberOfPumps === 3 &&
                    formData.pumpOperation === 2
                ) {
                    numberOfDutyPumps = 2;
                    adjustedFlow = totalFlow / 2;
                }
                // Case 3: Triplex pumps with Duty/Duty/Duty operation - divide flow by 3
                else if (
                    formData.numberOfPumps === 3 &&
                    formData.pumpOperation === 3
                ) {
                    numberOfDutyPumps = 3;
                    adjustedFlow = totalFlow / 3;
                }

                // Fetch recommendations using adjusted flow
                const recommendations = await fetchPumpRecommendations(
                    adjustedFlow,
                    totalHead
                );

                let processedRecommendations = [];
                // For multiple pumps, enhance the data with combined flow information
                if (numberOfDutyPumps > 1) {
                    // Add combined flow data to each pump
                    processedRecommendations = recommendations.map((pump) => ({
                        ...pump,
                        // Store the individual flow
                        individualFlow: pump.exactFlow,
                        // Calculate the combined flow of all duty pumps
                        combinedFlow: pump.exactFlow * numberOfDutyPumps,
                        // Number of duty pumps for UI display
                        numberOfDutyPumps: numberOfDutyPumps,
                    }));

                    // Filter pumps to ensure combined flow meets requirements
                    processedRecommendations = processedRecommendations.filter(
                        (pump) => Math.abs(pump.combinedFlow - totalFlow) <= 1
                    );
                } else {
                    // For single pump setups, just add the duty pump count
                    processedRecommendations = recommendations.map((pump) => ({
                        ...pump,
                        numberOfDutyPumps: 1,
                    }));
                }

                setRecommendedPumps(processedRecommendations);
                setShowRecommendations(processedRecommendations.length > 0);

                // Fetch performance curves for all recommended pumps
                const pumpCurves = {};
                const fetchPromises = processedRecommendations.map(
                    async (pump) => {
                        try {
                            const response = await apiClient.post(
                                "/api/pumpPerformance/performance-curve",
                                { pumpModel: pump.supplierModel }
                            );

                            pumpCurves[pump.supplierModel] = response.data;
                        } catch (error) {
                            console.error(
                                `Error fetching curve for ${pump.supplierModel}:`,
                                error
                            );
                            pumpCurves[pump.supplierModel] = [];
                        }
                    }
                );

                // Wait for all performance curves to be fetched
                await Promise.all(fetchPromises);
                setRecommendedPumpCurves(pumpCurves);
            } else {
                setRecommendedPumps([]);
                setShowRecommendations(false);
                setRecommendedPumpCurves({});
            }
        };

        getRecommendations();
    }, [
        formData.flow,
        formData.totalHead,
        formData.numberOfPumps,
        formData.pumpOperation,
    ]);

    // PumpRecommendations component with enhanced display for multiple duty pumps
    // PumpRecommendations component with enhanced display for multiple duty pumps
    // Add this state to track the limit
    const [pumpDisplayLimit, setPumpDisplayLimit] = useState(3); // Default to 3 as shown in your screenshot

    // 4. Add the hidden PDF chart component to your render method
    // Add this somewhere before the renderPdfGenerator() call in your JSX
    // Replace your renderHiddenPdfChart function with this updated version

    const renderHiddenPdfChart = () => {
        return (
            <Box
                ref={pdfChartContainerRef}
                className="pdf-chart" // Add a class for easy identification
                sx={{
                    // Change from visibility: hidden to display: none
                    // This makes it easier to temporarily show for capture
                    display: "none",
                    width: "1000px", // Fixed width for consistent PDF output
                    height: "600px", // Fixed height for consistent PDF output
                    position: "absolute",
                    top: 0,
                    left: 0,
                    backgroundColor: "white", // Add background
                    zIndex: -1000, // Initially behind everything
                }}
                data-pdf-chart="true" // Add data attribute for easy identification
            >
                <Paper
                    sx={{
                        p: 3,
                        bgcolor: "white",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Flow vs. Total Head Chart
                    </Typography>
                    <ResponsiveContainer width="100%" height={500}>
                        <LineChart
                            data={pdfChartData}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="flow"
                                domain={[
                                    0,
                                    (dataMax) => Math.ceil(dataMax * 1.1),
                                ]}
                                type="number"
                                label={{
                                    value: "Flow (l/s)",
                                    position: "insideBottomRight",
                                    offset: -5,
                                }}
                            />
                            <YAxis
                                type="number"
                                domain={[
                                    0,
                                    (dataMax) => Math.ceil(dataMax * 1.1),
                                ]}
                                label={{
                                    value: "Head (m)",
                                    angle: -90,
                                    position: "insideLeft",
                                }}
                            />

                            <Tooltip />
                            <Legend />

                            {/* System Curve */}
                            {headCalculation.calculateHead && (
                                <Line
                                    type="monotone"
                                    dataKey="systemHead"
                                    name="System Curve"
                                    stroke="#000000"
                                    strokeWidth={2}
                                    dot={{ r: 3 }}
                                    activeDot={{ r: 5 }}
                                    connectNulls={true}
                                />
                            )}

                            {/* Selected pump performance line */}
                            {pumpPerformanceCurve.length > 0 && (
                                <Line
                                    type="monotone"
                                    dataKey="head"
                                    name={
                                        formData.pumpModel
                                            ? `${formData.pumpModel} Performance`
                                            : "Pump Performance"
                                    }
                                    stroke="#1976d2"
                                    strokeWidth={2.5}
                                    dot={{ r: 3 }}
                                    activeDot={{ r: 5 }}
                                    connectNulls={true}
                                />
                            )}

                            {/* Manual Operating Point - when not in calculation mode */}
                            {!headCalculation.calculateHead &&
                                formData.flow &&
                                formData.totalHead && (
                                    <Line
                                        type="monotone"
                                        dataKey="manualHead"
                                        name="Operating Point"
                                        stroke="#ff0000"
                                        strokeWidth={0}
                                        dot={{
                                            r: 6,
                                            stroke: "#ff0000",
                                            strokeWidth: 2,
                                            fill: "#ff0000",
                                        }}
                                    />
                                )}

                            {/* Calculated Operating Point - when in system calculation mode */}
                            {headCalculation.calculateHead &&
                                formData.flow &&
                                formData.totalHead && (
                                    <Line
                                        type="monotone"
                                        dataKey="operatingPoint"
                                        name="Specified Point"
                                        stroke="#ff0000"
                                        strokeWidth={0}
                                        dot={{
                                            r: 6,
                                            stroke: "#ff0000",
                                            strokeWidth: 2,
                                            fill: "#ff0000",
                                        }}
                                    />
                                )}

                            {/* Intersection Point between pump and system curves */}
                            {pdfChartData.some(
                                (point) => point.intersectionPoint !== undefined
                            ) && (
                                <Line
                                    type="monotone"
                                    dataKey="intersectionPoint"
                                    name="System Operating Point"
                                    stroke="#009900"
                                    strokeWidth={0}
                                    dot={{
                                        r: 6,
                                        stroke: "#009900",
                                        strokeWidth: 2,
                                        fill: "#009900",
                                    }}
                                    activeDot={{ r: 8 }}
                                />
                            )}
                        </LineChart>
                    </ResponsiveContainer>
                </Paper>
            </Box>
        );
    };

    // Now modify the PumpRecommendations component
    const PumpRecommendations = () => {
        if (!showRecommendations || recommendedPumps.length === 0) {
            return null;
        }

        const handlePumpSelection = (pumpModel) => {
            setFormData((prev) => ({
                ...prev,
                pumpModel: pumpModel,
            }));
        };

        // Determine if we're in a multiple duty pump configuration
        const isMultipleDutyPumps =
            (formData.numberOfPumps === 2 && formData.pumpOperation === 2) || // Dual Duty/Duty
            (formData.numberOfPumps === 3 && formData.pumpOperation === 2) || // Triplex Duty/Duty/Standby
            (formData.numberOfPumps === 3 && formData.pumpOperation === 3); // Triplex Duty/Duty/Duty

        // Limit the number of pumps displayed
        const displayedPumps = recommendedPumps.slice(0, pumpDisplayLimit);

        return (
            <Box sx={{ mt: 2, mb: 2 }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 1,
                    }}
                >
                    <Typography variant="h6">Recommended Pumps</Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <FormControl size="small" sx={{ width: 140 }}>
                            <Select
                                value={pumpDisplayLimit}
                                onChange={(e) =>
                                    setPumpDisplayLimit(e.target.value)
                                }
                                displayEmpty
                                variant="outlined"
                                startAdornment={
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{ mr: 1 }}
                                    >
                                        Showing
                                    </Typography>
                                }
                            >
                                {[1, 2, 3, 4, 5].map((limit) => (
                                    <MenuItem key={limit} value={limit}>
                                        {limit}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2 }}
                >
                    Based on your flow rate ({formData.flow} L/s) and total head
                    ({formData.totalHead} m)
                    {isMultipleDutyPumps &&
                        ` - ${
                            recommendedPumps[0]?.numberOfDutyPumps || 0
                        } pumps working together`}
                </Typography>
                <Grid container spacing={2}>
                    {displayedPumps.map((pump) => {
                        const isSelected =
                            formData.pumpModel === pump.supplierModel;

                        // Calculate combinedFlow if it doesn't exist
                        const numberOfDutyPumps = pump.numberOfDutyPumps || 1;
                        const individualFlow = pump.exactFlow || 0;
                        const combinedFlow =
                            pump.combinedFlow ||
                            individualFlow * numberOfDutyPumps;

                        return (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={4}
                                key={pump.supplierModel}
                            >
                                <Paper
                                    sx={{
                                        p: 2,
                                        border: isSelected
                                            ? "2px solid #1976d2"
                                            : "1px solid #e0e0e0",
                                        cursor: "pointer",
                                        transition: "all 0.2s ease",
                                        "&:hover": {
                                            boxShadow: 3,
                                            transform: "translateY(-2px)",
                                            borderColor: "#1976d2",
                                        },
                                        backgroundColor: isSelected
                                            ? "rgba(25, 118, 210, 0.04)"
                                            : "white",
                                    }}
                                    onClick={() =>
                                        handlePumpSelection(pump.supplierModel)
                                    }
                                >
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        {pump.supplierModel}
                                    </Typography>
                                    <Typography variant="body2">
                                        {pump.kw}kW {pump.voltage}V -{" "}
                                        {pump.impellerType}
                                    </Typography>

                                    {isMultipleDutyPumps ? (
                                        <>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                Single pump:{" "}
                                                {individualFlow.toFixed(2)} L/s
                                                at {formData.totalHead}m
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="primary"
                                                sx={{ fontWeight: "bold" }}
                                            >
                                                Combined:{" "}
                                                {combinedFlow.toFixed(2)} L/s
                                                with {numberOfDutyPumps} pumps
                                            </Typography>
                                        </>
                                    ) : (
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            Exact flow at {formData.totalHead}m:{" "}
                                            {pump.exactFlow.toFixed(2)} L/s
                                        </Typography>
                                    )}

                                    <Button
                                        variant={
                                            isSelected
                                                ? "contained"
                                                : "outlined"
                                        }
                                        size="small"
                                        sx={{ mt: 1 }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handlePumpSelection(
                                                pump.supplierModel
                                            );
                                        }}
                                    >
                                        {isSelected ? "SELECTED" : "SELECT"}
                                    </Button>
                                </Paper>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        );
    };
    // Add this useEffect hook to your component
    useEffect(() => {
        // Create function to directly select a pump from recommendation
        window.selectPumpFromRecommendation = (pumpModel) => {
            console.log("Directly setting pump model to:", pumpModel);

            // Update form data
            setFormData((prev) => ({ ...prev, pumpModel }));

            // Find the dropdown element and manually trigger a change if needed
            setTimeout(() => {
                const pumpSelect = document.querySelector(
                    'select[name="pumpModel"]'
                );
                if (pumpSelect) {
                    console.log(
                        "Found pump select element, current value:",
                        pumpSelect.value
                    );

                    // If dropdown doesn't match, try to update it directly
                    if (pumpSelect.value !== pumpModel) {
                        console.log(
                            "Dropdown value doesn't match, attempting direct update"
                        );

                        // Try to find the option
                        const option = Array.from(pumpSelect.options).find(
                            (opt) => opt.value === pumpModel
                        );

                        if (option) {
                            pumpSelect.value = pumpModel;
                            console.log(
                                "Directly updated dropdown value to:",
                                pumpModel
                            );

                            // Trigger change event
                            const event = new Event("change", {
                                bubbles: true,
                            });
                            pumpSelect.dispatchEvent(event);
                        }
                    }
                }
            }, 100);
        };

        return () => {
            // Clean up
            delete window.selectPumpFromRecommendation;
        };
    }, []);

    const calculateTotalHead = (staticHead, length, size, type, flowRate) => {
        // Convert input values to numbers
        const head = parseFloat(staticHead) || 0;
        const mainLength = parseFloat(length) || 0;
        const mainSize = parseFloat(size) || 0;
        const mainType = parseFloat(type) || 150;
        const flow = parseFloat(flowRate) || 0;

        // If calculation mode is on but required values are missing, just return static head
        if (
            !headCalculation.calculateHead ||
            mainLength <= 0 ||
            mainSize <= 0 ||
            flow <= 0
        ) {
            return head.toFixed(2);
        }

        try {
            // Convert size from mm to meters and flow from L/s to m³/s
            const sizeInMeters = mainSize / 1000;
            const flowInCubicMetersPerSecond = flow / 1000;

            // Calculate friction loss using the formula
            const frictionLoss =
                10.67 *
                (mainLength /
                    (Math.pow(mainType, 1.85) * Math.pow(sizeInMeters, 4.87))) *
                Math.pow(flowInCubicMetersPerSecond, 1.85);

            // Handle potential NaN or Infinity
            if (isNaN(frictionLoss) || !isFinite(frictionLoss)) {
                console.warn("Invalid friction loss calculation result");
                return head.toFixed(2);
            }

            // Total Head = Static Head + Friction Loss
            return (head + frictionLoss).toFixed(2);
        } catch (error) {
            console.error("Error in total head calculation:", error);
            return head.toFixed(2); // Return just static head on error
        }
    };

    // Keep track of calculation fields in local state
    const [headCalculation, setHeadCalculation] = useState({
        calculateHead: false,
        staticHead: "",
        risingMainLength: "",
        risingMainSize: "",
        risingMainType: "",
    });

    const [quoteBreakdown, setQuoteBreakdown] = useState({
        tankPrice: 0,
        accessCoverPrice: 0,
        valvePitPrice: 0,
        pipeWorkPrice: 0,
        pumpPrice: 0,
        mixerPrice: 0,
        freightPrice: 0,
        levelControlPrice: 0,
        valvePitAccessCoverPrice: 0,
        pedestalPrice: 0,
        guideRailPrice: 0,
        controllerPrice: 0,
        totalPrice: 0,
    });

    const [showValvePit, setShowValvePit] = useState(false);

    const [levelControlOptions, setLevelControlOptions] = useState({
        descriptions: [],
    });
    const [freightOptions, setFreightOptions] = useState({
        cities: [],
    });

    const [mixerOptions, setMixerOptions] = useState({
        mixerTypes: [],
    });
    const [pumpOptions, setPumpOptions] = useState({
        supplierModels: [],
    });

    const [pipeWorkOptions, setPipeWorkOptions] = useState({
        sizes: [],
        pipeMaterials: [],
        valveMaterials: [],
    });
    const [dimensions, setDimensions] = useState({ diameters: [], depths: [] });
    const [valvePitOptions, setValvePitOptions] = useState({
        descriptions: [],
    });
    const [accessOptions, setAccessOptions] = useState({
        sizes: [],
        coverTypes: [],
    });

    useEffect(() => {
        console.log("Form Data Updated:", formData);
    }, [formData]);

    // Line chart for Flow vs. Total Head
    const [chartData, setChartData] = useState([]);

    // Generate the data points for the graph
    // In your component, add a new state or derived data
    const [combinedChartData, setCombinedChartData] = useState([]);

    // Add this useEffect to log combinedChartData whenever it changes
    useEffect(() => {
        console.log("combinedChartData updated:", combinedChartData);

        // Log the min/max values to understand the data ranges
        if (combinedChartData.length > 0) {
            const headValues = combinedChartData
                .map((point) => point.head)
                .filter(Boolean);
            const flowValues = combinedChartData
                .map((point) => point.flow)
                .filter(Boolean);
            const systemFlowValues = combinedChartData
                .map((point) => point.systemFlow)
                .filter(Boolean);
            const manualFlowValues = combinedChartData
                .map((point) => point.manualFlow)
                .filter(Boolean);

            console.log(
                "Head range:",
                Math.min(...headValues),
                "to",
                Math.max(...headValues)
            );

            if (flowValues.length) {
                console.log(
                    "Flow range:",
                    Math.min(...flowValues),
                    "to",
                    Math.max(...flowValues)
                );
            }

            if (systemFlowValues.length) {
                console.log(
                    "System Flow range:",
                    Math.min(...systemFlowValues),
                    "to",
                    Math.max(...systemFlowValues)
                );
            }

            if (manualFlowValues.length) {
                console.log("Manual Flow:", manualFlowValues);
            }
        }
    }, [combinedChartData]);

    // Then, modify your useEffect that generates chart data
    // Improved chart data generation to ensure both x and y values are available

    const formatPriceLabel = (key) => {
        const labels = {
            tankPrice: "Tank Price",
            accessCoverPrice: "Access Cover Price",
            valvePitPrice: "Valve Pit Price",
            pipeWorkPrice: "Pipe Work Price",
            pumpPrice: "Pump Price",
            mixerPrice: "Mixer Price",
            freightPrice: "Freight Price",
            levelControlPrice: "Level Control Price",
            valvePitAccessCoverPrice: "Valve Pit Access Cover Price",
            pedestalPrice: "Pedestal Price",
            guideRailPrice: "Guide Rail Price",
            controllerPrice: "Controller Price",
        };

        return labels[key] || key;
    };

    // Add these static options
    const pumpConfigurations = {
        numberOfPumps: [
            { value: 1, label: "Single" },
            { value: 2, label: "Dual" },
            { value: 3, label: "Triplex" },
        ],
        // Group operations by number of pumps
        pumpOperationsByPumpCount: {
            1: [{ value: 1, label: "Duty" }],
            2: [
                { value: 1, label: "Duty/Standby" },
                { value: 2, label: "Duty/Duty" },
            ],
            3: [
                { value: 1, label: "Duty/Standby/Standby" },
                { value: 2, label: "Duty/Duty/Standby" },
                { value: 3, label: "Duty/Duty/Duty" },
            ],
        },
        pumpSetups: ["Freestanding", "On Guide Rails"],
    };
    const [quote, setQuote] = useState(null);
    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState({
        open: false,
        message: "",
        severity: "success",
    });

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = async () => {
        setLoading(true);
        try {
            const [
                dimensionsResponse,
                accessOptionsResponse,
                valvePitResponse,
                pipeWorkResponse,
                pumpResponse,
                mixerResponse,
                freightResponse,
                levelControlResponse, // Fetch level control options
            ] = await Promise.all([
                apiClient.get("/api/quote/tank-dimensions"),
                apiClient.get("/api/quote/access-cover-options"),
                apiClient.get("/api/quote/valve-pit-options"),
                apiClient.get("/api/quote/pipe-work-options"),
                apiClient.get("/api/quote/pump-options"),
                apiClient.get("/api/quote/mixer-options"),
                apiClient.get("/api/quote/freight-options"),
                apiClient.get("/api/quote/level-control-options"), // New API call
            ]);

            setDimensions(dimensionsResponse.data);
            setAccessOptions(accessOptionsResponse.data);
            setValvePitOptions(valvePitResponse.data);
            setPipeWorkOptions(pipeWorkResponse.data);
            setPumpOptions(pumpResponse.data);
            setMixerOptions(mixerResponse.data);
            setFreightOptions(freightResponse.data);
            setLevelControlOptions(levelControlResponse.data); // Store level control options
        } catch (err) {
            setNotification({
                open: true,
                message:
                    err.response?.data?.message ||
                    "Failed to load initial data",
                severity: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === "valvePitDescription") {
            const mappedSize = mapValvePitDescriptionToAccessSize(value);
            setFormData((prev) => ({
                ...prev,
                valvePitDescription: value,
                valvePitAccessCoverSize: mappedSize, // Automatically update based on description
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    // Add a state to track whether we should check for controller errors
    const [shouldCheckController, setShouldCheckController] = useState(false);
    const [controllerError, setControllerError] = useState(false);

    // Update the handleSubmit function
    const handleSubmit = async () => {
        if (
            !formData.tankDiameter ||
            !formData.tankDepth ||
            !formData.accessSize ||
            !formData.accessCoverType ||
            // Only validate valve pit fields if valve pit is required
            (showValvePit &&
                (!formData.valvePitDescription ||
                    !formData.valvePitAccessCoverSize ||
                    !formData.valvePitAccessCoverType))
        ) {
            setNotification({
                open: true,
                message: "Please fill in all required fields",
                severity: "error",
            });
            return;
        }

        // Check if we should look for controller errors
        // Only check if pump model and control type are selected
        const shouldCheck = !!formData.pumpModel && !!formData.controlType;
        setShouldCheckController(shouldCheck);

        setLoading(true);
        try {
            const response = await apiClient.post(
                "/api/quote/calculate",
                formData
            );

            // Update all breakdown fields
            setQuoteBreakdown({
                tankPrice: response.data.tankPrice ?? 0,
                accessCoverPrice: response.data.accessCoverPrice ?? 0,
                valvePitPrice: response.data.valvePitPrice ?? 0,
                pipeWorkPrice: response.data.pipeWorkPrice ?? 0,
                pumpPrice: response.data.pumpPrice ?? 0,
                mixerPrice: response.data.mixerPrice ?? 0,
                freightPrice: response.data.freightPrice ?? 0,
                levelControlPrice: response.data.levelControlPrice ?? 0,
                valvePitAccessCoverPrice:
                    response.data.valvePitAccessCoverPrice ?? 0,
                pedestalPrice: response.data.pedestalPrice ?? 0,
                guideRailPrice: response.data.guideRailPrice ?? 0,
                controllerPrice: response.data.controllerPrice ?? 0,
                totalPrice: response.data.totalPrice ?? 0,
            });

            // Check if controller price is 0 when we expect it to have a value
            const hasControllerError =
                shouldCheck && response.data.controllerPrice === 0;
            setControllerError(hasControllerError);

            setNotification({
                open: true,
                message: hasControllerError
                    ? "Quote calculated, but no matching controller found in database. Please check your pump and control type selection."
                    : "Quote calculated successfully",
                severity: hasControllerError ? "warning" : "success",
            });
        } catch (err) {
            setNotification({
                open: true,
                message:
                    err.response?.data?.message || "Failed to calculate quote",
                severity: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSnackbarClose = () => {
        setNotification((prev) => ({ ...prev, open: false }));
    };
    // Add a new state to store the selected pump's performance curve
    const [pumpPerformanceCurve, setPumpPerformanceCurve] = useState([]);

    useEffect(() => {
        console.log("pumpPerformanceCurve:", pumpPerformanceCurve);
    }, [pumpPerformanceCurve]);

    // Add a function to fetch pump performance data
    const [pumpPerformanceStatus, setPumpPerformanceStatus] = useState({
        loading: false,
        error: false,
        errorMessage: "",
    });

    // Modify the fetchPumpPerformanceData function to include error handling
    const fetchPumpPerformanceData = async (pumpModel) => {
        if (!pumpModel) {
            // Reset to default curve if no pump is selected
            setPumpPerformanceCurve([]);
            setPumpPerformanceStatus({
                loading: false,
                error: false,
                errorMessage: "",
            });
            return;
        }

        try {
            setPumpPerformanceStatus({
                loading: true,
                error: false,
                errorMessage: "",
            });

            const response = await apiClient.post(
                "/api/pumpPerformance/performance-curve",
                { pumpModel: pumpModel }
            );

            // The response should contain points like [{flow: 1, head: 15}, {flow: 2, head: 14}, etc.]
            setPumpPerformanceCurve(response.data);
            setPumpPerformanceStatus({
                loading: false,
                error: false,
                errorMessage: "",
            });
        } catch (error) {
            console.error("Error fetching pump performance data:", error);
            setPumpPerformanceCurve([]);

            // Check for 404 specifically
            const errorMessage =
                error.response?.status === 404
                    ? `No performance data available for pump model: ${pumpModel}`
                    : "Error loading pump performance data. Please try again.";

            setPumpPerformanceStatus({
                loading: false,
                error: true,
                errorMessage: errorMessage,
            });
        }
    };

    // Update the useEffect that depends on formData.pumpModel
    useEffect(() => {
        // Fetch performance data when pump model changes
        if (formData.pumpModel) {
            fetchPumpPerformanceData(formData.pumpModel);
        } else {
            setPumpPerformanceCurve([]);
        }
    }, [formData.pumpModel]);

    // Updated system curve generation in useEffect
    // Updated system curve generation in useEffect
    // Updated system curve generation in useEffect
    const generateChartData = () => {
        let pumpData = [];
        let recommendedPumpLines = {};
        let systemData = [];
        let manualPoint = null;
        let intersectionPoint = null;

        // 1. Generate pump performance curve data for selected pump
        if (pumpPerformanceCurve.length > 0) {
            // Get number of duty pumps for the selected pump
            const selectedPumpModel = formData.pumpModel;
            const selectedPump = recommendedPumps.find(
                (p) => p.supplierModel === selectedPumpModel
            );
            const numberOfDutyPumps = selectedPump?.numberOfDutyPumps || 1;

            // If we have multiple duty pumps, adjust the flow values to show combined flow
            if (numberOfDutyPumps > 1) {
                pumpData = pumpPerformanceCurve
                    .filter(
                        (point) =>
                            point.flow !== null && point.flow !== undefined
                    )
                    .map((point) => ({
                        flow: point.flow * numberOfDutyPumps, // Multiply flow by number of duty pumps
                        head: point.head, // Head remains the same
                    }));
            } else {
                // Single pump - use data as is
                pumpData = pumpPerformanceCurve
                    .filter(
                        (point) =>
                            point.flow !== null && point.flow !== undefined
                    )
                    .map((point) => ({
                        flow: point.flow,
                        head: point.head,
                    }));
            }
        }

        // 2. Process performance curves for recommended pumps
        // Only include pumps that are visible based on the display limit
        const visiblePumps = recommendedPumps.slice(0, pumpDisplayLimit);

        visiblePumps.forEach((pump) => {
            const pumpModel = pump.supplierModel;
            const curveData = recommendedPumpCurves[pumpModel];
            const numberOfDutyPumps = pump.numberOfDutyPumps || 1;

            if (curveData && curveData.length > 0) {
                // Only add this pump's curve if it's not the selected pump (to avoid duplication)
                if (pumpModel !== formData.pumpModel) {
                    // Adjust the flow based on number of duty pumps
                    recommendedPumpLines[pumpModel] = curveData
                        .filter(
                            (point) =>
                                point.flow !== null && point.flow !== undefined
                        )
                        .map((point) => ({
                            flow: point.flow * numberOfDutyPumps, // Multiply flow by number of duty pumps
                            head: point.head, // Head remains the same
                        }));
                }
            }
        });

        // 3. Generate system curve data if calculation mode is on
        if (headCalculation.calculateHead) {
            const staticHead = parseFloat(headCalculation.staticHead || 0);
            const risingMainLength = parseFloat(
                headCalculation.risingMainLength || 0
            );
            const risingMainSize = parseFloat(
                headCalculation.risingMainSize || 0
            );
            const risingMainType = parseFloat(
                headCalculation.risingMainType || 150
            );

            // Only generate system curve if we have all needed values
            if (risingMainLength > 0 && risingMainSize > 0) {
                // Find the flow range from pump data to match our system curve to it
                let maxFlow = 10; // Default
                let numPoints = 12; // Default number of points

                // If we have pump data, use its range to determine our system curve range
                if (pumpData.length > 0) {
                    maxFlow = Math.max(...pumpData.map((p) => p.flow)) * 1.1; // Slightly exceed max pump flow

                    // Adjust number of points based on pump data points
                    numPoints = Math.min(Math.max(pumpData.length, 10), 20); // Between 10-20 points
                } else if (Object.keys(recommendedPumpLines).length > 0) {
                    // If no primary pump but we have recommended pumps, use their range
                    const allFlows = [];
                    Object.values(recommendedPumpLines).forEach((points) => {
                        points.forEach((p) => allFlows.push(p.flow));
                    });

                    if (allFlows.length > 0) {
                        maxFlow = Math.max(...allFlows) * 1.1;
                        numPoints = Math.min(
                            Math.max(
                                allFlows.length /
                                    Object.keys(recommendedPumpLines).length,
                                10
                            ),
                            20
                        );
                    }
                }

                // Generate evenly distributed points
                const flowStep = maxFlow / (numPoints - 1);

                // Always include zero flow point at static head
                systemData.push({
                    flow: 0,
                    systemHead: staticHead,
                });

                // Generate evenly spaced system curve points
                for (let i = 1; i < numPoints; i++) {
                    const flow = i * flowStep;
                    const calculatedHead = parseFloat(
                        calculateTotalHead(
                            staticHead,
                            risingMainLength,
                            risingMainSize,
                            risingMainType,
                            flow
                        )
                    );

                    systemData.push({
                        flow: flow,
                        systemHead: calculatedHead,
                    });
                }
            }
        }

        // 4. Generate manual input point if needed
        if (
            !headCalculation.calculateHead &&
            formData.flow &&
            formData.totalHead &&
            !isNaN(parseFloat(formData.flow)) &&
            !isNaN(parseFloat(formData.totalHead))
        ) {
            manualPoint = {
                flow: parseFloat(formData.flow),
                manualHead: parseFloat(formData.totalHead),
            };
        }

        // Now combine all data sets
        const combinedData = [];

        // Add selected pump data points
        pumpData.forEach((point) => {
            combinedData.push({
                flow: point.flow,
                head: point.head,
            });
        });

        // Process and add data for each recommended pump
        // Process and add data for each recommended pump
        Object.entries(recommendedPumpLines).forEach(
            ([pumpModel, curvePoints]) => {
                curvePoints.forEach((point) => {
                    // Find if this flow already exists in combined data
                    const existingPoint = combinedData.find(
                        (p) => Math.abs(p.flow - point.flow) < 0.01
                    );

                    if (existingPoint) {
                        // Add this pump's head value to existing point
                        existingPoint[`pump_${pumpModel}`] = point.head;
                    } else {
                        // Create a new point with this pump's data
                        const newPoint = {
                            flow: point.flow,
                            [`pump_${pumpModel}`]: point.head,
                        };
                        combinedData.push(newPoint);
                    }
                });
            }
        );

        // Add system data points (keeping them separate)
        systemData.forEach((point) => {
            // Check if a point with this flow already exists
            const existingPoint = combinedData.find(
                (p) => Math.abs(p.flow - point.flow) < 0.01
            );

            if (existingPoint) {
                // Add systemHead to existing point
                existingPoint.systemHead = point.systemHead;
            } else {
                // Add new point with just systemHead
                combinedData.push({
                    flow: point.flow,
                    systemHead: point.systemHead,
                });
            }
        });

        // Add manual point if exists
        if (manualPoint) {
            combinedData.push(manualPoint);
        }

        // Add operating point for calculation mode
        if (
            headCalculation.calculateHead &&
            formData.flow &&
            formData.totalHead
        ) {
            combinedData.push({
                flow: parseFloat(formData.flow),
                operatingPoint: parseFloat(formData.totalHead),
            });
        }

        // Calculate intersection point when we have both curves
        if (
            headCalculation.calculateHead &&
            pumpData.length > 0 &&
            systemData.length > 0
        ) {
            // Find intersection point between pump curve and system curve
            // We'll compare each adjacent pump data point with the system curve
            for (let i = 0; i < pumpData.length - 1; i++) {
                const point1 = pumpData[i];
                const point2 = pumpData[i + 1];

                // Find system curve points that are within this flow range
                const systemPoint1 = systemData.find(
                    (p) => Math.abs(p.flow - point1.flow) < 0.01
                );
                const systemPoint2 = systemData.find(
                    (p) => Math.abs(p.flow - point2.flow) < 0.01
                );

                if (systemPoint1 && systemPoint2) {
                    // Check if the curves intersect between these points
                    const pumpHead1 = point1.head;
                    const pumpHead2 = point2.head;
                    const sysHead1 = systemPoint1.systemHead;
                    const sysHead2 = systemPoint2.systemHead;

                    // Check if one curve is above the other at point1, and below at point2 (or vice versa)
                    if (
                        (pumpHead1 > sysHead1 && pumpHead2 < sysHead2) ||
                        (pumpHead1 < sysHead1 && pumpHead2 > sysHead2)
                    ) {
                        // Linear interpolation to find intersection point
                        const flow1 = point1.flow;
                        const flow2 = point2.flow;

                        // Calculate slopes
                        const pumpSlope =
                            (pumpHead2 - pumpHead1) / (flow2 - flow1);
                        const sysSlope =
                            (sysHead2 - sysHead1) / (flow2 - flow1);

                        // Calculate intersection flow
                        const intersectFlow =
                            flow1 +
                            (sysHead1 - pumpHead1) / (pumpSlope - sysSlope);

                        // Calculate intersection head using pump curve equation
                        const intersectHead =
                            pumpHead1 + pumpSlope * (intersectFlow - flow1);

                        // Add intersection point to data
                        intersectionPoint = {
                            flow: intersectFlow,
                            intersectionPoint: intersectHead,
                        };

                        break; // Stop after finding first intersection
                    }
                }
            }

            // If we didn't find an intersection with the algorithm, estimate one
            if (!intersectionPoint) {
                // Try to find points where pump head and system head are close
                let closestDiff = Number.MAX_VALUE;
                let closestPoint = null;

                combinedData.forEach((point) => {
                    if (
                        point.head !== undefined &&
                        point.systemHead !== undefined
                    ) {
                        const diff = Math.abs(point.head - point.systemHead);
                        if (diff < closestDiff) {
                            closestDiff = diff;
                            closestPoint = point;
                        }
                    }
                });

                // Use the closest point if we found one
                if (closestPoint && closestDiff < 2) {
                    // Only use if reasonably close
                    intersectionPoint = {
                        flow: closestPoint.flow,
                        intersectionPoint:
                            (closestPoint.head + closestPoint.systemHead) / 2,
                    };
                }
            }

            // Add intersection point to combined data if found
            if (intersectionPoint) {
                combinedData.push(intersectionPoint);
            }
        }

        // Sort combined data by flow for proper display
        combinedData.sort((a, b) => a.flow - b.flow);

        // Set the combined data
        setCombinedChartData(combinedData);
    };

    // Add useEffect to run the chart data generation
    useEffect(() => {
        generateChartData();
    }, [
        headCalculation,
        formData.flow,
        formData.totalHead,
        formData.pumpModel,
        pumpPerformanceCurve,
        recommendedPumpCurves,
    ]);
    useEffect(() => {
        if (headCalculation.calculateHead) {
            // When Calculate Head is checked, update pipeWorkSize and pipeWorkMaterial
            // to match Rising Main values
            const materialMapping = {
                150: "PVC",
                140: "Polyethylene",
            };

            setFormData((prev) => ({
                ...prev,
                pipeWorkSize: headCalculation.risingMainSize || "",
                pipeWorkMaterial:
                    materialMapping[headCalculation.risingMainType] || "",
            }));
        }
    }, [
        headCalculation.calculateHead,
        headCalculation.risingMainSize,
        headCalculation.risingMainType,
    ]);

    // 1. Add a new state variable for the PDF chart data
    const [pdfChartData, setPdfChartData] = useState([]);
    // 2. Add a ref for the PDF chart container
    const pdfChartContainerRef = useRef(null);
    // 3. Modify the generateChartData function to also create PDF-specific data
    // Add this right after setting combinedChartData in the existing generateChartData function
    useEffect(() => {
        // Generate PDF-specific chart data that only includes the selected pump data
        const generatePdfChartData = () => {
            let pdfData = [];

            // 1. Generate data for selected pump performance curve only
            if (pumpPerformanceCurve.length > 0) {
                // Get number of duty pumps for the selected pump
                const selectedPumpModel = formData.pumpModel;
                const selectedPump = recommendedPumps.find(
                    (p) => p.supplierModel === selectedPumpModel
                );
                const numberOfDutyPumps = selectedPump?.numberOfDutyPumps || 1;

                // If we have multiple duty pumps, adjust the flow values to show combined flow
                if (numberOfDutyPumps > 1) {
                    pdfData = pumpPerformanceCurve
                        .filter(
                            (point) =>
                                point.flow !== null && point.flow !== undefined
                        )
                        .map((point) => ({
                            flow: point.flow * numberOfDutyPumps, // Multiply flow by number of duty pumps
                            head: point.head, // Head remains the same
                        }));
                } else {
                    // Single pump - use data as is
                    pdfData = pumpPerformanceCurve
                        .filter(
                            (point) =>
                                point.flow !== null && point.flow !== undefined
                        )
                        .map((point) => ({
                            flow: point.flow,
                            head: point.head,
                        }));
                }
            }

            // 2. Add system curve data if calculation mode is on
            if (headCalculation.calculateHead) {
                const systemData = combinedChartData
                    .filter((point) => point.systemHead !== undefined)
                    .map((point) => ({
                        flow: point.flow,
                        systemHead: point.systemHead,
                    }));

                // Merge with pump data
                systemData.forEach((point) => {
                    const existingPoint = pdfData.find(
                        (p) => Math.abs(p.flow - point.flow) < 0.01
                    );
                    if (existingPoint) {
                        existingPoint.systemHead = point.systemHead;
                    } else {
                        pdfData.push({
                            flow: point.flow,
                            systemHead: point.systemHead,
                        });
                    }
                });
            }

            // 3. Add the operating point or manual point
            if (headCalculation.calculateHead) {
                if (formData.flow && formData.totalHead) {
                    pdfData.push({
                        flow: parseFloat(formData.flow),
                        operatingPoint: parseFloat(formData.totalHead),
                    });
                }
            } else if (formData.flow && formData.totalHead) {
                pdfData.push({
                    flow: parseFloat(formData.flow),
                    manualHead: parseFloat(formData.totalHead),
                });
            }

            // 4. Add intersection point if it exists
            const intersectionPoint = combinedChartData.find(
                (point) => point.intersectionPoint !== undefined
            );
            if (intersectionPoint) {
                pdfData.push({
                    flow: intersectionPoint.flow,
                    intersectionPoint: intersectionPoint.intersectionPoint,
                });
            }

            // 5. Sort data by flow value
            pdfData.sort((a, b) => a.flow - b.flow);

            setPdfChartData(pdfData);
        };

        generatePdfChartData();
    }, [
        combinedChartData,
        pumpPerformanceCurve,
        formData.pumpModel,
        formData.flow,
        formData.totalHead,
        headCalculation.calculateHead,
        recommendedPumps,
    ]);
    // Modify the chart data generation to use the fetched performance curve

    return (
        <Box sx={{ p: 3 }}>
            <NavigationBar />

            <Paper
                sx={{
                    p: 6,

                    m: 3,
                    boxShadow: 3,
                    border: "1px solid #e0e0e0",
                    borderRadius: 2,
                }}
            >
                {" "}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 3,
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            textAlign: "center",
                            width: "100%",
                        }}
                    >
                        Pump Station Quote Calculator
                    </Typography>
                    {loading && <CircularProgress size={24} />}
                </Box>
                <Grid container spacing={4}>
                    {/* Tank and Access Section */}
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                                p: 3,
                                backgroundColor: "#fafafa",
                                boxShadow: 2,
                                borderRadius: 2,
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 3,

                                    pb: 1,
                                }}
                            >
                                Tank and Access Cover
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Tank Diameter</InputLabel>
                                        <Select
                                            name="tankDiameter"
                                            value={formData.tankDiameter}
                                            onChange={handleChange}
                                            label="Tank Diameter"
                                            disabled={loading}
                                        >
                                            {dimensions.diameters.map((d) => (
                                                <MenuItem key={d} value={d}>
                                                    {d}mm
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Tank Depth</InputLabel>
                                        <Select
                                            name="tankDepth"
                                            value={formData.tankDepth}
                                            onChange={handleChange}
                                            label="Tank Depth"
                                            disabled={loading}
                                        >
                                            {dimensions.depths.map((d) => (
                                                <MenuItem key={d} value={d}>
                                                    {d}mm
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>
                                            Access Opening Size
                                        </InputLabel>
                                        <Select
                                            name="accessSize"
                                            value={formData.accessSize}
                                            onChange={handleChange}
                                            label="Access Opening Size"
                                            disabled={loading}
                                        >
                                            {accessOptions.sizes.map((size) => (
                                                <MenuItem
                                                    key={size}
                                                    value={size}
                                                >
                                                    {size}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>
                                            Access Cover Type
                                        </InputLabel>
                                        <Select
                                            name="accessCoverType"
                                            value={formData.accessCoverType}
                                            onChange={handleChange}
                                            label="Access Cover Type"
                                            disabled={loading}
                                        >
                                            <MenuItem value="Not Required">
                                                Not Required
                                            </MenuItem>
                                            {accessOptions.coverTypes.map(
                                                (type) => (
                                                    <MenuItem
                                                        key={type}
                                                        value={type}
                                                    >
                                                        {type}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* Valve Pit Section */}
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                mb: 2,
                            }}
                        >
                            <Checkbox
                                checked={showValvePit}
                                onChange={(e) => {
                                    setShowValvePit(e.target.checked);
                                    if (!e.target.checked) {
                                        setFormData((prev) => ({
                                            ...prev,
                                            valvePitDescription: "",
                                            valvePitAccessCoverType: "",
                                            valvePitAccessCoverSize: "",
                                        }));
                                    }
                                }}
                            />
                            <Typography>Is Valve Pit Required?</Typography>
                        </Box>
                        <Paper
                            sx={{
                                p: 3,
                                backgroundColor: "#fafafa",
                                boxShadow: 2,
                                borderRadius: 2,
                                display: showValvePit ? "block" : "none",
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 3,
                                    pb: 1,
                                }}
                            >
                                Valve Pit Configuration
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Valve Pit Type</InputLabel>
                                        <Select
                                            name="valvePitDescription"
                                            value={formData.valvePitDescription}
                                            onChange={handleChange}
                                            label="Valve Pit Type"
                                            disabled={loading}
                                        >
                                            {valvePitOptions.descriptions.map(
                                                (description) => (
                                                    <MenuItem
                                                        key={description}
                                                        value={description}
                                                    >
                                                        {description}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>
                                            Valve Pit Access Cover Type
                                        </InputLabel>
                                        <Select
                                            name="valvePitAccessCoverType"
                                            value={
                                                formData.valvePitAccessCoverType
                                            }
                                            onChange={handleChange}
                                            label="Valve Pit Access Cover Type"
                                            disabled={loading}
                                        >
                                            {accessOptions.coverTypes.map(
                                                (type) => (
                                                    <MenuItem
                                                        key={type}
                                                        value={type}
                                                    >
                                                        {type}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* Pump Configuration Section */}
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                                p: 3,
                                backgroundColor: "#fafafa",
                                boxShadow: 2,
                                borderRadius: 2,
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 3,

                                    pb: 1,
                                }}
                            >
                                Pump Configuration
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>No. of Pumps</InputLabel>
                                        <Select
                                            name="numberOfPumps"
                                            value={formData.numberOfPumps}
                                            onChange={(e) => {
                                                // Clear pump operation when number of pumps changes
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    numberOfPumps:
                                                        e.target.value,
                                                    pumpOperation: "", // Reset pump operation
                                                }));
                                            }}
                                            label="No. of Pumps"
                                            disabled={loading}
                                        >
                                            {pumpConfigurations.numberOfPumps.map(
                                                (pump) => (
                                                    <MenuItem
                                                        key={pump.value}
                                                        value={pump.value}
                                                    >
                                                        {pump.label}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Pump Operation</InputLabel>
                                        <Select
                                            name="pumpOperation"
                                            value={formData.pumpOperation}
                                            onChange={handleChange}
                                            label="Pump Operation"
                                            disabled={
                                                loading ||
                                                !formData.numberOfPumps
                                            }
                                        >
                                            {formData.numberOfPumps &&
                                                pumpConfigurations.pumpOperationsByPumpCount[
                                                    formData.numberOfPumps
                                                ].map((operation) => (
                                                    <MenuItem
                                                        key={operation.label}
                                                        value={operation.value}
                                                    >
                                                        {operation.label}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Pump Setup</InputLabel>
                                        <Select
                                            name="pumpSetup"
                                            value={formData.pumpSetup}
                                            onChange={handleChange}
                                            label="Pump Setup"
                                            disabled={
                                                loading ||
                                                !formData.numberOfPumps
                                            }
                                        >
                                            {pumpConfigurations.pumpSetups.map(
                                                (setup) => (
                                                    <MenuItem
                                                        key={setup}
                                                        value={setup}
                                                    >
                                                        {setup}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {formData.pumpSetup === "On Guide Rails" && (
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                Guide Rail Type
                                            </InputLabel>
                                            <Select
                                                name="guideRailType"
                                                value={formData.guideRailType}
                                                onChange={handleChange}
                                                label="Guide Rail Type"
                                                disabled={loading}
                                            >
                                                <MenuItem value="">
                                                    Select
                                                </MenuItem>
                                                <MenuItem value="Galvanised Steel">
                                                    Galvanised Steel
                                                </MenuItem>
                                                <MenuItem value="Stainless Steel">
                                                    Stainless Steel
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                            mb: 2,
                                        }}
                                    >
                                        <Checkbox
                                            checked={
                                                headCalculation.calculateHead
                                            }
                                            onChange={(e) => {
                                                setHeadCalculation((prev) => ({
                                                    ...prev,
                                                    calculateHead:
                                                        e.target.checked,
                                                    staticHead: e.target.checked
                                                        ? prev.staticHead
                                                        : "",
                                                    risingMainLength: e.target
                                                        .checked
                                                        ? prev.risingMainLength
                                                        : "",
                                                    risingMainSize: e.target
                                                        .checked
                                                        ? prev.risingMainSize
                                                        : "",
                                                    risingMainType: e.target
                                                        .checked
                                                        ? prev.risingMainType
                                                        : "",
                                                }));

                                                if (!e.target.checked) {
                                                    // Reset total head when unchecking
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        totalHead: "",
                                                    }));
                                                } else {
                                                    // When checking, set risingMainSize and risingMainType from pipe work if empty
                                                    setHeadCalculation(
                                                        (prev) => ({
                                                            ...prev,
                                                            risingMainSize:
                                                                prev.risingMainSize ||
                                                                formData.pipeWorkSize,
                                                            risingMainType:
                                                                prev.risingMainType ||
                                                                (formData.pipeWorkMaterial ===
                                                                "PVC"
                                                                    ? "150"
                                                                    : formData.pipeWorkMaterial ===
                                                                      "Polyethylene"
                                                                    ? "140"
                                                                    : ""),
                                                        })
                                                    );
                                                }
                                            }}
                                        />
                                        <Typography>Calculate Head</Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    {/*  Flow field - Should always update
                                    formData.flow and recalculate total head
                                    when needed */}
                                    <TextField
                                        fullWidth
                                        label="Flow (l/s)"
                                        name="flow"
                                        value={formData.flow}
                                        onChange={(e) => {
                                            const newValue = e.target.value;

                                            if (
                                                headCalculation.calculateHead &&
                                                headCalculation.staticHead &&
                                                headCalculation.risingMainLength &&
                                                headCalculation.risingMainSize &&
                                                headCalculation.risingMainType
                                            ) {
                                                // If in calculation mode and all fields are filled, calculate the head
                                                const calculatedHead =
                                                    calculateTotalHead(
                                                        headCalculation.staticHead,
                                                        headCalculation.risingMainLength,
                                                        headCalculation.risingMainSize,
                                                        headCalculation.risingMainType,
                                                        newValue
                                                    );

                                                setFormData((prev) => ({
                                                    ...prev,
                                                    flow: newValue,
                                                    totalHead: calculatedHead,
                                                }));
                                            } else {
                                                // Just update the flow value
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    flow: newValue,
                                                }));
                                            }
                                        }}
                                        disabled={loading}
                                        type="number"
                                        InputProps={{
                                            inputProps: { min: 0 },
                                        }}
                                    />
                                </Grid>

                                {!headCalculation.calculateHead ? (
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Total Head (m)"
                                            name="totalHead"
                                            value={formData.totalHead}
                                            onChange={handleChange}
                                            disabled={loading}
                                            type="number"
                                            InputProps={{
                                                inputProps: { min: 0 },
                                            }}
                                        />
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <Typography
                                                variant="subtitle1"
                                                sx={{ mt: 1 }}
                                            >
                                                Calculated Total Head:{" "}
                                                {formData.totalHead || "0"} m
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Static Head (m)"
                                                value={
                                                    headCalculation.staticHead
                                                }
                                                onChange={(e) => {
                                                    const newValue =
                                                        e.target.value;
                                                    setHeadCalculation(
                                                        (prev) => ({
                                                            ...prev,
                                                            staticHead:
                                                                newValue,
                                                        })
                                                    );
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        totalHead:
                                                            calculateTotalHead(
                                                                newValue,
                                                                headCalculation.risingMainLength,
                                                                headCalculation.risingMainSize,
                                                                headCalculation.risingMainType,
                                                                formData.flow // Pass the current flow value
                                                            ),
                                                    }));
                                                }}
                                                disabled={loading}
                                                type="number"
                                                InputProps={{
                                                    inputProps: { min: 0 },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Rising Main Length (m)"
                                                value={
                                                    headCalculation.risingMainLength
                                                }
                                                onChange={(e) => {
                                                    const newValue =
                                                        e.target.value;
                                                    setHeadCalculation(
                                                        (prev) => ({
                                                            ...prev,
                                                            risingMainLength:
                                                                newValue,
                                                        })
                                                    );
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        totalHead:
                                                            calculateTotalHead(
                                                                headCalculation.staticHead,
                                                                newValue,
                                                                headCalculation.risingMainSize,
                                                                headCalculation.risingMainType,
                                                                formData.flow
                                                            ),
                                                    }));
                                                }}
                                                disabled={loading}
                                                type="number"
                                                InputProps={{
                                                    inputProps: { min: 0 },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>
                                                    Rising Main Size (mm)
                                                </InputLabel>
                                                <Select
                                                    value={
                                                        headCalculation.risingMainSize
                                                    }
                                                    onChange={(e) => {
                                                        const newValue =
                                                            e.target.value;
                                                        setHeadCalculation(
                                                            (prev) => ({
                                                                ...prev,
                                                                risingMainSize:
                                                                    newValue,
                                                            })
                                                        );
                                                        // Update pipeWorkSize to match risingMainSize
                                                        setFormData((prev) => ({
                                                            ...prev,
                                                            pipeWorkSize:
                                                                newValue,
                                                            totalHead:
                                                                calculateTotalHead(
                                                                    headCalculation.staticHead,
                                                                    headCalculation.risingMainLength,
                                                                    newValue,
                                                                    headCalculation.risingMainType,
                                                                    formData.flow
                                                                ),
                                                        }));
                                                    }}
                                                    label="Rising Main Size (mm)"
                                                    disabled={loading}
                                                >
                                                    {pipeWorkOptions.sizes.map(
                                                        (size) => (
                                                            <MenuItem
                                                                key={size}
                                                                value={size}
                                                            >
                                                                {size}mm
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>
                                                    Rising Main Type
                                                </InputLabel>
                                                <Select
                                                    value={
                                                        headCalculation.risingMainType
                                                    }
                                                    onChange={(e) => {
                                                        const newValue =
                                                            e.target.value;
                                                        setHeadCalculation(
                                                            (prev) => ({
                                                                ...prev,
                                                                risingMainType:
                                                                    newValue,
                                                            })
                                                        );
                                                        // Update pipeWorkMaterial based on risingMainType
                                                        const materialMapping =
                                                            {
                                                                150: "PVC",
                                                                140: "Polyethylene",
                                                            };
                                                        setFormData((prev) => ({
                                                            ...prev,
                                                            pipeWorkMaterial:
                                                                materialMapping[
                                                                    newValue
                                                                ] || "",
                                                            totalHead:
                                                                calculateTotalHead(
                                                                    headCalculation.staticHead,
                                                                    headCalculation.risingMainLength,
                                                                    headCalculation.risingMainSize,
                                                                    newValue,
                                                                    formData.flow
                                                                ),
                                                        }));
                                                    }}
                                                    label="Rising Main Type"
                                                    disabled={loading}
                                                >
                                                    <MenuItem value="150">
                                                        PVC
                                                    </MenuItem>
                                                    <MenuItem value="140">
                                                        Polyethylene
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Pump Model</InputLabel>
                                        <Select
                                            name="pumpModel"
                                            value={formData.pumpModel || ""}
                                            onChange={handleChange}
                                            label="Pump Model"
                                            disabled={loading}
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {pumpOptions.supplierModels.map(
                                                (pump) => (
                                                    <MenuItem
                                                        key={pump.model}
                                                        value={pump.model}
                                                        // Highlight if this is the currently selected model
                                                        sx={{
                                                            backgroundColor:
                                                                formData.pumpModel ===
                                                                pump.model
                                                                    ? "rgba(25, 118, 210, 0.08)"
                                                                    : "transparent",
                                                        }}
                                                    >
                                                        {`${pump.model} - ${pump.kw}kW ${pump.voltage}V - ${pump.impellerType}`}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <PumpRecommendations />
                                </Grid>
                                {pumpPerformanceStatus.loading && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            mt: 2,
                                        }}
                                    >
                                        <CircularProgress
                                            size={24}
                                            sx={{ mr: 1 }}
                                        />
                                        <Typography variant="body2">
                                            Loading pump performance data...
                                        </Typography>
                                    </Box>
                                )}

                                {pumpPerformanceStatus.error && (
                                    <Alert severity="warning" sx={{ mt: 2 }}>
                                        {pumpPerformanceStatus.errorMessage}
                                    </Alert>
                                )}
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper
                            sx={{ mt: 3, p: 3, bgcolor: "white" }}
                            ref={chartContainerRef}
                        >
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                Flow vs. Total Head Chart
                            </Typography>
                            <ResponsiveContainer width="100%" height={400}>
                                {formData.pumpModel &&
                                    pumpPerformanceStatus.error && (
                                        <text
                                            x="50%"
                                            y="50%"
                                            textAnchor="middle"
                                            dominantBaseline="middle"
                                            style={{
                                                fill: "#f44336",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            No performance data available for
                                            this pump model
                                        </text>
                                    )}
                                <LineChart
                                    data={combinedChartData}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="flow"
                                        domain={[
                                            0,
                                            (dataMax) =>
                                                Math.ceil(dataMax * 1.1),
                                        ]}
                                        type="number"
                                        label={{
                                            value: "Flow (l/s)",
                                            position: "insideBottomRight",
                                            offset: -5,
                                        }}
                                    />
                                    <YAxis
                                        type="number"
                                        domain={[
                                            0,
                                            (dataMax) =>
                                                Math.ceil(dataMax * 1.1),
                                        ]}
                                        label={{
                                            value: "Head (m)",
                                            angle: -90,
                                            position: "insideLeft",
                                        }}
                                    />

                                    <Tooltip
                                        content={({
                                            active,
                                            payload,
                                            label,
                                        }) => {
                                            if (
                                                active &&
                                                payload &&
                                                payload.length
                                            ) {
                                                return (
                                                    <div
                                                        style={{
                                                            backgroundColor:
                                                                "white",
                                                            padding: "10px",
                                                            border: "1px solid #ccc",
                                                            borderRadius: "5px",
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            Flow:{" "}
                                                            {label.toFixed(2)}{" "}
                                                            l/s
                                                        </p>
                                                        {payload.map(
                                                            (entry, index) => {
                                                                let lineName =
                                                                    entry.name;
                                                                let value =
                                                                    entry.value;

                                                                // Skip null values (for missing data points)
                                                                if (
                                                                    value ===
                                                                    null
                                                                )
                                                                    return null;

                                                                // Format line names for pump models
                                                                if (
                                                                    lineName.startsWith(
                                                                        "pump_"
                                                                    )
                                                                ) {
                                                                    lineName =
                                                                        lineName.replace(
                                                                            "pump_",
                                                                            ""
                                                                        );
                                                                }

                                                                return (
                                                                    <p
                                                                        key={`item-${index}`}
                                                                        style={{
                                                                            margin: 0,
                                                                            color: entry.color,
                                                                            display:
                                                                                "flex",
                                                                            justifyContent:
                                                                                "space-between",
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                marginRight:
                                                                                    "10px",
                                                                            }}
                                                                        >
                                                                            {
                                                                                lineName
                                                                            }
                                                                            :
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                fontWeight:
                                                                                    "bold",
                                                                            }}
                                                                        >
                                                                            {value.toFixed(
                                                                                2
                                                                            )}{" "}
                                                                            m
                                                                        </span>
                                                                    </p>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                );
                                            }
                                            return null;
                                        }}
                                    />

                                    <Legend />

                                    {/* System Curve */}
                                    {headCalculation.calculateHead && (
                                        <Line
                                            type="monotone"
                                            dataKey="systemHead"
                                            name="System Curve"
                                            stroke="#000000"
                                            strokeWidth={2}
                                            dot={{ r: 3 }} // Add dots with radius 3
                                            activeDot={{ r: 5 }} // Make active dots slightly larger
                                            connectNulls={true}
                                        />
                                    )}

                                    {/* Primary selected pump performance line */}
                                    {pumpPerformanceCurve.length > 0 && (
                                        <Line
                                            type="monotone"
                                            dataKey="head"
                                            name={
                                                formData.pumpModel
                                                    ? `${formData.pumpModel} Performance`
                                                    : "Pump Performance"
                                            }
                                            stroke="#1976d2"
                                            strokeWidth={2.5}
                                            dot={{ r: 3 }} // Add dots with radius 3
                                            activeDot={{ r: 5 }} // Make active dots slightly larger
                                            connectNulls={true}
                                        />
                                    )}

                                    {/* Recommended pump performance lines */}
                                    {recommendedPumps
                                        .slice(0, pumpDisplayLimit)
                                        .map((pump, index) => {
                                            // Skip the currently selected pump as it's already shown with the primary line
                                            if (
                                                pump.supplierModel ===
                                                formData.pumpModel
                                            )
                                                return null;

                                            // Generate a color based on index (to make each line visually distinct)
                                            const colorOptions = [
                                                "#8884d8",
                                                "#82ca9d",
                                                "#ffc658",
                                                "#ff7300",
                                                "#0088FE",
                                                "#00C49F",
                                                "#FFBB28",
                                            ];
                                            const color =
                                                colorOptions[
                                                    index % colorOptions.length
                                                ];

                                            return (
                                                <Line
                                                    key={pump.supplierModel}
                                                    type="monotone"
                                                    dataKey={`pump_${pump.supplierModel}`}
                                                    name={`${pump.supplierModel}`}
                                                    stroke={color}
                                                    strokeWidth={1.5}
                                                    strokeDasharray="5 5" // Make these dashed to distinguish from selected pump
                                                    dot={{ r: 2 }} // Slightly smaller dots for recommended pumps
                                                    activeDot={{ r: 4 }} // Slightly smaller active dots for recommended pumps
                                                    connectNulls={true}
                                                />
                                            );
                                        })}

                                    {/* Manual Operating Point - when not in calculation mode */}
                                    {!headCalculation.calculateHead &&
                                        formData.flow &&
                                        formData.totalHead && (
                                            <Line
                                                type="monotone"
                                                dataKey="manualHead"
                                                name="Operating Point"
                                                stroke="#ff0000"
                                                strokeWidth={0}
                                                dot={{
                                                    r: 6,
                                                    stroke: "#ff0000",
                                                    strokeWidth: 2,
                                                    fill: "#ff0000",
                                                }}
                                            />
                                        )}

                                    {/* Calculated Operating Point - when in system calculation mode */}
                                    {headCalculation.calculateHead &&
                                        formData.flow &&
                                        formData.totalHead && (
                                            <Line
                                                type="monotone"
                                                dataKey="operatingPoint"
                                                name="Specified Point"
                                                stroke="#ff0000"
                                                strokeWidth={0}
                                                dot={{
                                                    r: 6,
                                                    stroke: "#ff0000",
                                                    strokeWidth: 2,
                                                    fill: "#ff0000",
                                                }}
                                            />
                                        )}

                                    {/* Intersection Point between pump and system curves */}
                                    {/* {headCalculation.calculateHead &&
                                        pumpPerformanceCurve.length > 0 && (
                                            <Line
                                                type="monotone"
                                                dataKey="intersectionPoint"
                                                name="System Operating Point"
                                                stroke="#009900"
                                                strokeWidth={0}
                                                dot={{
                                                    r: 6,
                                                    stroke: "#009900",
                                                    strokeWidth: 2,
                                                    fill: "#009900",
                                                }}
                                                activeDot={{ r: 8 }}
                                            />
                                        )} */}
                                </LineChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Grid>
                    {/* Pipe Work Section */}
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                                p: 3,
                                backgroundColor: "#fafafa",
                                boxShadow: 2,
                                borderRadius: 2,
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 3,
                                    pb: 1,
                                }}
                            >
                                Pipe Work Configuration
                            </Typography>
                            <Grid container spacing={2}>
                                {!headCalculation.calculateHead ? (
                                    // Show normal pipe configuration when Calculate Head is unchecked
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <FormControl fullWidth>
                                                <InputLabel>
                                                    Pipe Size
                                                </InputLabel>
                                                <Select
                                                    name="pipeWorkSize"
                                                    value={
                                                        formData.pipeWorkSize
                                                    }
                                                    onChange={handleChange}
                                                    label="Pipe Size"
                                                    disabled={loading}
                                                >
                                                    {pipeWorkOptions.sizes.map(
                                                        (size) => (
                                                            <MenuItem
                                                                key={size}
                                                                value={size}
                                                            >
                                                                {size}mm
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControl fullWidth>
                                                <InputLabel>
                                                    Pipe Material
                                                </InputLabel>
                                                <Select
                                                    name="pipeWorkMaterial"
                                                    value={
                                                        formData.pipeWorkMaterial
                                                    }
                                                    onChange={handleChange}
                                                    label="Pipe Material"
                                                    disabled={
                                                        loading ||
                                                        !formData.pipeWorkSize
                                                    }
                                                >
                                                    {pipeWorkOptions.pipeMaterials.map(
                                                        (material) => (
                                                            <MenuItem
                                                                key={material}
                                                                value={material}
                                                            >
                                                                {material}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </>
                                ) : (
                                    // Show message when Calculate Head is checked
                                    <Grid item xs={12} md={8}>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            Using Rising Main Size (
                                            {headCalculation.risingMainSize ||
                                                "not set"}
                                            ) mm and Rising Main Type (
                                            {headCalculation.risingMainType ===
                                            "150"
                                                ? "PVC"
                                                : headCalculation.risingMainType ===
                                                  "140"
                                                ? "Polyethylene"
                                                : "not set"}
                                            ) values from the Pump Configuration
                                            section.
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel>Valve Material</InputLabel>
                                        <Select
                                            name="valveMaterial"
                                            value={formData.valveMaterial}
                                            onChange={handleChange}
                                            label="Valve Material"
                                            disabled={
                                                loading ||
                                                (!headCalculation.calculateHead &&
                                                    (!formData.pipeWorkSize ||
                                                        !formData.pipeWorkMaterial)) ||
                                                (headCalculation.calculateHead &&
                                                    (!headCalculation.risingMainSize ||
                                                        !headCalculation.risingMainType))
                                            }
                                        >
                                            {pipeWorkOptions.valveMaterials.map(
                                                (material) => (
                                                    <MenuItem
                                                        key={material}
                                                        value={material}
                                                    >
                                                        {material}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* Additional Equipment Section */}
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                                p: 3,
                                backgroundColor: "#fafafa",
                                boxShadow: 2,
                                borderRadius: 2,
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 3,

                                    pb: 1,
                                }}
                            >
                                Additional Settings
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Mixer Type</InputLabel>
                                        <Select
                                            name="mixerType"
                                            value={formData.mixerType}
                                            onChange={handleChange}
                                            label="Mixer Type"
                                            disabled={loading}
                                        >
                                            <MenuItem value="Not Required">
                                                Not Required
                                            </MenuItem>{" "}
                                            {mixerOptions.mixerTypes.map(
                                                (type) => (
                                                    <MenuItem
                                                        key={type}
                                                        value={type}
                                                    >
                                                        {type}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Level Control</InputLabel>
                                        <Select
                                            name="levelControlDescription"
                                            value={
                                                formData.levelControlDescription
                                            }
                                            onChange={handleChange}
                                            label="Level Control"
                                            disabled={loading}
                                        >
                                            {levelControlOptions.descriptions.map(
                                                (description) => (
                                                    <MenuItem
                                                        key={description}
                                                        value={description}
                                                    >
                                                        {description}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Control Type</InputLabel>
                                        <Select
                                            name="controlType"
                                            value={formData.controlType}
                                            onChange={handleChange}
                                            label="Control Type"
                                            disabled={loading}
                                        >
                                            <MenuItem value="Soft Starter">
                                                Soft Starter
                                            </MenuItem>
                                            <MenuItem value="Direct Online">
                                                Direct Online
                                            </MenuItem>
                                            <MenuItem value="VFD">VFD</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>
                                            Destination City
                                        </InputLabel>
                                        <Select
                                            name="city"
                                            value={formData.city}
                                            onChange={handleChange}
                                            label="Delivery City"
                                            disabled={loading}
                                        >
                                            {freightOptions.cities.map(
                                                (city) => (
                                                    <MenuItem
                                                        key={city}
                                                        value={city}
                                                    >
                                                        {city}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Box
                    sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}
                >
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        Calculate Quote
                    </Button>
                </Box>
                {quoteBreakdown.totalPrice > 0 && (
                    <Paper sx={{ mt: 3, p: 2, bgcolor: "grey.50" }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Quote Summary
                        </Typography>

                        {shouldCheckController && controllerError && (
                            <Alert severity="warning" sx={{ mb: 3 }}>
                                No matching controller found in the database for
                                your selected pump and control type. The quote
                                is calculated without a controller price. Please
                                adjust your pump or control type selection or
                                contact support for assistance.
                            </Alert>
                        )}

                        <Grid container spacing={2}>
                            {/* Dynamically render price fields */}
                            {Object.entries(quoteBreakdown).map(
                                ([key, value]) =>
                                    key !== "totalPrice" && (
                                        <Grid item xs={12} md={4} key={key}>
                                            <Typography variant="subtitle1">
                                                {formatPriceLabel(key)}:
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography variant="h6">
                                                    ${value.toFixed(2)}
                                                </Typography>
                                                {key === "controllerPrice" &&
                                                    shouldCheckController &&
                                                    controllerError &&
                                                    value === 0 && (
                                                        <Typography
                                                            variant="caption"
                                                            color="error"
                                                            sx={{ ml: 1 }}
                                                        >
                                                            No match found
                                                        </Typography>
                                                    )}
                                            </Box>
                                        </Grid>
                                    )
                            )}

                            {/* Total Price */}
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                                    Total Price:
                                </Typography>
                                <Typography variant="h4" color="primary">
                                    ${quoteBreakdown.totalPrice.toFixed(2)}
                                </Typography>
                                {shouldCheckController && controllerError && (
                                    <Typography variant="body2" color="error">
                                        *Price does not include controller
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                )}
                {renderPdfGenerator()}
            </Paper>
            <BackToDashboardButton />

            <Snackbar
                open={notification.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={notification.severity}
                    sx={{ width: "100%" }}
                >
                    {notification.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ProductQuoteCalculator;
