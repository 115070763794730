"use client";
import { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Typography,
    Box,
} from "@mui/material";

const ChecklistDialog = ({ open, onClose, setAllChecked, productName }) => {
    const [checklist, setChecklist] = useState({
        // Pre-Installation
        sitePreparation: false,
        productInspection: false,
        complianceCheck: false,
        // Installation
        basePreparation: false,
        positioning: false,
        connections: false,
        backfilling: false,
        // Post-Installation
        inspection: false,
        testing: false,
        siteRestoration: false,
        // Documentation
        recordDetails: false,
        photos: false,
        finalSubmission: false,
    });

    const handleChange = (event) => {
        const { name, checked } = event.target;
        setChecklist((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const isAllChecked = Object.values(checklist).every(
        (value) => value === true
    );

    const handleClose = () => {
        setAllChecked(isAllChecked);
        onClose();
    };

    // Format product name for display
    const getFormattedProductName = () => {
        if (!productName || productName === "None") return "Product";

        // Replace underscores with spaces
        return productName.replace(/_/g, " ");
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
                {getFormattedProductName()} Installation Checklist
            </DialogTitle>
            <Typography
                variant="body2"
                gutterBottom
                sx={{ fontStyle: "italic", ml: 2, textAlign: "center" }}
            >
                (Include photos for each step and upload them with the completed
                checklist.)
            </Typography>
            <DialogContent sx={{ ml: 10 }}>
                {/* Pre-Installation Section */}
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Pre-Installation
                    </Typography>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checklist.sitePreparation}
                                    onChange={handleChange}
                                    name="sitePreparation"
                                />
                            }
                            label="Site Preparation"
                        />
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ ml: 3 }}
                        >
                            • Confirm site conditions match design
                            specifications
                            <br />• Verify excavation dimensions are correct
                            <br />• Ensure access for delivery and equipment
                            <br />
                            <Typography sx={{ fontStyle: "italic" }}>
                                (Upload photo of prepared site and excavation.)
                            </Typography>
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checklist.productInspection}
                                    onChange={handleChange}
                                    name="productInspection"
                                />
                            }
                            label="Product Inspection"
                        />
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ ml: 3 }}
                        >
                            • Check for any damage during transit
                            <br />• Verify all components are included (as per
                            the packing list)
                            <br />• Confirm product size and specifications
                            match the order
                            <br />
                            <Typography sx={{ fontStyle: "italic" }}>
                                (Upload photo of product and components.)
                            </Typography>
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checklist.complianceCheck}
                                    onChange={handleChange}
                                    name="complianceCheck"
                                />
                            }
                            label="Compliance Check"
                        />
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ ml: 3 }}
                        >
                            • Review relevant permits and approvals
                            <br />• Ensure installation meets local standards
                            and regulations
                            <br />
                            <Typography sx={{ fontStyle: "italic" }}>
                                (Upload relevant permits or documents.)
                            </Typography>
                        </Typography>
                    </FormGroup>
                </Box>

                {/* Installation Section */}
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Installation
                    </Typography>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checklist.basePreparation}
                                    onChange={handleChange}
                                    name="basePreparation"
                                />
                            }
                            label="Base Preparation"
                        />
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ ml: 3 }}
                        >
                            • Ensure the base is level and compacted to design
                            specifications
                            <br />• Lay a geotextile or other required material
                            (if specified)
                            <br />
                            <Typography sx={{ fontStyle: "italic" }}>
                                (Upload photo of prepared base.)
                            </Typography>
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checklist.positioning}
                                    onChange={handleChange}
                                    name="positioning"
                                />
                            }
                            label="Positioning"
                        />
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ ml: 3 }}
                        >
                            • Place the {getFormattedProductName()} unit in the
                            prepared excavation
                            <br />• Align inlet and outlet points with the
                            drainage design
                            <br />
                            <Typography sx={{ fontStyle: "italic" }}>
                                (Upload photo of positioned{" "}
                                {getFormattedProductName()} unit.)
                            </Typography>
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checklist.connections}
                                    onChange={handleChange}
                                    name="connections"
                                />
                            }
                            label="Connections"
                        />
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ ml: 3 }}
                        >
                            • Securely connect inlet and outlet pipes
                            <br />• Seal joints to prevent leakage using
                            approved materials
                            <br />
                            <Typography sx={{ fontStyle: "italic" }}>
                                (Upload photo of connected pipes and sealed
                                joints.)
                            </Typography>
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checklist.backfilling}
                                    onChange={handleChange}
                                    name="backfilling"
                                />
                            }
                            label="Backfilling"
                        />
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ ml: 3 }}
                        >
                            • Backfill evenly around the unit, ensuring proper
                            compaction
                            <br />• Use specified material for backfill (e.g.,
                            sand or gravel)
                            <br />
                            <Typography sx={{ fontStyle: "italic" }}>
                                (Upload photo of backfilled area around the
                                unit.)
                            </Typography>
                        </Typography>
                    </FormGroup>
                </Box>

                {/* Post-Installation Section */}
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Post-Installation
                    </Typography>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checklist.inspection}
                                    onChange={handleChange}
                                    name="inspection"
                                />
                            }
                            label="Inspection"
                        />
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ ml: 3 }}
                        >
                            • Confirm the unit is level and connections are
                            secure
                            <br />• Check for any visible signs of damage after
                            installation
                            <br />
                            <Typography sx={{ fontStyle: "italic" }}>
                                (Upload photo of final inspection showing level
                                and connections.)
                            </Typography>
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checklist.testing}
                                    onChange={handleChange}
                                    name="testing"
                                />
                            }
                            label="Testing"
                        />
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ ml: 3 }}
                        >
                            • Perform a flow test to verify system functionality
                            <br />• Inspect for leaks at all connections
                            <br />
                            <Typography sx={{ fontStyle: "italic" }}>
                                (Upload photo or video of flow test results.)
                            </Typography>
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checklist.siteRestoration}
                                    onChange={handleChange}
                                    name="siteRestoration"
                                />
                            }
                            label="Site Restoration"
                        />
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ ml: 3 }}
                        >
                            • Restore the site to its original condition or as
                            per project requirements
                            <br />• Ensure surrounding area drains effectively
                            to the {getFormattedProductName()}
                            <br />
                            <Typography sx={{ fontStyle: "italic" }}>
                                (Upload photo of restored site and drainage
                                setup.)
                            </Typography>
                        </Typography>
                    </FormGroup>
                </Box>

                {/* Documentation Section */}
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Documentation
                    </Typography>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checklist.recordDetails}
                                    onChange={handleChange}
                                    name="recordDetails"
                                />
                            }
                            label="Record Details"
                        />
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ ml: 3 }}
                        >
                            • Installation date:
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checklist.photos}
                                    onChange={handleChange}
                                    name="photos"
                                />
                            }
                            label="Photos"
                        />
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ ml: 3 }}
                        >
                            • All steps documented and uploaded
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checklist.finalSubmission}
                                    onChange={handleChange}
                                    name="finalSubmission"
                                />
                            }
                            label="Final Submission"
                        />
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ ml: 3 }}
                        >
                            • Provide the owner with the operation and
                            maintenance manual
                            <br />• Register the warranty with owner details
                        </Typography>
                    </FormGroup>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChecklistDialog;
