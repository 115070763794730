import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Box,
    Grid,
    Snackbar,
    Alert,
    CircularProgress,
    InputAdornment,
    Tooltip,
    Divider,
} from "@mui/material";
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Search as SearchIcon,
    FilterAlt as FilterIcon,
    Clear as ClearIcon,
} from "@mui/icons-material";
import apiClient from "../../Helpers/apiClient";

function FreightPriceTable() {
    const [freightPrices, setFreightPrices] = useState([]);
    const [filteredFreightPrices, setFilteredFreightPrices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedFreight, setSelectedFreight] = useState(null);
    const [formData, setFormData] = useState({
        id: 0,
        city: "",
        price: null,
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });

    // Sorting states
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("id");

    // Filtering states
    const [filterText, setFilterText] = useState("");
    const [filterField, setFilterField] = useState("all");

    const navigate = useNavigate();

    useEffect(() => {
        fetchFreightPrices();
    }, []);

    // Apply filters whenever freightPrices or filter settings change
    useEffect(() => {
        applyFilters();
    }, [freightPrices, filterText, filterField]);

    const fetchFreightPrices = async () => {
        setLoading(true);
        try {
            const response = await apiClient.get(
                "/api/PumpStationPriceTables/FreightPrices"
            );

            if (Array.isArray(response.data)) {
                setFreightPrices(response.data);
            } else {
                console.warn("Response is not an array:", response.data);
                setFreightPrices([]);
            }

            setError(null);
        } catch (err) {
            console.error("Error fetching freight prices:", err);
            setError(`Failed to load data: ${err.message}`);
            setFreightPrices([]);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenDialog = (freight = null) => {
        if (freight) {
            setFormData({
                id: freight.id || freight.Id || 0,
                city: freight.city || freight.City || "",
                price: freight.price || freight.Price || null,
            });
            setSelectedFreight(freight);
        } else {
            // Reset form for new entry
            setFormData({
                id: 0,
                city: "",
                price: null,
            });
            setSelectedFreight(null);
        }
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDeleteDialog = (freight) => {
        setSelectedFreight(freight);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedFreight(null);
    };

    const handleFormChange = (e) => {
        const { name, value, type } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]:
                type === "number"
                    ? value === ""
                        ? null
                        : parseFloat(value)
                    : value,
        }));
    };

    const handleSubmit = async () => {
        try {
            // Convert to PascalCase to match C# model
            const payload = {
                Id: formData.id,
                City: formData.city,
                Price: formData.price,
                Quotations: [], // Always include this for navigation property
            };

            let response;
            if (selectedFreight) {
                // Update existing record
                response = await apiClient.put(
                    `/api/PumpStationPriceTables/FreightPrices/${payload.Id}`,
                    payload
                );
                setSnackbar({
                    open: true,
                    message: "Freight price updated successfully!",
                    severity: "success",
                });
            } else {
                // Create new record
                response = await apiClient.post(
                    "/api/PumpStationPriceTables/FreightPrices",
                    payload
                );
                setSnackbar({
                    open: true,
                    message: "Freight price created successfully!",
                    severity: "success",
                });
            }

            handleCloseDialog();
            fetchFreightPrices();
        } catch (err) {
            console.error("Error saving freight price:", err);
            setSnackbar({
                open: true,
                message: `Error: ${
                    err.response?.data?.message ||
                    "Failed to save. Please try again."
                }`,
                severity: "error",
            });
        }
    };

    const handleDelete = async () => {
        try {
            await apiClient.delete(
                `/api/PumpStationPriceTables/FreightPrices/${
                    selectedFreight.id || selectedFreight.Id
                }`
            );
            setSnackbar({
                open: true,
                message: "Freight price deleted successfully!",
                severity: "success",
            });
            handleCloseDeleteDialog();
            fetchFreightPrices();
        } catch (err) {
            console.error("Error deleting freight price:", err);
            setSnackbar({
                open: true,
                message: `Error: ${
                    err.response?.data?.message ||
                    "Failed to delete. Please try again."
                }`,
                severity: "error",
            });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    // Sorting functionality
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const getSortedData = (data) => {
        if (!data.length) return [];

        return [...data].sort((a, b) => {
            // Mapping between component state and backend properties
            const propertyMap = {
                id: ["id", "Id"],
                city: ["city", "City"],
                price: ["price", "Price"],
            };

            // Find the current property's possible keys
            const currentProp = propertyMap[orderBy] || [orderBy, orderBy];

            // Get values, prioritizing camelCase then PascalCase
            const getPropertyValue = (obj) => {
                for (let key of currentProp) {
                    if (obj[key] !== undefined) return obj[key];
                }
                return null;
            };

            const valueA = getPropertyValue(a);
            const valueB = getPropertyValue(b);

            // Special handling for ID column
            if (orderBy === "id") {
                const idA = parseInt(valueA || 0);
                const idB = parseInt(valueB || 0);
                return order === "asc" ? idA - idB : idB - idA;
            }

            // Check if either value is 'N/A' or null
            const isNAValue = (val) => val === null || val === "N/A";

            // If one of the values is 'N/A', put it at the bottom
            if (isNAValue(valueA) && !isNAValue(valueB)) return 1;
            if (!isNAValue(valueA) && isNAValue(valueB)) return -1;
            if (isNAValue(valueA) && isNAValue(valueB)) return 0;

            // Handle numeric sorting
            if (typeof valueA === "number" && typeof valueB === "number") {
                return order === "asc"
                    ? (valueA || 0) - (valueB || 0)
                    : (valueB || 0) - (valueA || 0);
            }

            // Handle string sorting
            if (typeof valueA === "string" && typeof valueB === "string") {
                return order === "asc"
                    ? valueA.localeCompare(valueB)
                    : valueB.localeCompare(valueA);
            }

            // Fallback for mixed types
            return 0;
        });
    };

    // Filtering functionality
    const handleFilterChange = (event) => {
        setFilterText(event.target.value);
    };

    const handleFilterFieldChange = (field) => {
        setFilterField(field);
    };

    const clearFilter = () => {
        setFilterText("");
        setFilterField("all");
    };

    const applyFilters = () => {
        if (!filterText.trim()) {
            setFilteredFreightPrices(freightPrices);
            return;
        }

        const searchTerm = filterText.toLowerCase().trim();

        const filtered = freightPrices.filter((freight) => {
            // Comprehensive filter across all fields or specific field
            if (filterField === "all") {
                const fieldsToSearch = [
                    freight.id || freight.Id,
                    freight.city || freight.City,
                    freight.price || freight.Price,
                ];

                return fieldsToSearch.some(
                    (field) =>
                        field !== null &&
                        field !== undefined &&
                        String(field).toLowerCase().includes(searchTerm)
                );
            }

            // Specific field filtering
            let fieldValue;
            switch (filterField) {
                case "id":
                    fieldValue = freight.id || freight.Id;
                    break;
                case "city":
                    fieldValue = freight.city || freight.City;
                    break;
                case "price":
                    fieldValue = freight.price || freight.Price;
                    break;
                default:
                    return false;
            }

            return (
                fieldValue !== null &&
                fieldValue !== undefined &&
                String(fieldValue).toLowerCase().includes(searchTerm)
            );
        });

        setFilteredFreightPrices(filtered);
    };

    // Prepare data for rendering
    const displayData = getSortedData(filteredFreightPrices);

    return (
        <Box
            sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                padding: 4,
                paddingTop: 2,
                boxSizing: "border-box",
                overflow: "hidden",
            }}
        >
            <Typography variant="h4" gutterBottom align="center">
                Freight Price Table
            </Typography>

            <Paper
                sx={{
                    width: "100%",
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    mb: 2,
                }}
            >
                {/* Filter and Add Button Section */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: 2,
                        alignItems: "center",
                    }}
                >
                    <TextField
                        variant="outlined"
                        placeholder="Search across all fields"
                        size="small"
                        fullWidth
                        value={filterText}
                        onChange={handleFilterChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: filterText ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        size="small"
                                        onClick={clearFilter}
                                        edge="end"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ) : null,
                        }}
                        sx={{ mr: 2 }}
                    />
                    <Tooltip title="Filter by field">
                        <Box>
                            <IconButton
                                color={
                                    filterField !== "all"
                                        ? "primary"
                                        : "default"
                                }
                                aria-label="Filter options"
                                onClick={(e) => {
                                    e.currentTarget.nextElementSibling.style.display =
                                        e.currentTarget.nextElementSibling.style
                                            .display === "none"
                                            ? "flex"
                                            : "none";
                                }}
                            >
                                <FilterIcon />
                            </IconButton>
                            <Box
                                sx={{
                                    position: "absolute",
                                    zIndex: 10,
                                    bgcolor: "background.paper",
                                    boxShadow: 3,
                                    borderRadius: 1,
                                    p: 1,
                                    display: "none",
                                    flexDirection: "column",
                                    gap: 0.5,
                                }}
                            >
                                {[
                                    { id: "all", label: "All Fields" },
                                    { id: "id", label: "ID" },
                                    { id: "city", label: "City" },
                                    { id: "price", label: "Price" },
                                ].map((option) => (
                                    <Button
                                        key={option.id}
                                        size="small"
                                        variant={
                                            filterField === option.id
                                                ? "contained"
                                                : "text"
                                        }
                                        onClick={() => {
                                            handleFilterFieldChange(option.id);
                                            // Hide the filter dropdown
                                            document.querySelector(
                                                '[aria-label="Filter options"]'
                                            ).nextElementSibling.style.display =
                                                "none";
                                        }}
                                    >
                                        {option.label}
                                    </Button>
                                ))}
                            </Box>
                        </Box>
                    </Tooltip>
                    {filterField !== "all" && (
                        <Typography variant="caption" sx={{ ml: 1 }}>
                            Filtering by:{" "}
                            {filterField === "id"
                                ? "ID"
                                : filterField === "city"
                                ? "City"
                                : filterField === "price"
                                ? "Price"
                                : ""}
                        </Typography>
                    )}
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => handleOpenDialog()}
                    >
                        Add New
                    </Button>
                </Box>

                <Divider />

                {/* Table Container with Vertical and Horizontal Scroll */}
                <TableContainer
                    sx={{
                        flexGrow: 1,
                        overflowX: "auto",
                        overflowY: "scroll",
                        maxHeight: "calc(100vh - 300px)",
                    }}
                >
                    <Table
                        stickyHeader
                        sx={{
                            minWidth: 800,
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                {[
                                    { id: "id", label: "ID" },
                                    { id: "city", label: "City" },
                                    { id: "price", label: "Price" },
                                    { id: "actions", label: "Actions" },
                                ].map((column) =>
                                    column.id !== "actions" ? (
                                        <TableCell key={column.id}>
                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={
                                                    orderBy === column.id
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort(column.id)
                                                }
                                            >
                                                <strong>{column.label}</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                    ) : (
                                        <TableCell
                                            key={column.id}
                                            align="center"
                                        >
                                            <strong>{column.label}</strong>
                                        </TableCell>
                                    )
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {displayData.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        {filterText
                                            ? "No matches found. Try a different search."
                                            : "No data available. Add your first freight price."}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                displayData.map((freight) => (
                                    <TableRow
                                        key={freight.id || freight.Id}
                                        hover
                                    >
                                        <TableCell>
                                            FP-
                                            {(freight.id || freight.Id)
                                                .toString()
                                                .padStart(3, "0")}
                                        </TableCell>
                                        <TableCell>
                                            {freight.city ||
                                                freight.City ||
                                                "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            $
                                            {Number(
                                                freight.price ||
                                                    freight.Price ||
                                                    0
                                            ).toFixed(2)}
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton
                                                color="primary"
                                                onClick={() =>
                                                    handleOpenDialog(freight)
                                                }
                                                aria-label="edit"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                color="error"
                                                onClick={() =>
                                                    handleOpenDeleteDialog(
                                                        freight
                                                    )
                                                }
                                                aria-label="delete"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Summary and Pagination */}
                <Box
                    sx={{
                        p: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="body2" color="text.secondary">
                        {filterText
                            ? `Showing ${displayData.length} of ${freightPrices.length} items`
                            : `Total: ${freightPrices.length} items`}
                    </Typography>
                    {filterText && (
                        <Button
                            size="small"
                            startIcon={<ClearIcon />}
                            onClick={clearFilter}
                        >
                            Clear Filter
                        </Button>
                    )}
                </Box>
            </Paper>

            {/* Back to Main Tables Button */}
            <Box sx={{ mt: 2, textAlign: "center", flexShrink: 0 }}>
                <Button
                    variant="contained"
                    component={Link}
                    to="/pumpstation-price-tables"
                    sx={{ mt: 2 }}
                >
                    Back to Pump Station Tables
                </Button>
            </Box>

            {/* Create/Edit Dialog */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {selectedFreight
                        ? "Edit Freight Price"
                        : "Add New Freight Price"}
                </DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="City"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    required
                                    helperText="Enter city name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Price"
                                    name="price"
                                    type="number"
                                    inputProps={{
                                        step: "0.01",
                                        min: "0",
                                        max: "999999.9999",
                                    }}
                                    value={formData.price || ""}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    required
                                    helperText="Enter freight price"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                    >
                        {selectedFreight ? "Update" : "Save"}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this freight price? This
                        action cannot be undone.
                    </DialogContentText>
                    <DialogContentText sx={{ mt: 2, fontWeight: "bold" }}>
                        Details:
                        <br />
                        City:{" "}
                        {selectedFreight?.city ||
                            selectedFreight?.City ||
                            "N/A"}
                        <br />
                        Price: $
                        {Number(
                            selectedFreight?.price ||
                                selectedFreight?.Price ||
                                0
                        ).toFixed(2)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
                    <Button
                        onClick={handleDelete}
                        color="error"
                        variant="contained"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default FreightPriceTable;
