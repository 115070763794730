// routes/AppRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import SignupPage from "../Pages/signupPage";
import DashBoardPage from "../Pages/dashBoardPage";
import EmailConfirmationPage from "../Pages/emailConfirmationPage";
import CaseStudiesPage from "../Pages/caseStudiesPage/caseStudiesPage";
import { AuthProvider } from "../Helpers/auth-context";
import ProtectedRoute from "./ProtectedRoute";
import EmailVerificationPage from "../Pages/emailVerificationPage";
import PleaseCheckYourEmailPage from "../Pages/pleaseCheckYourEmailPage";
import ProductHomePage from "../Pages/productHomePage/productHomePage";
import ForgotPasswordPage from "../Pages/forgotPasswordPage";
import ResetPasswordPage from "../Pages/ResetPasswordPage";
import LoginPage from "../loginPage";
import FileStoragePage from "../Pages/fileStoragePage";
import ProjectsPage from "../Pages/projectsPage";
import ClientFormPage from "../Pages/warrantyQuotePages/clientFormPage";
import EditProjectPage from "../Pages/warrantyQuotePages/editProjectPage";
import WarrentyManagementPage from "../Pages/warrantyQuotePages/warrantyManagementPage";
import WarrentyDetailPage from "../Pages/warrantyQuotePages/warrentyDetailPage";
import { AutoLogoutProvider } from "../Helpers/AutoLogoutProvider";
import DesignToolPage from "../Pages/designToolPage/designToolPage";
import PumpStationForm from "../Pages/testPage";
import ProductQuoteCalculator from "../Pages/QuotePricePage/productQouteCalculator";
import AccessCoverPriceTable from "../Pages/QuotePricePage/AccessCoverPriceTable";
import PumpStationPriceTablesDashBoardPage from "../Pages/QuotePricePage/PumpStationPriceTablesDashBoardPage";
import ControllerPriceTable from "../Pages/QuotePricePage/ControllerPriceTable";
import FreightPriceTable from "../Pages/QuotePricePage/FreightPriceTable";
import LevelControlPriceTable from "../Pages/QuotePricePage/LevelControlPriceTable";
import MixerPriceTable from "../Pages/QuotePricePage/MixerPriceTable";
import PedestalPriceTable from "../Pages/QuotePricePage/PedestalPriceTable";
import PipeWorkPriceTable from "../Pages/QuotePricePage/PipeWorkPriceTable";
import PumpPriceTable from "../Pages/QuotePricePage/PumpPriceTable";
import TankPriceTable from "../Pages/QuotePricePage/TankPriceTable";
import ValvePitPriceTable from "../Pages/QuotePricePage/ValvePitPriceTable";
import XtrCalculatorPage from "../Pages/xtrCalculatorPage";
import GuideRailPriceTable from "../Pages/QuotePricePage/GuideRailPriceTable";
import DocumentManager from "../Pages/DocumentManagerPage/DocumentManager";

function AppRoutes() {
    return (
        <AuthProvider>
            <AutoLogoutProvider timeout={15 * 60 * 1000}>
                <Routes>
                    <Route path="/pdf-manager" element={<DocumentManager />} />
                    {/* pumpstationtables section */}
                    <Route
                        path="/pumpstation-price-tables"
                        element={<PumpStationPriceTablesDashBoardPage />}
                    />
                    <Route
                        path="/access-cover-price-table"
                        element={<AccessCoverPriceTable />}
                    />
                    <Route
                        path="/controller-price-table"
                        element={<ControllerPriceTable />}
                    />
                    <Route
                        path="/freight-price-table"
                        element={<FreightPriceTable />}
                    />
                    <Route
                        path="/level-control-price-table"
                        element={<LevelControlPriceTable />}
                    />
                    <Route
                        path="/mixer-price-table"
                        element={<MixerPriceTable />}
                    />
                    <Route
                        path="/pedestal-price-table"
                        element={<PedestalPriceTable />}
                    />
                    <Route
                        path="/pipe-work-price-table"
                        element={<PipeWorkPriceTable />}
                    />
                    <Route
                        path="/pump-price-table"
                        element={<PumpPriceTable />}
                    />
                    <Route
                        path="/tank-price-table"
                        element={<TankPriceTable />}
                    />{" "}
                    <Route
                        path="/guideRail-price-table"
                        element={<GuideRailPriceTable />}
                    />
                    <Route
                        path="/valve-pit-price-table"
                        element={<ValvePitPriceTable />}
                    />
                    {/* end of pumpstationtables section */}
                    <Route path="/tool" element={<DesignToolPage />} />
                    <Route path="/test" element={<PumpStationForm />} />
                    <Route
                        path="/quote-price"
                        element={
                            <ProtectedRoute>
                                <ProductQuoteCalculator />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/signup" element={<SignupPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute>
                                <DashBoardPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/case-studies" element={<CaseStudiesPage />} />
                    <Route
                        path="/forgot-password"
                        element={<ForgotPasswordPage />}
                    />
                    <Route
                        path="/reset-password"
                        element={<ResetPasswordPage />}
                    />
                    <Route path="/projects" element={<ProjectsPage />} />
                    <Route
                        path="/projects/:projectId/files"
                        element={<FileStoragePage />}
                    />
                    {/* Newly added routes */}
                    <Route path="/form/:token" element={<ClientFormPage />} />
                    <Route
                        path="/warrenty/edit/:id"
                        element={<EditProjectPage />}
                    />
                    <Route
                        path="/warrenty"
                        element={<WarrentyManagementPage />}
                    />
                    <Route
                        path="/warrenty/detail/:id"
                        element={<WarrentyDetailPage />}
                    />
                    {/* Newly added routes */}
                    <Route
                        path="/verify-email"
                        element={<EmailVerificationPage />}
                    />
                    <Route
                        path="/check-your-email"
                        element={<PleaseCheckYourEmailPage />}
                    />
                    <Route path="/xtr" element={<XtrCalculatorPage />} />
                    <Route
                        path="/confirmation-success"
                        element={<EmailConfirmationPage />}
                    />
                    <Route path="/" element={<ProductHomePage />} />
                    <Route path="*" element={<h1>404 Not Found</h1>} />
                </Routes>
            </AutoLogoutProvider>
        </AuthProvider>
    );
}

export default AppRoutes;
