import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
    Grid,
    Box,
    Typography,
    Button,
    Paper,
    Container,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Snackbar,
    Alert,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";
import apiClient from "../Helpers/apiClient";
import AuthContext from "../Helpers/auth-context";
import DashboardSideBar from "../Components/dashBoardSideBar";

const DashBoardPage = () => {
    const navigate = useNavigate();
    const { dispatch } = useContext(AuthContext);

    const [userData, setUserData] = useState({
        email: "",
        firstName: "",
        lastName: "",
    });

    // State to handle snackbar visibility
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    useEffect(() => {
        const authState = JSON.parse(localStorage.getItem("authState"));

        if (authState && authState.user) {
            const email = authState.user.email;
            const firstName = authState.user.firstName;
            const lastName = authState.user.lastName;
            setUserData({ email, firstName, lastName });
        }
    }, []);

    const handleLogout = async () => {
        try {
            await apiClient.post("/api/Account/logout");

            setSnackbarMessage("Logout successful! Redirecting to homepage...");
            setSeverity("success");
            setSnackbarOpen(true);

            setTimeout(() => {
                dispatch({ type: "LOGOUT" });
                navigate("/");
            }, 2000);
        } catch (error) {
            console.error("Logout failed", error);
            setSnackbarMessage("Logout failed. Please try again.");
            setSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <DashboardSideBar />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: "100vh",
                    overflow: "auto",
                    backgroundColor: "#f5f5f5",
                    marginLeft: "240px", // Adjust this value to match your sidebar width
                }}
            >
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        {/* Header */}
                        <Grid item xs={12}>
                            <Paper
                                elevation={3}
                                sx={{
                                    p: 2,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography variant="h4" component="h1">
                                    Dashboard
                                </Typography>
                                <Box>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => navigate("/")}
                                        startIcon={<ExitToAppIcon />}
                                        sx={{ mr: 2 }}
                                    >
                                        Home
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleLogout}
                                        startIcon={<ExitToAppIcon />}
                                    >
                                        Logout
                                    </Button>
                                </Box>
                            </Paper>
                        </Grid>

                        {/* Welcome Message */}
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ p: 2 }}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                >
                                    {userData.firstName && userData.lastName
                                        ? `Welcome back, ${userData.firstName} ${userData.lastName}!`
                                        : "Welcome to your dashboard!"}
                                </Typography>
                            </Paper>
                        </Grid>

                        {/* User Info */}
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ p: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    <PersonIcon
                                        sx={{ mr: 1, verticalAlign: "middle" }}
                                    />
                                    User Information
                                </Typography>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <BadgeIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={userData.firstName}
                                            secondary="First Name"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <BadgeIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={userData.lastName}
                                            secondary="Last Name"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <EmailIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={userData.email}
                                            secondary="Email"
                                        />
                                    </ListItem>
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={severity}
                        sx={{ width: "100%" }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

export default DashBoardPage;
