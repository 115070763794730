import apiClient from "../Helpers/apiClient";

const PumpPerformanceService = {
    // Get all performance points for a pump
    getPerformanceData: async (pumpId) => {
        try {
            const response = await apiClient.get(
                `/api/PumpPerformance/pump/${pumpId}`
            );
            return response.data;
        } catch (error) {
            console.error("Error fetching pump performance data:", error);
            throw error;
        }
    },

    // Add a single performance point
    addPerformancePoint: async (point) => {
        try {
            const response = await apiClient.post(
                "/api/PumpPerformance",
                point
            );
            return response.data;
        } catch (error) {
            console.error("Error adding performance point:", error);
            throw error;
        }
    },

    // Add multiple performance points in batch
    addPerformancePointsBatch: async (points) => {
        try {
            const response = await apiClient.post(
                "/api/PumpPerformance/batch",
                points
            );
            return response.data;
        } catch (error) {
            console.error("Error adding performance points batch:", error);
            throw error;
        }
    },

    // Update a performance point
    updatePerformancePoint: async (id, point) => {
        try {
            const response = await apiClient.put(
                `/api/PumpPerformance/${id}`,
                point
            );
            return response.data;
        } catch (error) {
            console.error("Error updating performance point:", error);
            throw error;
        }
    },

    // Delete a performance point
    deletePerformancePoint: async (id) => {
        try {
            await apiClient.delete(`/api/PumpPerformance/${id}`);
            return true;
        } catch (error) {
            console.error("Error deleting performance point:", error);
            throw error;
        }
    },

    // Delete all performance points for a pump
    clearPumpPerformanceData: async (pumpId) => {
        try {
            await apiClient.delete(`/api/PumpPerformance/pump/${pumpId}`);
            return true;
        } catch (error) {
            console.error("Error clearing pump performance data:", error);
            throw error;
        }
    },

    // Convert grid data to performance points
    convertGridToPerformancePoints: (gridData, pumpId) => {
        const points = [];

        // Loop through the grid data array
        // gridData is expected to be an array where index+1 = head value
        // and the value at that index is the flow rate
        gridData.forEach((flowRate, index) => {
            // Only add points that have data (non-empty cells)
            if (
                flowRate !== null &&
                flowRate !== undefined &&
                flowRate !== ""
            ) {
                points.push({
                    pumpPriceId: pumpId,
                    headMeters: index + 1, // Grid index 0 represents head value 1m
                    flowLPS: parseFloat(flowRate),
                });
            }
        });

        return points;
    },

    // Convert performance points to grid data
    convertPerformancePointsToGrid: (points, maxHead = 60) => {
        // Create an array to match your grid UI with maxHead cells (1-60 m head)
        const gridData = Array(maxHead).fill("");

        // Fill in the cells where we have data
        points.forEach((point) => {
            if (point.headMeters >= 1 && point.headMeters <= maxHead) {
                gridData[point.headMeters - 1] = point.flowLPS.toString();
            }
        });

        return gridData;
    },
};

export default PumpPerformanceService;
