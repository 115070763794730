import React, { useState, useEffect } from "react";
import {
    Button,
    Box,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Paper,
    CircularProgress,
    Alert,
    Snackbar,
} from "@mui/material";
import apiClient from "../../Helpers/apiClient";
import html2canvas from "html2canvas"; // Add this package using npm install html2canvas

const DynamicPdfGenerator = ({ formData, quoteBreakdown, chartRef }) => {
    const [pdfBytes, setPdfBytes] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false);
    const [preview, setPreview] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [isCapturingChart, setIsCapturingChart] = useState(false);
    const [notification, setNotification] = useState({
        open: false,
        message: "",
        severity: "success",
    });
    const [needsPreRender, setNeedsPreRender] = useState(true);

    // Clean up URL when component unmounts or when pdfBytes changes
    useEffect(() => {
        if (pdfBytes) {
            // Create a blob URL for preview
            const blob = new Blob([pdfBytes], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            setPdfUrl(url);

            return () => {
                // Clean up URL when component unmounts or when pdfBytes changes
                if (url) URL.revokeObjectURL(url);
            };
        }
    }, [pdfBytes]);

    useEffect(() => {
        const preRenderHiddenChart = () => {
            if (!chartRef || !chartRef.current || !needsPreRender) return;

            // Find the chart container element
            const chartElement = chartRef.current;

            // Temporarily make it visible for rendering
            const originalDisplay = chartElement.style.display;
            chartElement.style.display = "block";

            // Force layout calculation to ensure the chart is rendered
            void chartElement.offsetWidth;

            // Add a small delay to ensure the chart has time to render
            setTimeout(() => {
                // Take a snapshot to ensure it's rendered
                console.log("Pre-rendering chart:", chartElement);

                // Hide it again
                chartElement.style.display = originalDisplay;
                setNeedsPreRender(false);
            }, 300);
        };

        preRenderHiddenChart();
    }, [chartRef, needsPreRender]);

    // Function to capture the chart as an image
    // Add this code to your DynamicPdfGenerator.jsx file
    // Find the captureChart function and modify it like this:

    const captureChart = async () => {
        if (!chartRef || !chartRef.current) {
            console.error("Chart reference not available");
            return null;
        }

        setIsCapturingChart(true);

        try {
            // Find the chart container element
            const chartElement = chartRef.current;

            console.log("Chart element to capture:", chartElement);
            console.log("Chart element dimensions:", {
                width: chartElement.offsetWidth,
                height: chartElement.offsetHeight,
                visibility: window.getComputedStyle(chartElement).visibility,
            });

            // Important: If the element is hidden, temporarily make it visible for capturing
            const originalVisibility = chartElement.style.visibility;
            const originalPosition = chartElement.style.position;

            // Force the hidden chart to be visible and positioned for capture
            if (
                originalVisibility === "hidden" ||
                window.getComputedStyle(chartElement).visibility === "hidden"
            ) {
                console.log(
                    "Chart is hidden, temporarily making it visible for capture"
                );
                chartElement.style.visibility = "visible";
                chartElement.style.position = "fixed";
                chartElement.style.top = "0";
                chartElement.style.left = "0";
                chartElement.style.zIndex = "-1000"; // Behind everything but still rendered

                // Force a browser reflow/repaint to ensure the chart is rendered
                void chartElement.offsetWidth;
            }

            // Use html2canvas to capture the chart as an image
            const canvas = await html2canvas(chartElement, {
                scale: 2, // Higher scale for better quality
                backgroundColor: "#FFFFFF",
                logging: true, // Enable logging
                useCORS: true,
                allowTaint: true,
            });

            // Restore original visibility and position
            if (
                originalVisibility === "hidden" ||
                window.getComputedStyle(chartElement).visibility === "hidden"
            ) {
                chartElement.style.visibility = originalVisibility;
                chartElement.style.position = originalPosition;
                chartElement.style.top = "";
                chartElement.style.left = "";
                chartElement.style.zIndex = "";
            }

            // Convert the canvas to a base64 encoded PNG
            const imageData = canvas.toDataURL("image/png");
            console.log(
                "Chart captured successfully, data length:",
                imageData.length
            );

            return imageData;
        } catch (error) {
            console.error("Error capturing chart:", error);
            return null;
        } finally {
            setIsCapturingChart(false);
        }
    };

    const generatePdf = async () => {
        setIsGenerating(true);

        try {
            // Pre-render step: temporarily make chart visible to ensure it's rendered properly
            if (chartRef && chartRef.current) {
                // Store original display style
                const chartElement = chartRef.current;
                const originalDisplay = chartElement.style.display;

                // Make chart visible (but positioned off-screen)
                chartElement.style.display = "block";
                chartElement.style.position = "fixed";
                chartElement.style.top = "-9999px";
                chartElement.style.left = "-9999px";

                // Force a browser reflow/repaint to ensure the chart is rendered
                void chartElement.offsetWidth;

                // Wait a moment for the chart to fully render
                await new Promise((resolve) => setTimeout(resolve, 300));

                // Capture the chart
                let chartImageData = await captureChart();

                // Prepare the data to send to the backend
                const pdfData = {
                    // Original properties
                    projectName: formData.projectName || null,
                    projectAddress: formData.projectAddress || null,
                    tankDiameter: formData.tankDiameter || null,
                    tankDepth: formData.tankDepth || null,
                    pipeWorkSize: formData.pipeWorkSize || null,
                    pipeWorkMaterial: formData.pipeWorkMaterial || null,
                    pumpModel: formData.pumpModel || null,
                    numberOfPumps: formData.numberOfPumps || 1,
                    pumpSetup: formData.pumpSetup || null,
                    pumpOperation: formData.pumpOperation || 1,
                    guideRailType: formData.guideRailType || null,
                    flow: formData.flow || null,
                    totalHead: formData.totalHead || null,
                    accessSize: formData.accessSize || null,
                    accessCoverType: formData.accessCoverType || null,
                    valveMaterial: formData.valveMaterial || null,
                    controlType: formData.controlType || null,
                    levelControlDescription:
                        formData.levelControlDescription || null,
                    quoteBreakdown: quoteBreakdown || {},

                    // New properties from the formdata object
                    valvePitDescription: formData.valvePitDescription || null,
                    valvePitAccessCoverSize:
                        formData.valvePitAccessCoverSize || null,
                    valvePitAccessCoverType:
                        formData.valvePitAccessCoverType || null,
                    customerName: formData.customerName || null,
                    customerEmail: formData.customerEmail || null,
                    customerPhone: formData.customerPhone || null,
                    notes: formData.notes || null,
                    mixerType: formData.mixerType || null,
                    city: formData.city || null,
                    staticHead: formData.staticHead || null,
                    risingMainLength: formData.risingMainLength || null,
                    risingMainSize: formData.risingMainSize || null,
                    risingMainType: formData.risingMainType || null,

                    // Add the chart image data
                    chartImage: chartImageData,
                };

                // Call the backend API to generate the PDF
                const response = await apiClient.post(
                    "/api/pdf/generate",
                    pdfData,
                    {
                        responseType: "arraybuffer", // Important for binary data
                    }
                );

                // Store the PDF binary data
                setPdfBytes(response.data);

                setNotification({
                    open: true,
                    message: "PDF generated successfully!",
                    severity: "success",
                });
            } else {
                throw new Error("Chart reference not available");
            }
        } catch (error) {
            console.error("Error generating PDF:", error);
            setNotification({
                open: true,
                message: `Error generating PDF: ${
                    error.response?.data?.message || error.message
                }. Please try again.`,
                severity: "error",
            });
        } finally {
            setIsGenerating(false);
        }
    };

    const handlePreviewClick = async () => {
        if (!pdfBytes) {
            try {
                setIsGenerating(true);
                await generatePdf();
                setPreview(true);
            } catch (error) {
                console.error("Failed to generate PDF for preview:", error);
                setNotification({
                    open: true,
                    message:
                        "Failed to generate PDF for preview. Please try again.",
                    severity: "error",
                });
            } finally {
                setIsGenerating(false);
            }
        } else {
            setPreview(true);
        }
    };

    const handleClosePreview = () => {
        setPreview(false);
    };

    const handleDownload = () => {
        if (!pdfBytes) return;

        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${
            formData.projectName || "pump-station"
        }-specification.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        setNotification({
            open: true,
            message: "PDF downloaded successfully!",
            severity: "success",
        });
    };

    const handleSnackbarClose = () => {
        setNotification((prev) => ({ ...prev, open: false }));
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Generate Pump Station Specification PDF
                </Typography>

                <Typography variant="body2" color="text.secondary" paragraph>
                    Generate a customized PDF specification sheet for this pump
                    station configuration. The PDF will include all the details
                    from your quote and the performance chart.
                </Typography>

                <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={generatePdf}
                        disabled={isGenerating || isCapturingChart}
                    >
                        {isGenerating || isCapturingChart ? (
                            <CircularProgress size={24} />
                        ) : (
                            "Generate PDF"
                        )}
                    </Button>

                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handlePreviewClick}
                        disabled={isGenerating || isCapturingChart}
                    >
                        Preview PDF
                    </Button>

                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleDownload}
                        disabled={!pdfBytes}
                    >
                        Download PDF
                    </Button>
                </Box>
            </Paper>

            {/* PDF Preview Dialog with iframe */}
            <Dialog
                open={preview}
                onClose={handleClosePreview}
                maxWidth="lg"
                fullWidth
            >
                <DialogContent sx={{ height: "80vh" }}>
                    {pdfUrl ? (
                        <iframe
                            src={pdfUrl}
                            title="PDF Preview"
                            width="100%"
                            height="100%"
                            style={{ border: "none" }}
                        />
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePreview}>Close</Button>
                    <Button onClick={handleDownload} color="primary">
                        Download
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={notification.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={notification.severity}
                    sx={{ width: "100%" }}
                >
                    {notification.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default DynamicPdfGenerator;
