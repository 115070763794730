import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { getProductCaseStudies } from "../../Services/wordPressAPICall";
import ForwardOutlinedIcon from "@mui/icons-material/ForwardOutlined";

// Custom CSS for the arrows
const customStyles = `
  .custom-arrow {
    position: absolute;
    bottom: 25px;
    top: auto;
    transform: none;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    color: #185576;
  }
  
  .custom-arrow svg {
    font-size: 100px;
  }
  
  .custom-arrow-prev {
    left: 40px;
  }
  
  .custom-arrow-next {
    right: 40px;
  }
  
  .arrow-left {
    transform: rotate(180deg);
  }
  
  .custom-swiper-container {
    position: relative;
    padding-bottom: 50px;
  }
`;

const CaseStudiesComponent = ({ productCategoryId }) => {
    const [caseStudies, setCaseStudies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [swiper, setSwiper] = useState(null);

    useEffect(() => {
        const fetchCaseStudies = async () => {
            try {
                setLoading(true);
                const productPages = await getProductCaseStudies(
                    productCategoryId
                );
                setCaseStudies(productPages);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching case studies:", err);
                setError(
                    "Failed to load case studies. Please try again later."
                );
                setLoading(false);
            }
        };

        fetchCaseStudies();
    }, [productCategoryId]);

    // Helper function to extract image URL from yoast_head_json
    const extractImageUrl = (page) => {
        if (
            page.yoast_head_json &&
            page.yoast_head_json.og_image &&
            page.yoast_head_json.og_image.length > 0
        ) {
            return page.yoast_head_json.og_image[0].url;
        }
        return "/imgs/protector_image/placeholder.jpg"; // Fallback image
    };

    // Helper function to extract clean text from HTML content
    const extractCleanText = (html) => {
        if (!html) return "No content available";

        // Create a temporary element to parse the HTML
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;

        // Get text content (removes all HTML tags)
        let text = tempDiv.textContent || tempDiv.innerText || "";

        // Remove extra whitespace and normalize spacing
        text = text.replace(/\s+/g, " ").trim();

        return text;
    };

    // Helper function to extract content from the page
    const extractContent = (page) => {
        // If the content.rendered property exists, extract clean text from it
        if (page.content && page.content.rendered) {
            return extractCleanText(page.content.rendered);
        }

        // Fallback to description from yoast_head_json
        if (page.yoast_head_json && page.yoast_head_json.og_description) {
            return page.yoast_head_json.og_description.replace(
                /(&hellip;|\[&hellip;\])/g,
                "..."
            );
        }

        return "No description available";
    };

    // Create a safe HTML parser function
    const createMarkup = (html) => {
        return { __html: html };
    };

    const handlePrev = () => {
        if (swiper) swiper.slidePrev();
    };

    const handleNext = () => {
        if (swiper) swiper.slideNext();
    };

    if (loading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                }}
            >
                <Typography variant="h6" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    if (caseStudies.length === 0) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    flexDirection: "column",
                    gap: 2,
                }}
            >
                <Typography variant="h6">
                    No case studies available for this product.
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    This product doesn't have any case studies published yet.
                </Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
            }}
        >
            {/* Include custom styles */}
            <style>{customStyles}</style>

            {/* Case Studies title */}
            <Typography
                variant="h4"
                component="h1"
                gutterBottom
                sx={{ px: 3, pt: 2, pb: 1 }}
            >
                Case Studies
            </Typography>

            <Box
                sx={{
                    flex: 1,
                    position: "relative",
                    overflow: "hidden",
                }}
                className="custom-swiper-container"
            >
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={30}
                    slidesPerView={1}
                    navigation={false}
                    onSwiper={setSwiper}
                    style={{ height: "100%", width: "100%" }}
                >
                    {caseStudies.map((caseStudy) => (
                        <SwiperSlide key={caseStudy.id}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", md: "row" },
                                    height: "calc(100% - 50px)", // Reduce height to make room for arrows
                                    p: 2,
                                    px: { xs: 2, md: 4 },
                                    overflow: "auto",
                                }}
                            >
                                {/* Left side - Image */}
                                <Box
                                    sx={{
                                        flex: { xs: "none", md: 1 },
                                        mb: { xs: 2, md: 0 },
                                        pr: { xs: 0, md: 2 },
                                        maxHeight: { xs: "40vh", md: "100%" },
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src={extractImageUrl(caseStudy)}
                                        alt={caseStudy.title.rendered}
                                        sx={{
                                            maxWidth: "100%",
                                            maxHeight: "100vh",
                                            objectFit: "contain",
                                            borderRadius: 2,
                                            boxShadow: 3,
                                        }}
                                    />
                                </Box>

                                {/* Right side - Content */}
                                <Box
                                    sx={{
                                        flex: { xs: "none", md: 1 },
                                        pl: { xs: 0, md: 2 },
                                        display: "flex",
                                        flexDirection: "column",
                                        maxHeight: { xs: "auto", md: "100%" },
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        dangerouslySetInnerHTML={createMarkup(
                                            caseStudy.title.rendered
                                        )}
                                        sx={{ mb: 2, mt: 2, fontWeight: 700 }}
                                    />

                                    <Box
                                        sx={{
                                            flex: 1,
                                            overflow: "auto",
                                            pr: 1,
                                            mb: { xs: 6, md: 0 }, // Add bottom margin on mobile for arrow visibility
                                        }}
                                    >
                                        <Typography variant="body1">
                                            {extractContent(caseStudy)}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </SwiperSlide>
                    ))}
                </Swiper>

                {/* Custom navigation arrows */}
                <Box
                    className="custom-arrow custom-arrow-prev"
                    onClick={handlePrev}
                >
                    <ForwardOutlinedIcon
                        className="arrow-left"
                        fontSize="large"
                    />
                </Box>

                <Box
                    className="custom-arrow custom-arrow-next"
                    onClick={handleNext}
                >
                    <ForwardOutlinedIcon fontSize="large" />
                </Box>
            </Box>
        </Box>
    );
};

export default CaseStudiesComponent;
