import React from "react";
import { AppBar, Toolbar, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NavigationBar = () => {
    const navigate = useNavigate();

    const handleButtonClick = (action) => {
        switch (action) {
            case "addNewQuote":
                navigate("/quote-price");
                break;
            case "addToPriceTables":
                navigate("/pumpstation-price-tables");
                break;
            case "viewQuotes":
                console.log("Viewing Quotes");
                break;
            case "changePassword":
                console.log("Changing Password");
                break;
            case "userList":
                console.log("Viewing User List");
                break;
            case "salesRepMenu":
                console.log("Sales Rep Menu");
                break;
            case "logOut":
                console.log("Logging Out");
                break;
            default:
                console.log("Unknown action");
        }
    };

    const buttons = [
        { label: "ADD NEW Quotes", action: "addNewQuote" },
        { label: "Add to Price Tables", action: "addToPriceTables" },
        { label: "VIEW QUOTES", action: "viewQuotes" },
        { label: "CHANGE PASSWORD", action: "changePassword" },
        { label: "USER LIST", action: "userList" },
        { label: "SALES REP MENU", action: "salesRepMenu" },
        { label: "LOG OUT", action: "logOut" },
    ];

    return (
        <AppBar
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            position="static"
            color="transparent"
            elevation={0}
        >
            <Toolbar>
                {buttons.map((btn) => (
                    <Button
                        key={btn.label}
                        onClick={() => handleButtonClick(btn.action)}
                        sx={{
                            marginRight: 3,
                            color: "white",
                            backgroundColor: "#54c9ec",
                        }}
                    >
                        {btn.label}
                    </Button>
                ))}
            </Toolbar>
        </AppBar>
    );
};

export default NavigationBar;
