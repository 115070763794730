import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Box,
    Grid,
    Snackbar,
    Alert,
    CircularProgress,
    InputAdornment,
    Tooltip,
    Divider,
} from "@mui/material";
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Search as SearchIcon,
    FilterAlt as FilterIcon,
    Clear as ClearIcon,
} from "@mui/icons-material";
import apiClient from "../../Helpers/apiClient";

function PedestalPriceTable() {
    const [pedestals, setPedestals] = useState([]);
    const [filteredPedestals, setFilteredPedestals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedPedestal, setSelectedPedestal] = useState(null);
    const [formData, setFormData] = useState({
        id: 0,
        guideRailKits: "",
        pedestal: "",
        description: "",
        guildRailNB: "",
        costPrice: 0,
        sellPrice: 0,
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });
    // Sorting states
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("id");
    // Filtering states
    const [filterText, setFilterText] = useState("");
    const [filterField, setFilterField] = useState("all");

    const navigate = useNavigate();

    useEffect(() => {
        fetchPedestals();
    }, []);

    // Apply filters whenever pedestals or filter settings change
    useEffect(() => {
        applyFilters();
    }, [pedestals, filterText, filterField]);

    const fetchPedestals = async () => {
        setLoading(true);
        try {
            console.log("Fetching pedestals...");

            const response = await apiClient.get(
                "/api/PumpStationPriceTables/PedestalPrices"
            );

            console.log("Response received successfully!");
            console.log("Data type:", typeof response.data);
            console.log("Is array:", Array.isArray(response.data));

            if (Array.isArray(response.data)) {
                setPedestals(response.data);
                console.log(
                    `Successfully loaded ${response.data.length} pedestals`
                );
            } else {
                console.warn("Response is not an array:", response.data);
                setPedestals([]);
            }

            setError(null);
        } catch (err) {
            console.error("Error fetching pedestals:", err);
            setError(`Failed to load data: ${err.message}`);
            setPedestals([]);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenDialog = (pedestal = null) => {
        if (pedestal) {
            setFormData({
                id: pedestal.id || pedestal.Id || 0,
                guideRailKits:
                    pedestal.guideRailKits || pedestal.GuideRailKits || "",
                pedestal: pedestal.pedestal || pedestal.Pedestal || "",
                description: pedestal.description || pedestal.Description || "",
                guildRailNB: pedestal.guildRailNB || pedestal.GuildRailNB || "",
                costPrice: pedestal.costPrice || pedestal.CostPrice || 0,
                sellPrice: pedestal.sellPrice || pedestal.SellPrice || 0,
            });
            setSelectedPedestal(pedestal);
        } else {
            setFormData({
                id: 0,
                guideRailKits: "",
                pedestal: "",
                description: "",
                guildRailNB: "",
                costPrice: 0,
                sellPrice: 0,
            });
            setSelectedPedestal(null);
        }
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDeleteDialog = (pedestal) => {
        setSelectedPedestal(pedestal);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedPedestal(null);
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]:
                name === "costPrice" || name === "sellPrice"
                    ? parseFloat(value) || 0
                    : value,
        });
    };

    const handleSubmit = async () => {
        try {
            // Convert camelCase to PascalCase to match C# model property names
            const payload = {
                Id: formData.id,
                GuideRailKits: formData.guideRailKits,
                Pedestal: formData.pedestal,
                Description: formData.description,
                GuildRailNB: formData.guildRailNB,
                CostPrice: formData.costPrice,
                SellPrice: formData.sellPrice,
            };

            let response;
            if (selectedPedestal) {
                // Update existing record
                response = await apiClient.put(
                    `/api/PumpStationPriceTables/PedestalPrices/${payload.Id}`,
                    payload
                );
                setSnackbar({
                    open: true,
                    message: "Pedestal updated successfully!",
                    severity: "success",
                });
            } else {
                // Create new record
                response = await apiClient.post(
                    "/api/PumpStationPriceTables/PedestalPrices",
                    payload
                );
                setSnackbar({
                    open: true,
                    message: "Pedestal created successfully!",
                    severity: "success",
                });
            }

            handleCloseDialog();
            fetchPedestals();
        } catch (err) {
            console.error("Error saving pedestal:", err);
            setSnackbar({
                open: true,
                message: `Error: ${
                    err.response?.data?.message ||
                    "Failed to save. Please try again."
                }`,
                severity: "error",
            });
        }
    };

    const handleDelete = async () => {
        try {
            await apiClient.delete(
                `/api/PumpStationPriceTables/PedestalPrices/${
                    selectedPedestal.id || selectedPedestal.Id
                }`
            );
            setSnackbar({
                open: true,
                message: "Pedestal deleted successfully!",
                severity: "success",
            });
            handleCloseDeleteDialog();
            fetchPedestals();
        } catch (err) {
            console.error("Error deleting pedestal:", err);
            setSnackbar({
                open: true,
                message: `Error: ${
                    err.response?.data?.message ||
                    "Failed to delete. Please try again."
                }`,
                severity: "error",
            });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    // Sorting functionality
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const getSortedData = (data) => {
        if (!data.length) return [];

        return [...data].sort((a, b) => {
            // Handle the ID/modelNo column specially
            if (orderBy === "modelNo") {
                const idA = parseInt(a.id || a.Id || 0);
                const idB = parseInt(b.id || b.Id || 0);
                return order === "asc" ? idA - idB : idB - idA;
            }

            // For any other column, get the property with both camelCase and PascalCase checks
            let valueA, valueB;

            // Try camelCase first, then PascalCase if not found
            if (orderBy === "guideRailKits") {
                valueA =
                    a.guideRailKits !== undefined
                        ? a.guideRailKits
                        : a.GuideRailKits;
                valueB =
                    b.guideRailKits !== undefined
                        ? b.guideRailKits
                        : b.GuideRailKits;
            } else if (orderBy === "pedestal") {
                valueA = a.pedestal !== undefined ? a.pedestal : a.Pedestal;
                valueB = b.pedestal !== undefined ? b.pedestal : b.Pedestal;
            } else if (orderBy === "description") {
                valueA =
                    a.description !== undefined ? a.description : a.Description;
                valueB =
                    b.description !== undefined ? b.description : b.Description;
            } else if (orderBy === "guildRailNB") {
                valueA =
                    a.guildRailNB !== undefined ? a.guildRailNB : a.GuildRailNB;
                valueB =
                    b.guildRailNB !== undefined ? b.guildRailNB : b.GuildRailNB;
            } else if (orderBy === "costPrice") {
                valueA = a.costPrice !== undefined ? a.costPrice : a.CostPrice;
                valueB = b.costPrice !== undefined ? b.costPrice : b.CostPrice;

                // Convert to numbers for price comparison
                valueA = parseFloat(valueA) || 0;
                valueB = parseFloat(valueB) || 0;
                return order === "asc" ? valueA - valueB : valueB - valueA;
            } else if (orderBy === "sellPrice") {
                valueA = a.sellPrice !== undefined ? a.sellPrice : a.SellPrice;
                valueB = b.sellPrice !== undefined ? b.sellPrice : b.SellPrice;

                // Convert to numbers for price comparison
                valueA = parseFloat(valueA) || 0;
                valueB = parseFloat(valueB) || 0;
                return order === "asc" ? valueA - valueB : valueB - valueA;
            }

            // For non-numeric values, convert to strings and compare
            if (typeof valueA !== "number") {
                valueA = String(valueA || "").toLowerCase();
                valueB = String(valueB || "").toLowerCase();
                return order === "asc"
                    ? valueA.localeCompare(valueB)
                    : valueB.localeCompare(valueA);
            }

            // For numeric values
            return order === "asc" ? valueA - valueB : valueB - valueA;
        });
    };

    // Filtering functionality
    const handleFilterChange = (event) => {
        setFilterText(event.target.value);
    };

    const handleFilterFieldChange = (field) => {
        setFilterField(field);
    };

    const clearFilter = () => {
        setFilterText("");
        setFilterField("all");
    };

    const applyFilters = () => {
        if (!filterText.trim()) {
            setFilteredPedestals(pedestals);
            return;
        }

        const searchTerm = filterText.toLowerCase().trim();

        const filtered = pedestals.filter((pedestal) => {
            // Format model number for filtering (PD-001, PD-002, etc.)
            const modelNo = `PD-${(pedestal.id || pedestal.Id || 0)
                .toString()
                .padStart(3, "0")}`;

            // Handle both camelCase and PascalCase property names from API
            const guideRailKits =
                pedestal.guideRailKits || pedestal.GuideRailKits || "";
            const pedestalValue = pedestal.pedestal || pedestal.Pedestal || "";
            const description =
                pedestal.description || pedestal.Description || "";
            const guildRailNB =
                pedestal.guildRailNB || pedestal.GuildRailNB || "";
            const costPrice = pedestal.costPrice || pedestal.CostPrice || 0;
            const sellPrice = pedestal.sellPrice || pedestal.SellPrice || 0;

            if (filterField === "all") {
                return (
                    modelNo.toLowerCase().includes(searchTerm) ||
                    String(pedestal.id || pedestal.Id || "").includes(
                        searchTerm
                    ) ||
                    guideRailKits.toLowerCase().includes(searchTerm) ||
                    pedestalValue.toLowerCase().includes(searchTerm) ||
                    description.toLowerCase().includes(searchTerm) ||
                    guildRailNB.toLowerCase().includes(searchTerm) ||
                    String(costPrice).includes(searchTerm) ||
                    String(sellPrice).includes(searchTerm)
                );
            }

            // Filter by specific field
            if (filterField === "modelNo")
                return modelNo.toLowerCase().includes(searchTerm);
            if (filterField === "id")
                return String(pedestal.id || pedestal.Id || "").includes(
                    searchTerm
                );
            if (filterField === "guideRailKits")
                return guideRailKits.toLowerCase().includes(searchTerm);
            if (filterField === "pedestal")
                return pedestalValue.toLowerCase().includes(searchTerm);
            if (filterField === "description")
                return description.toLowerCase().includes(searchTerm);
            if (filterField === "guildRailNB")
                return guildRailNB.toLowerCase().includes(searchTerm);
            if (filterField === "costPrice")
                return String(costPrice).includes(searchTerm);
            if (filterField === "sellPrice")
                return String(sellPrice).includes(searchTerm);

            return false;
        });

        setFilteredPedestals(filtered);
    };

    // Prepare data for rendering
    const displayData = getSortedData(filteredPedestals);

    return (
        <Box
            sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                padding: 4,
                paddingTop: 2,
                boxSizing: "border-box",
                overflow: "hidden",
            }}
        >
            <Typography variant="h4" gutterBottom align="center">
                Pedestal Price Table
            </Typography>

            <Paper
                sx={{
                    width: "100%",
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    mb: 2,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: 2,
                        alignItems: "center",
                    }}
                >
                    {/* Filter */}
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            width: "60%",
                        }}
                    >
                        <TextField
                            variant="outlined"
                            placeholder="Search..."
                            size="small"
                            fullWidth
                            value={filterText}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: filterText ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={clearFilter}
                                            edge="end"
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ) : null,
                            }}
                        />
                        <Tooltip title="Filter by field">
                            <Box>
                                <IconButton
                                    color={
                                        filterField !== "all"
                                            ? "primary"
                                            : "default"
                                    }
                                    aria-label="Filter options"
                                    onClick={(e) => {
                                        e.currentTarget.nextElementSibling.style.display =
                                            e.currentTarget.nextElementSibling
                                                .style.display === "none"
                                                ? "flex"
                                                : "none";
                                    }}
                                >
                                    <FilterIcon />
                                </IconButton>
                                <Box
                                    sx={{
                                        position: "absolute",
                                        zIndex: 10,
                                        bgcolor: "background.paper",
                                        boxShadow: 3,
                                        borderRadius: 1,
                                        p: 1,
                                        display: "none",
                                        flexDirection: "column",
                                        gap: 0.5,
                                    }}
                                >
                                    {[
                                        { id: "all", label: "All Fields" },
                                        { id: "modelNo", label: "ID" },
                                        {
                                            id: "guideRailKits",
                                            label: "Guide Rail Kits",
                                        },
                                        { id: "pedestal", label: "Pedestal" },
                                        {
                                            id: "description",
                                            label: "Description",
                                        },
                                        {
                                            id: "guildRailNB",
                                            label: "Guild Rail NB",
                                        },
                                        {
                                            id: "costPrice",
                                            label: "Cost Price",
                                        },
                                        {
                                            id: "sellPrice",
                                            label: "Sell Price",
                                        },
                                    ].map((option) => (
                                        <Button
                                            key={option.id}
                                            size="small"
                                            variant={
                                                filterField === option.id
                                                    ? "contained"
                                                    : "text"
                                            }
                                            onClick={() => {
                                                handleFilterFieldChange(
                                                    option.id
                                                );
                                                // Hide the filter dropdown
                                                document.querySelector(
                                                    '[aria-label="Filter options"]'
                                                ).nextElementSibling.style.display =
                                                    "none";
                                            }}
                                        >
                                            {option.label}
                                        </Button>
                                    ))}
                                </Box>
                            </Box>
                        </Tooltip>
                        {filterField !== "all" && (
                            <Typography variant="caption" sx={{ ml: 1 }}>
                                Filtering by:{" "}
                                {filterField === "modelNo"
                                    ? "ID"
                                    : filterField === "id"
                                    ? "ID"
                                    : filterField === "guideRailKits"
                                    ? "Guide Rail Kits"
                                    : filterField === "pedestal"
                                    ? "Pedestal"
                                    : filterField === "description"
                                    ? "Description"
                                    : filterField === "guildRailNB"
                                    ? "Guild Rail NB"
                                    : filterField === "costPrice"
                                    ? "Cost Price"
                                    : filterField === "sellPrice"
                                    ? "Sell Price"
                                    : ""}
                            </Typography>
                        )}
                    </Box>

                    {/* Add button */}
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => handleOpenDialog()}
                    >
                        Add New Item
                    </Button>
                </Box>

                <Divider />

                {loading ? (
                    <Box
                        sx={{ display: "flex", justifyContent: "center", p: 3 }}
                    >
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Box
                        sx={{ display: "flex", justifyContent: "center", p: 3 }}
                    >
                        <Typography color="error">{error}</Typography>
                    </Box>
                ) : (
                    <>
                        <TableContainer
                            sx={{
                                flex: 1,
                                overflow: "auto",
                            }}
                        >
                            <Table
                                stickyHeader
                                aria-label="pedestal price table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === "modelNo"}
                                                direction={
                                                    orderBy === "modelNo"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort("modelNo")
                                                }
                                            >
                                                <strong>ID</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={
                                                    orderBy === "guideRailKits"
                                                }
                                                direction={
                                                    orderBy === "guideRailKits"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort(
                                                        "guideRailKits"
                                                    )
                                                }
                                            >
                                                <strong>Guide Rail Kits</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === "pedestal"}
                                                direction={
                                                    orderBy === "pedestal"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort(
                                                        "pedestal"
                                                    )
                                                }
                                            >
                                                <strong>Pedestal</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={
                                                    orderBy === "guildRailNB"
                                                }
                                                direction={
                                                    orderBy === "guildRailNB"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort(
                                                        "guildRailNB"
                                                    )
                                                }
                                            >
                                                <strong>Guild Rail NB</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === "costPrice"}
                                                direction={
                                                    orderBy === "costPrice"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort(
                                                        "costPrice"
                                                    )
                                                }
                                            >
                                                <strong>Cost Price</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === "sellPrice"}
                                                direction={
                                                    orderBy === "sellPrice"
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={() =>
                                                    handleRequestSort(
                                                        "sellPrice"
                                                    )
                                                }
                                            >
                                                <strong>Sell Price</strong>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="center">
                                            <strong>Actions</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {displayData.length === 0 ? (
                                        <TableRow>
                                            <TableCell
                                                colSpan={7}
                                                align="center"
                                            >
                                                {filterText
                                                    ? "No matches found. Try a different search."
                                                    : "No data available. Add your first pedestal."}
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        displayData.map((pedestal) => (
                                            <TableRow
                                                key={pedestal.id || pedestal.Id}
                                                hover
                                            >
                                                <TableCell>
                                                    PD-
                                                    {(
                                                        pedestal.id ||
                                                        pedestal.Id
                                                    )
                                                        .toString()
                                                        .padStart(3, "0")}
                                                </TableCell>
                                                <TableCell>
                                                    {pedestal.guideRailKits ||
                                                        pedestal.GuideRailKits}
                                                </TableCell>
                                                <TableCell>
                                                    {pedestal.pedestal ||
                                                        pedestal.Pedestal}
                                                </TableCell>
                                                <TableCell>
                                                    {pedestal.guildRailNB ||
                                                        pedestal.GuildRailNB}
                                                </TableCell>
                                                <TableCell>
                                                    $
                                                    {typeof (
                                                        pedestal.costPrice ||
                                                        pedestal.CostPrice
                                                    ) === "number"
                                                        ? (
                                                              pedestal.costPrice ||
                                                              pedestal.CostPrice
                                                          ).toFixed(2)
                                                        : "0.00"}
                                                </TableCell>
                                                <TableCell>
                                                    $
                                                    {typeof (
                                                        pedestal.sellPrice ||
                                                        pedestal.SellPrice
                                                    ) === "number"
                                                        ? (
                                                              pedestal.sellPrice ||
                                                              pedestal.SellPrice
                                                          ).toFixed(2)
                                                        : "0.00"}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() =>
                                                            handleOpenDialog(
                                                                pedestal
                                                            )
                                                        }
                                                        aria-label="edit"
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="error"
                                                        onClick={() =>
                                                            handleOpenDeleteDialog(
                                                                pedestal
                                                            )
                                                        }
                                                        aria-label="delete"
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box
                            sx={{
                                p: 2,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Typography variant="body2" color="text.secondary">
                                {filterText
                                    ? `Showing ${displayData.length} of ${pedestals.length} items`
                                    : `Total: ${pedestals.length} items`}
                            </Typography>
                            {filterText && (
                                <Button
                                    size="small"
                                    startIcon={<ClearIcon />}
                                    onClick={clearFilter}
                                >
                                    Clear Filter
                                </Button>
                            )}
                        </Box>
                    </>
                )}
            </Paper>

            <Box sx={{ mt: 2, textAlign: "center", flexShrink: 0 }}>
                <Button
                    variant="contained"
                    component={Link}
                    to="/pumpstation-price-tables"
                    sx={{ mt: 2 }}
                >
                    Back to Pump Station Tables
                </Button>
            </Box>

            {/* Create/Edit Dialog */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {selectedPedestal ? "Edit Pedestal" : "Add New Pedestal"}
                </DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Guide Rail Kits"
                                    name="guideRailKits"
                                    value={formData.guideRailKits}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    helperText="e.g., DGRK SERIES, HGRK SERIES"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Pedestal"
                                    name="pedestal"
                                    value={formData.pedestal}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    helperText="e.g., DGRK65-65, DGRK80-80"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Guild Rail NB"
                                    name="guildRailNB"
                                    value={formData.guildRailNB}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    helperText="e.g., 32NB, 40NB"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Cost Price"
                                    name="costPrice"
                                    type="number"
                                    inputProps={{ step: "0.01", min: "0" }}
                                    value={formData.costPrice}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Sell Price"
                                    name="sellPrice"
                                    type="number"
                                    inputProps={{ step: "0.01", min: "0" }}
                                    value={formData.sellPrice}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                    >
                        {selectedPedestal ? "Update" : "Save"}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this pedestal? This
                        action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
                    <Button
                        onClick={handleDelete}
                        color="error"
                        variant="contained"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default PedestalPriceTable;
