import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Paper,
    Grid,
    TextField,
    Button,
    CircularProgress,
    Snackbar,
    Alert,
    Divider,
    Tabs,
    Tab,
    IconButton,
    useTheme,
} from "@mui/material";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import {
    ViewList as ViewListIcon,
    ShowChart as ShowChartIcon,
    Save as SaveIcon,
    Delete as DeleteIcon,
    Refresh as RefreshIcon,
} from "@mui/icons-material";
import pumpPerformanceService from "../../Services/pumpPerformanceService";

// Number of head meters to display (1-60m)
const MAX_HEAD = 60;
// How many columns to show per row in the grid
const COLUMNS_PER_ROW = 10;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`pump-performance-tabpanel-${index}`}
            aria-labelledby={`pump-performance-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const PumpPerformanceEditor = ({ pumpId, onClose }) => {
    const [performanceData, setPerformanceData] = useState(
        Array(MAX_HEAD).fill("")
    );
    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "info",
    });

    const theme = useTheme();

    // Fetch performance data when component mounts or pumpId changes
    useEffect(() => {
        if (pumpId) {
            loadPerformanceData();
        }
    }, [pumpId]);

    const loadPerformanceData = async () => {
        if (!pumpId) return;

        setLoading(true);
        try {
            const data = await pumpPerformanceService.getPerformanceData(
                pumpId
            );

            // Initialize grid with empty values
            const grid = Array(MAX_HEAD).fill("");

            // Populate grid with data
            data.forEach((point) => {
                if (point.headMeters >= 1 && point.headMeters <= MAX_HEAD) {
                    grid[point.headMeters - 1] = point.flowLPS.toString();
                }
            });

            setPerformanceData(grid);

            // Format data for chart
            const formattedChartData = data
                .map((point) => ({
                    head: point.headMeters,
                    flow: point.flowLPS,
                }))
                .sort((a, b) => a.head - b.head);

            setChartData(formattedChartData);
        } catch (error) {
            console.error("Error loading performance data:", error);
            setSnackbar({
                open: true,
                message: "Failed to load performance data",
                severity: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (index, value) => {
        const newData = [...performanceData];

        // Allow empty strings, but convert valid numbers
        if (value === "") {
            newData[index] = "";
        } else {
            const numValue = parseFloat(value);
            if (!isNaN(numValue)) {
                newData[index] = numValue.toString();
            }
        }

        setPerformanceData(newData);
    };

    const handleSave = async () => {
        if (!pumpId) return;

        setSaving(true);
        try {
            // Convert grid data to performance points
            const points =
                pumpPerformanceService.convertGridToPerformancePoints(
                    performanceData,
                    pumpId
                );

            // Only save if there are points to save
            if (points.length > 0) {
                const result =
                    await pumpPerformanceService.addPerformancePointsBatch(
                        points
                    );
                setSnackbar({
                    open: true,
                    message: "Performance data saved successfully",
                    severity: "success",
                });

                // Update chart data
                const formattedChartData = result
                    .map((point) => ({
                        head: point.headMeters,
                        flow: point.flowLPS,
                    }))
                    .sort((a, b) => a.head - b.head);

                setChartData(formattedChartData);
            } else {
                setSnackbar({
                    open: true,
                    message: "No performance data to save",
                    severity: "info",
                });
            }
        } catch (error) {
            console.error("Error saving performance data:", error);
            setSnackbar({
                open: true,
                message: "Failed to save performance data",
                severity: "error",
            });
        } finally {
            setSaving(false);
        }
    };

    const handleClearAll = async () => {
        if (!pumpId) return;

        if (
            window.confirm(
                "Are you sure you want to clear all performance data for this pump?"
            )
        ) {
            setSaving(true);
            try {
                await pumpPerformanceService.clearPumpPerformanceData(pumpId);
                setPerformanceData(Array(MAX_HEAD).fill(""));
                setChartData([]);
                setSnackbar({
                    open: true,
                    message: "All performance data cleared",
                    severity: "success",
                });
            } catch (error) {
                console.error("Error clearing performance data:", error);
                setSnackbar({
                    open: true,
                    message: "Failed to clear performance data",
                    severity: "error",
                });
            } finally {
                setSaving(false);
            }
        }
    };

    const handleRefresh = () => {
        loadPerformanceData();
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    // Generate the grid UI
    const renderPerformanceGrid = () => {
        // Calculate how many rows we need
        const numRows = Math.ceil(MAX_HEAD / COLUMNS_PER_ROW);
        const rows = [];

        for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
            const startHead = rowIndex * COLUMNS_PER_ROW + 1;
            const endHead = Math.min(
                (rowIndex + 1) * COLUMNS_PER_ROW,
                MAX_HEAD
            );

            rows.push(
                <Box key={`row-${rowIndex}`} sx={{ mb: 2 }}>
                    <Grid container spacing={1} alignItems="center">
                        {/* Head Labels Row */}
                        <Grid item xs={1.2} sx={{ textAlign: "right", pr: 2 }}>
                            {" "}
                            {/* Increased size & padding */}
                            <Typography variant="body2" fontWeight="bold">
                                Head (m)
                            </Typography>
                        </Grid>
                        {Array.from({ length: endHead - startHead + 1 }).map(
                            (_, i) => (
                                <Grid
                                    item
                                    xs={1}
                                    key={`head-${startHead + i}`}
                                    textAlign="center"
                                >
                                    <Typography variant="body2">
                                        {startHead + i}
                                    </Typography>
                                </Grid>
                            )
                        )}

                        {/* Flow Input Row */}
                        <Grid item xs={1.2} sx={{ textAlign: "right", pr: 2 }}>
                            {" "}
                            {/* Matched the head label width */}
                            <Typography variant="body2" fontWeight="bold">
                                Flow (l/s)
                            </Typography>
                        </Grid>
                        {Array.from({ length: endHead - startHead + 1 }).map(
                            (_, i) => {
                                const dataIndex = startHead + i - 1;
                                return (
                                    <Grid item xs={1} key={`flow-${dataIndex}`}>
                                        <TextField
                                            size="small"
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                                step: 0.1,
                                                style: {
                                                    padding: "4px",
                                                    textAlign: "center",
                                                },
                                            }}
                                            value={performanceData[dataIndex]}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    dataIndex,
                                                    e.target.value
                                                )
                                            }
                                            disabled={loading || saving}
                                            sx={{ width: "100%" }}
                                        />
                                    </Grid>
                                );
                            }
                        )}
                    </Grid>
                </Box>
            );
        }

        return rows;
    };

    // Render the chart
    const renderPerformanceChart = () => {
        if (chartData.length === 0) {
            return (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "400px",
                        border: `1px dashed ${theme.palette.divider}`,
                    }}
                >
                    <Typography color="text.secondary">
                        No performance data available to display
                    </Typography>
                </Box>
            );
        }

        return (
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    data={chartData}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="flow"
                        label={{
                            value: "Flow (liters per second)",
                            position: "insideBottomRight",
                            offset: -10,
                        }}
                    />
                    <YAxis
                        label={{
                            value: "Head (meters)",
                            angle: -90,
                            position: "insideLeft",
                        }}
                    />
                    <Tooltip />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="head"
                        name="Head"
                        stroke={theme.palette.primary.main}
                        activeDot={{ r: 8 }}
                        strokeWidth={2}
                    />
                </LineChart>
            </ResponsiveContainer>
        );
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="pump performance tabs"
                    centered
                >
                    <Tab
                        icon={<ViewListIcon />}
                        label="Data Grid"
                        id="pump-performance-tab-0"
                        aria-controls="pump-performance-tabpanel-0"
                    />
                    <Tab
                        icon={<ShowChartIcon />}
                        label="Performance Curve"
                        id="pump-performance-tab-1"
                        aria-controls="pump-performance-tabpanel-1"
                    />
                </Tabs>
            </Box>

            {/* Action Buttons */}
            <Box
                sx={{ mb: 3, display: "flex", justifyContent: "space-between" }}
            >
                <Box>
                    <Button
                        variant="outlined"
                        startIcon={<RefreshIcon />}
                        onClick={handleRefresh}
                        disabled={loading || saving}
                        sx={{ mr: 1 }}
                    >
                        Refresh
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={handleClearAll}
                        disabled={loading || saving}
                    >
                        Clear All
                    </Button>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={
                        saving ? <CircularProgress size={24} /> : <SaveIcon />
                    }
                    onClick={handleSave}
                    disabled={loading || saving}
                >
                    Save Data
                </Button>
            </Box>

            {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <TabPanel value={tabValue} index={0}>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            paragraph
                        >
                            Enter flow rate (liters per second) for each head
                            value (meters). Empty cells will be ignored.
                        </Typography>
                        <Box
                            sx={{
                                maxHeight: "500px",
                                overflowY: "auto",
                                mt: 2,
                            }}
                        >
                            {renderPerformanceGrid()}
                        </Box>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            paragraph
                        >
                            This chart shows the pump performance curve based on
                            the data you've entered.
                        </Typography>
                        {renderPerformanceChart()}
                    </TabPanel>
                </>
            )}

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{ width: "100%" }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default PumpPerformanceEditor;
