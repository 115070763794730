import React, { useState } from "react";
import { Dialog, Box, Typography, Grow, Menu, MenuItem } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import BuildIcon from "@mui/icons-material/Build";
import FolderIcon from "@mui/icons-material/Folder";
import EcoprotectorOverviewUI from "../../Components/ProductOverviews/EcoprotectorOverviewUI/EcoprotectorOverviewUI";
import EcoprotectorSpecificationsUI from "../../Components/ProductOverviews/EcoprotectorSpecificationsUI/EcoprotectorSpecificaitonsUI";
import EcoprotectorDrawingDesignToolUI from "../../Components/ProductOverviews/EcoprotectorDrawingDesignToolUI/EcoprotectorDrawingDesignToolUI";
import CaseStudiesComponent from "./CaseStudiesComponent";
// Product-specific content configurations

const PRODUCT_CONTENT = {
    ECOPROTECTOR: {
        specifications: [
            {
                title: "EcoProtector Specification Overview",
                url: "#",
            },
            {
                title: "EcoProtector Brochure (Direct Download)",
                url: "https://jweoigjwiojjr.s3.ap-southeast-2.amazonaws.com/EcoProtector+Brochure+02.pdf",
            },
            {
                title: "Ecoprotector Technical Specification (Direct Download)",
                url: "https://jweoigjwiojjr.s3.ap-southeast-2.amazonaws.com/Ecoprotector+Technical+Specification+01.pdf",
            },
            {
                title: "MUSIC Files (Direct Download)",
                url: "https://jweoigjwiojjr.s3.ap-southeast-2.amazonaws.com/MUSIC+Files.zip",
            },
        ],
        installation: [
            {
                title: "Installation Manual",
                url: "https://jweoigjwiojjr.s3.ap-southeast-2.amazonaws.com/EcoProtector+Maintenance.pdf",
            },
            {
                title: "Maintenance Manual",
                url: "https://jweoigjwiojjr.s3.ap-southeast-2.amazonaws.com/EcoProtector+GPT+-+Maintenance+Register.pdf",
            },
            {
                title: "Maintenance Register",
                url: "https://jweoigjwiojjr.s3.ap-southeast-2.amazonaws.com/EcoProtector+GPT+-+Maintenance+Register.pdf",
            },
        ],
        designToolUrl: "https://specifyingpdf.protector.com.au/",
    },
    // Add other products here with their specific content
};

// Default "To be announced" items
const DEFAULT_SPECS = [
    { title: "To be announced", url: "#" },
    { title: "To be announced", url: "#" },
    { title: "To be announced", url: "#" },
];

const DEFAULT_INSTALL = [
    { title: "To be announced", url: "#" },
    { title: "To be announced", url: "#" },
    { title: "To be announced", url: "#" },
];

const getProductNavButtons = (productName) => {
    // Get content based on product name or use default content
    const currentProduct = PRODUCT_CONTENT[productName] || {};

    return [
        {
            icon: <InfoIcon />,
            title: "Product Overview",
            id: "overview",
        },
        {
            icon: <SettingsApplicationsIcon />,
            title: "Product Specifications",
            id: "specifications",
            hasDropdown: true,
            dropdownItems: currentProduct.specifications || DEFAULT_SPECS,
        },
        {
            icon: <ArchitectureIcon />,
            title: "Drawing Design Tool",
            id: "design",
            url: currentProduct.designToolUrl || "#",
        },
        {
            icon: <BuildIcon />,
            title: "Installation Data",
            id: "installation",
            hasDropdown: true,
            dropdownItems: currentProduct.installation || DEFAULT_INSTALL,
        },
        {
            icon: <FolderIcon />,
            title: "Case Studies",
            id: "cases",
        },
    ];
};

const ProductDialog = ({ open, onClose, product, anchorEl }) => {
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [activeButton, setActiveButton] = useState("overview");
    const [selectedProductTab, setSelectedProductTab] = useState("overview");

    const handleButtonClick = (event, button) => {
        event.stopPropagation();
        setSelectedProductTab(button.id);
        setActiveButton(button.id);

        if (button.hasDropdown) {
            setMenuAnchor(event.currentTarget);
        }
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleMenuItemClick = (url) => {
        if (url !== "#") {
            window.open(url, "_blank");
        }
        handleMenuClose();
    };

    // Post token to external service
    const openWithPostToken = () => {
        const jwtToken = localStorage.getItem("jwtToken");

        if (jwtToken) {
            const form = document.createElement("form");
            form.method = "POST";
            form.action = process.env.REACT_APP_API_URL;
            form.target = "_blank";

            const hiddenField = document.createElement("input");
            hiddenField.type = "hidden";
            hiddenField.name = "token";
            hiddenField.value = jwtToken;

            form.appendChild(hiddenField);
            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        } else {
            console.error("No token found in local storage.");
        }
    };

    const navButtons = getProductNavButtons(product?.name);
    const activeDropdownItems =
        navButtons.find((btn) => btn.id === activeButton)?.dropdownItems || [];

    // Renders the appropriate content based on product and selected tab
    const renderProductContent = () => {
        const productCategoryId = product?.wordpress_pages_category_id || 0;

        if (product?.name === "ECOPROTECTOR") {
            switch (selectedProductTab) {
                case "overview":
                    return <EcoprotectorOverviewUI />;
                case "specifications":
                    return <EcoprotectorSpecificationsUI />;
                case "design":
                    return (
                        <EcoprotectorDrawingDesignToolUI
                            productName={product.name}
                        />
                    );
                case "installation":
                    return null;
                case "cases":
                    return (
                        <CaseStudiesComponent
                            productCategoryId={productCategoryId}
                        />
                    );
                default:
                    return null;
            }
        }

        // Default content for other products with case study tab support
        if (selectedProductTab === "cases") {
            return (
                <CaseStudiesComponent productCategoryId={productCategoryId} />
            );
        }

        // Default content for other products
        return (
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        maxWidth: "800px",
                        height: "70vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box
                        component="img"
                        src={product?.image}
                        alt={product?.name}
                        sx={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "contain",
                            objectPosition: "center",
                        }}
                    />
                </Box>
            </Box>
        );
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={false}
            TransitionComponent={Grow}
            TransitionProps={{
                style: {
                    transformOrigin: anchorEl
                        ? `${anchorEl.offsetLeft}px ${
                              anchorEl.offsetTop + 100
                          }px`
                        : "0 0",
                },
            }}
            PaperProps={{
                sx: {
                    minWidth: "95vw",
                    maxHeight: "95vh", // Set maximum height to 90% of viewport height
                    height: "95vh", // Set fixed height to match maxHeight
                    bgcolor: "background.paper",
                    borderRadius: 1,
                    overflow: "hidden", // Changed from "auto" to "hidden"
                    display: "flex",
                    flexDirection: "column",
                },
            }}
            sx={{
                "& .MuiDialog-container": {
                    alignItems: "flex-start",
                    maxWidth: "100vw",
                    maxHeight: "100vh",
                },
            }}
        >
            {/* Navigation Buttons */}
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    borderBottom: "1px solid rgba(0,0,0,0.12)",
                }}
            >
                {navButtons.map((button) => (
                    <Box
                        key={button.id}
                        onClick={(e) => handleButtonClick(e, button)}
                        sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 1,
                            py: 2,
                            px: 3,
                            cursor: "pointer",
                            bgcolor:
                                button.id === activeButton
                                    ? "#185576"
                                    : "#696a6a",
                            color: "white",
                            transition: "all 0.2s ease",
                            "&:hover": {
                                bgcolor: "#185576",
                            },
                            borderRight: "3px solid rgba(0,0,0,0.12)",
                        }}
                    >
                        {button.icon}
                        <Typography
                            variant="button"
                            sx={{ whiteSpace: "nowrap" }}
                        >
                            {button.title}
                        </Typography>
                    </Box>
                ))}

                {/* Dropdown Menu */}
                <Menu
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor)}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    PaperProps={{
                        sx: {
                            // minWidth: "20vw",
                            // height: "30vh", // Set a specific height
                            // maxHeight: "90vh", // Limit maximum height
                            bgcolor: "#185576",
                            borderRadius: 1,
                            display: "flex", // Add this
                            flexDirection: "column", // Add this
                            overflow: "hidden", // Change from "auto" to "hidden"
                            color: "white",
                        },
                    }}
                    sx={{
                        "& .MuiMenu-paper": {
                            marginTop: 0,
                        },
                    }}
                >
                    {activeDropdownItems.map((item) => (
                        <MenuItem
                            key={item.title}
                            onClick={() => handleMenuItemClick(item.url)}
                            sx={{
                                width: "100%",
                                fontSize: "1rem",
                                cursor:
                                    item.url === "#" ? "default" : "pointer",
                                opacity: item.url === "#" ? 0.7 : 1,
                            }}
                        >
                            {item.title}
                        </MenuItem>
                    ))}
                </Menu>
            </Box>

            {/* Content Area */}
            <Box sx={{ flex: 1, overflow: "hidden" }}>
                {renderProductContent()}
            </Box>
        </Dialog>
    );
};

export default ProductDialog;
